/**
 * Created by spepa on 04.07.2024
 */

var LazyAsset = cc.Node.extend({
    ctor: function (res, options) {
        options = options || {};

        this.options = options;
        this.asset = undefined;
        this.virtualResource = res.resolve();
        this._pendingCalls = [];

        this._super();
        this.setAnchorPoint(0.5, 0.5);

        var dimensions = this.virtualResource.getDimensions();
        if (dimensions) {
            this.setContentSize2(dimensions);
        } else {
            cleverapps.throwAsync("Empty dimensions for lazy asset " + this.virtualResource + " " + res);
        }

        this.createPreview();

        if (cleverapps.lazyAssets.isLoaded(this.virtualResource) || cleverapps.lazyAssets.isPreferredBundle(this.virtualResource.getBundleName())) {
            this.loaded = true;

            cleverapps.lazyAssets.loadResource(this.virtualResource, this.createListener(this.createAsset.bind(this)));

            if (this.options.keepLazy) {
                cleverapps.lazyAssets.keepResource(this.virtualResource);
            }
        } else {
            cleverapps.throwAsync("Image is not allowed to be load " + this.virtualResource + " " + res);
        }
    },

    cleanup: function () {
        this._super();

        if (this.loaded) {
            this.loaded = false;

            cleverapps.lazyAssets.deleteResource(this.virtualResource);
        }
    },

    whenAssetLoaded: function (callback) {
        if (this.asset) {
            callback();
        } else {
            this._pendingCalls.push(callback);
        }
    },

    isSpine: function () {
        return this.virtualResource instanceof VirtualJson;
    },

    createPreview: function () {
        this.preview = this.options.preview || new cc.Sprite(bundles.merge.frames.unit_unknown);
        this.preview.setPosition(this.width / 2, this.height / 2);
        this.addChild(this.preview);

        this.preview.setVisible(false);
        this.preview.runAction(new cc.Sequence(
            new cc.DelayTime(0.2),
            new cc.Show()
        ));
    },

    createAsset: function () {
        if (this.asset) {
            return;
        }

        if (this.virtualResource instanceof VirtualFrame) {
            this.asset = new cc.Sprite(this.virtualResource);
        } else {
            this.asset = new cleverapps.Spine(this.virtualResource);
        }
        this.asset.setPosition(this.width / 2, this.height / 2);
        this.addChild(this.asset);

        if (this.preview) {
            if (this.preview.isDisplayed()) {
                this.preview.runAction(AnimationsLibrary.upgrade(this.preview, this.asset));
            } else {
                this.preview.removeFromParent();
            }
            this.preview = undefined;
        }

        this._callPending();
    },

    _callPending: function () {
        var running = true;
        this.addCleaner(function () {
            running = false;
        });

        for (var i = 0; i < this._pendingCalls.length; i++) {
            if (!running) {
                break;
            }
            var call = this._pendingCalls[i];
            if (typeof call === "function") {
                call();
            } else {
                this.asset[call.method] && this.asset[call.method].apply(this.asset, call.args);
            }
        }
    },

    getAnimationData: function () {
        return this.asset && this.asset.getAnimationData ? this.asset.getAnimationData.apply(this.asset, arguments) : undefined;
    },

    getAnimationDuration: function () {
        return this.asset && this.asset.getAnimationDuration ? this.asset.getAnimationDuration.apply(this.asset, arguments) : 0;
    },

    getTimeLeft: function () {
        return this.asset && this.asset.getTimeLeft ? this.asset.getTimeLeft.apply(this.asset, arguments) : 0;
    },

    isSaveToRemove: function () {
        return this.asset && this.asset.isSaveToRemove ? this.asset.isSaveToRemove.apply(this.asset, arguments) : true;
    },

    getTimeScale: function () {
        return this.asset && this.asset.getTimeScale ? this.asset.getTimeScale.apply(this.asset, arguments) : 1;
    },

    hasAnimation: function () {
        if (!this.isSpine()) {
            return false;
        }
        if (!this.asset) {
            cleverapps.throwAsync("using hasAnimation while resource not loaded " + this.virtualResource);
            return false;
        }
        return this.asset.hasAnimation.apply(this.asset, arguments);
    },

    setCompleteListener: function (callback) {
        if (this.asset && this.asset.setCompleteListener) {
            this.asset.setCompleteListener(callback);
        } else {
            callback && callback();
        }
    },

    setCompleteListenerRemove: function (callback) {
        if (this.asset && this.asset.setCompleteListenerRemove) {
            this.asset.setCompleteListenerRemove(callback);
        } else {
            callback && callback();
            this.removeFromParent();
        }
    },

    setCompleteListenerOnce: function (callback) {
        if (this.asset && this.asset.setCompleteListenerOnce) {
            this.asset.setCompleteListenerOnce(callback);
        } else {
            callback && callback();
        }
    },

    _processMethodCall: function (method, args) {
        if (this.asset) {
            this.asset[method] && this.asset[method].apply(this.asset, args);
        } else {
            this._pendingCalls.push({ method: method, args: args });
        }
    },

    setIdle: function () {
        this._processMethodCall("setIdle", arguments);
    },

    setIdleSet: function () {
        this._processMethodCall("setIdleSet", arguments);
    },

    setAnimation: function () {
        this._processMethodCall("setAnimation", arguments);
    },

    addAnimation: function () {
        this._processMethodCall("addAnimation", arguments);
    },

    setTimeScale: function () {
        this._processMethodCall("setTimeScale", arguments);
    },

    clearTrack: function () {
        this._processMethodCall("clearTrack", arguments);
    },

    setSkin: function () {
        this._processMethodCall("setSkin", arguments);
    },

    setAnimationAndIdleAfter: function () {
        this._processMethodCall("setAnimationAndIdleAfter", arguments);
    },

    setSafeToRemove: function () {
        this._processMethodCall("setSafeToRemove", arguments);
    },

    isLoaded: function () {
        return Boolean(this.asset);
    }
});

LazyAsset.TRY_LOAD_TIMEOUT = cleverapps.parseInterval("20 seconds");