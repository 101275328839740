/**
 * Created by mac on 12/10/21
 */

cleverapps.UI.HelpButton = cleverapps.UI.Button.extend({
    ctor: function (options) {
        options = options || {};

        this._super({
            type: options.type || cleverapps.styles.UI.Button.Images.help_button,
            onClicked: options.callback
        });
    }
});