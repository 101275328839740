/**
 * Created by spepa on 22.06.2023
 */

var Map2dFences = function (map) {
    this.map = map;
    this.fences = [];
    this.regions = [];

    for (var name in this.map.regions) {
        if (name.startsWith("fence_")) {
            this.map.regions[name].positions.forEach(function (cell) {
                this.addRegion(cell.x, cell.y, name);
            }, this);
        }
    }

    for (var y in this.regions) {
        for (var x in this.regions[y]) {
            this.addFence(+x, +y);
        }
    }

    Map2dFences.AutoCreateRegions(map);
};

Map2dFences.prototype.addRegion = function (x, y, regionName) {
    if (!this.regions[y]) {
        this.regions[y] = {};
    }

    this.regions[y][x] = regionName;
};

Map2dFences.prototype.removeRegion = function (x, y) {
    if (this.regions[y]) {
        delete this.regions[y][x];
    }
};

Map2dFences.prototype.getRegion = function (x, y) {
    return this.regions[y] && this.regions[y][x];
};

Map2dFences.prototype.addFence = function (x, y) {
    var regionName = this.getRegion(x, y);

    if (!this.fences[y]) {
        this.fences[y] = {};
    }

    if (!this.fences[y][x]) {
        this.fences[y][x] = [];
    }

    var types = this.calcCellFenceType(x, y);
    types.forEach(function (type) {
        if (type === "inner") {
            return;
        }
        var fence = new Map2dDecorator({
            regionName: regionName,
            code: type,
            x: x,
            y: y
        });
        Map2d.currentMap.decorators.addDecorator(x, y, fence);
        this.fences[y][x].push(fence);
    }.bind(this));
};

Map2dFences.prototype.removeFence = function (x, y) {
    if (this.fences[y] && this.fences[y][x]) {
        this.fences[y][x].forEach(function (fence) {
            Map2d.currentMap.decorators.removeDecorator(x, y, fence);
        });
        delete this.fences[y][x];
    }
};

Map2dFences.prototype.getFences = function (x, y) {
    return this.fences[y] && this.fences[y][x];
};

Map2dFences.prototype.calcCellFenceType = function (x, y) {
    var regionName = this.getRegion(x, y);

    var emptyDirs = "UDRL";

    for (var dir in Map2dFences.DIRS) {
        var neighbour = cc.pAdd(cc.p(x, y), Map2dFences.DIRS[dir]);
        var neighbourRegion = this.getRegion(neighbour.x, neighbour.y);

        var hasNeighbor = neighbourRegion && (neighbourRegion === regionName || Map2dFences.SINGLE_BORDER && "DL".includes(dir));

        if (hasNeighbor) {
            emptyDirs = emptyDirs.replace(dir, "");
        }
    }

    var types = Map2dFences.TYPES[emptyDirs || "inner"];
    if (bundles[regionName].meta.corner) {
        types = types.concat(Map2dFences.CORNER_TYPES[emptyDirs] || []);
        types = types.concat(this.getInnerCorners(x, y));
    }

    return types;
};

Map2dFences.prototype.getInnerCorners = function (x, y) {
    var innerCornersTypes = [];

    Object.keys(Map2dFences.DIAGONAL_DIRS).forEach(function (dir) {
        var diagonalNeighbour = cc.pAdd(cc.p(x, y), Map2dFences.DIAGONAL_DIRS[dir]);
        var diagonalNeighbourRegion = this.getRegion(diagonalNeighbour.x, diagonalNeighbour.y);
        if (diagonalNeighbourRegion && diagonalNeighbourRegion === this.getRegion(x, y)) {
            return;
        }

        for (var i = 0; i < dir.length; i++) {
            var neighbour = cc.pAdd(cc.p(x, y), Map2dFences.DIRS[dir[i]]);
            if (this.getRegion(neighbour.x, neighbour.y) !== this.getRegion(x, y)) {
                return;
            }
        }
        innerCornersTypes.push(Map2dFences.INNER_CORNER_TYPES[dir]);
    }.bind(this));

    return innerCornersTypes;
};

Map2dFences.prototype.getFencesNames = function () {
    var fences = [];
    for (var region in this.map.regions) {
        if (region.startsWith("fence_")) {
            fences.push(region);
        }
    }
    return fences;
};

Map2dFences.AutoCreateRegions = function (map) {
    Object.keys(bundles).forEach(function (name) {
        if (name.startsWith("fence_") && !map.regions[name]) {
            if (bundles[name].injectTo && bundles[name].injectTo.includes(Map2d.getUnitsTexture(map.unitsSkin))) {
                map.regions[name] = { positions: [] };
            }
        }
    });
};

Map2dFences.DIRS = {
    U: { y: -1, x: 0 },
    R: { y: 0, x: 1 },
    D: { y: 1, x: 0 },
    L: { y: 0, x: -1 }
};

Map2dFences.TYPES = {
    inner: ["inner"],
    U: ["up"],
    UR: ["up", "right"],
    R: ["right"],
    DR: ["down", "right"],
    D: ["down"],
    DL: ["left", "down"],
    L: ["left"],
    UL: ["up", "left"],
    RL: ["right", "left"],
    UD: ["up", "down"],
    DRL: ["left", "right", "down"],
    UDL: ["up", "left", "down"],
    URL: ["up", "right", "left"],
    UDR: ["up", "right", "down"],
    UDRL: ["up", "left", "right", "down"]
};

Map2dFences.CORNER_TYPES = {
    UR: ["upright"],
    DR: ["downright"],
    DL: ["downleft"],
    UL: ["upleft"],
    DRL: ["downright", "downleft"],
    UDL: ["downleft", "upleft"],
    URL: ["upright", "upleft"],
    UDR: ["upright", "downright"],
    UDRL: ["upright", "downright", "downleft", "upleft"]
};

Map2dFences.DIAGONAL_DIRS = {
    UR: { y: -1, x: 1 },
    UL: { y: -1, x: -1 },
    DR: { y: 1, x: 1 },
    DL: { y: 1, x: -1 }
};

Map2dFences.INNER_CORNER_TYPES = {
    UR: "innerupright",
    DR: "innerdownright",
    DL: "innerdownleft",
    UL: "innerupleft"
};

if (cleverapps.config.name === "wordsoup") {
    Map2dFences.SINGLE_BORDER = true;
}