/**
 * Created by ivan on 04.06.18.
 */

var RabbitTile = function (x, y) {
    BaseTile.call(this, x, y);
    
    this.isForGoal = x === undefined;
    this.isForEditor = x < 0;

    this.lives = 2;
    this.onHurtListener = function () {};
};

RabbitTile.prototype = Object.create(BaseTile.prototype);
RabbitTile.prototype.constructor = RabbitTile;
Skinify(RabbitTile, "rabbit");

RabbitTile.CODES = ["T"];

RabbitTile.prototype.getViewClass = function () {
    return RabbitTileView;
};

RabbitTile.prototype.getGoalId = function () {
    return RabbitTile.GOAL_ID;
};

RabbitTile.GOAL_ID = "rabbit";

RabbitTile.prototype.save = function () {
    var data = RabbitTile.CODES;
    if (this.isRoot) {
        data = data.concat(["" + this.size]);
    }
    if (this.lives > 1 || cleverapps.environment.isGameScene()) {
        data = data.concat(["l", "" + this.lives]);
    }
    return data;
};

RabbitTile.prototype.load = function (data) {
    var index = data.indexOf("l");
    if (index !== -1) {
        this.lives = parseInt(data[index + 1]);
        if (this.lives < 1) {
            this.alive = false;
        }
        data = data.slice(0, index);
    } else {
        this.lives = 1;
    }
    if (data.length > 1) {
        this.isRoot = true;
        this.size = parseInt(data[1]);
        if (Game.currentGame && !cleverapps.config.editorMode) {
            Game.currentGame.on("beforeStartActions", function () {
                this.children().forEach(function (child) {
                    child.root = this;
                }.bind(this));
            }.bind(this));
        }
    }
};

RabbitTile.prototype.hurt = function () {
    if (this.alive) {
        this.lives--;
        if (this.lives < 1) {
            this.alive = false;
        }
        this.onHurtListener();
        if (this.root) {
            this.root.checkAlive();
        } else if (!this.alive) {
            this.explode();
        }
    }
};

RabbitTile.prototype.checkAlive = function () {
    var anyAlive = this.children().some(function (child) {
        return child.alive;
    });
    if (!anyAlive) {
        this.children().forEach(function (child) {
            child.explode();
        });
        Game.currentGame.goalCounter.setTimeout(function () {}, 700);
    }
};

RabbitTile.prototype.children = function () {
    var children = [];
    for (var x = this.x; x < this.x + this.size; ++x) {
        for (var y = this.y; y < this.y + this.size; ++y) {
            children.push(Game.currentGame.field.floor[y][x]);
        }
    }
    return children;
};

RabbitTile.prototype.explode = function () {
    var EXPLODE_INTERVAL = this.getExplodeInterval();

    this.onBeforeExplodeListener();

    Game.currentGame.counter.setTimeout(function () {
        this.onAfterExplodeListener(this.x, this.y);
    }.bind(this), EXPLODE_INTERVAL * 1000);
};