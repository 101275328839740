/**
 * Created by Andrey Popov on 1/15/25.
 */

cc.AnimateDelta = cc.MoveBy.extend({
    ctor: function (moveDuration, fadeOutDuration, dy) {
        cc.MoveBy.prototype.ctor.call(this);
        this._moveDuration = moveDuration;
        this._fadeOutDuration = fadeOutDuration;

        this.initWithDuration(moveDuration + fadeOutDuration, cc.p(0, dy));
        this.easing(cc.easeIn(0.7));
    },

    startWithTarget: function (target) {
        cc.MoveBy.prototype.startWithTarget.call(this, target);

        target.replaceParentSamePlace(cleverapps.scenes.getMovingNode(target));
        target.setLocalZOrder(1);
        target.setVisible(true);
    },

    update: function (dt) {
        if (dt < this._moveDuration) {
            this._super(dt * this._duration / this._moveDuration);
        }

        if (!this.target) {
            return;
        }

        if (dt < 0.1) {
            this.target.opacity = 255 * dt * 10;
        } else if (dt > this._duration) {
            this.target.opacity = 0;
        } else if (dt > this._moveDuration) {
            this.target.opacity = 255 * (1 - (dt - this._moveDuration) / this._fadeOutDuration);
        } else {
            this.target.opacity = 255;
        }
    }
});