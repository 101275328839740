/**
 * Created by Slava Ivanov 13.10.22
 */

var TapToContinue = cc.Node.extend({
    ctor: function (options) {
        this._super();

        var text = cleverapps.UI.generateOnlyText(options.text, options.font || cleverapps.styles.FONTS.CLOSE_TEXT);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            text.setWrapWidth(cc.view.getFrameSize().width);
        }

        text.setPositionRound(text.width / 2, text.height / 2);
        this.setContentSize(text.getContentSize());

        this.setAnchorPoint(0.5, 0.5);

        this.addChild(text);

        this.setCascadeOpacityEnabled(true);

        this.setOpacity(0);
        this.setVisible(false);
        this.updateSize();
    },

    updateSize: function () {
        var availableWidth = cleverapps.resolution.getSceneSize().width;

        var scale = (availableWidth - cleverapps.styles.TapToContinue.padding) / this.width;
        var newScale = Math.min(scale, 1);

        this.setScale(newScale);
    },

    hide: function (silent) {
        this.stopAllActions();

        if (silent) {
            this.setVisible(false);
            return;
        }

        this.runAction(new cc.Sequence(
            new cc.FadeOut(0.1),
            new cc.Hide()
        ));
    },

    show: function () {
        this.runAction(
            new cc.Sequence(
                new cc.FadeOut(0),
                new cc.DelayTime(0.5),
                new cc.Show(),
                new cc.FadeIn(1)
            )
        );
    }
});

cleverapps.styles.TapToContinue = {
    padding: 100
};
