/**
 * Created by evgenijsenkevic on 26.12.2024
 */

var FriendsListRequestsToolbarItem = function () {
    ToolbarItem.call(this, ToolbarItem.FRIENDS_LIST_REQUESTS);

    this.enable();
};

FriendsListRequestsToolbarItem.prototype = Object.create(ToolbarItem.prototype);
FriendsListRequestsToolbarItem.prototype.constructor = FriendsListRequestsToolbarItem;

FriendsListRequestsToolbarItem.prototype.onClick = function () {
    this.unmark();

    cleverapps.focusManager.display({
        focus: "FriendsSendPresentWindow",
        action: function (f) {
            cleverapps.SocialManager.enforceLogin(function (code) {
                if (code !== cleverapps.CODE_SUCCEED) {
                    f();
                    return;
                }

                if (cleverapps.platformLogic.chooseFriend) {
                    cleverapps.platformLogic.chooseFriend(function (friendId) {
                        f();

                        if (!friendId) {
                            return;
                        }

                        levels.friendRequests.sendServerRequest(friendId, levels.FriendRequest.TYPE_SENT_COINS_UNIT);
                    });
                } else {
                    f();
                }
            });
        }
    });
};
