/**
 * Created by mac on 7/22/17.
 */

var LevelTutorial = function (steps, onFinish) {
    this.data = steps;
    this.step = -1;
    this.finished = false;
    this.onFinishCallbacks = [onFinish];

    this.onAfterResize = function () {};
};

LevelTutorial.prototype.runOnFinishCallbacks = function () {
    this.onFinishCallbacks.forEach(function (callback) {
        callback();
    });
    this.onFinishCallbacks = [];
};

LevelTutorial.prototype.nextStep = function () {
    this.step++;
    if (this.step >= this.data.length) {
        this.runOnFinishCallbacks();
        this.finished = true;
    } else {
        Game.currentGame.hideAvailableMove();

        var stepData = this.data[this.step];
        this.prepare(stepData);
        Game.currentGame.trigger("tutorial:nextStep", stepData);
    }
};

LevelTutorial.prototype.finishAll = function () {
    this.finishStep();
    this.step = this.data.length;
    this.finished = true;
    this.runOnFinishCallbacks();
};

LevelTutorial.prototype.finishStep = function () {
    for (var y = 0; y < Field.SIZE; y++) {
        for (var x = 0; x < Field.SIZE; x++) {
            var cell = Game.currentGame.field.cells[y][x];
            if (cell && cell.tutorialMovable !== undefined) {
                cell.movable = cell.tutorialMovable;
                cell.hurtable = cell.tutorialHurtable;
                delete cell.tutorialMovable;
                delete cell.tutorialHurtable;
            }
        }
    }

    Game.currentGame.tryMove = this.oldGameTryMove;
    this.oldGameTryMove = function () {};
    Game.currentGame.boosters.setActive = this.oldBoostersSetActive;
    this.oldBoostersSetActive = function () {};
    Game.currentGame.boosters.onExecute = this.oldBoostersOnExecute;
    this.oldBoostersOnExecute = function () {};
    Game.currentGame.availableMove.setDisabled(false);

    Game.currentGame.trigger("tutorial:finishStep");
};

LevelTutorial.prototype.existInControll = function (x, y) {
    var stepData = this.data[this.step];
    for (var i = 0; i < stepData.control.length; i++) {
        if (x === stepData.control[i][0] && y === stepData.control[i][1]) {
            return true;
        }
    }
    return false;
};

LevelTutorial.prototype.prepare = function (stepData) {
    var x, y, cell;

    for (y = 0; y < Field.SIZE; y++) {
        for (x = 0; x < Field.SIZE; x++) {
            cell = Game.currentGame.field.cells[y][x];
            if (cell && !this.existInControll(x, y)) {
                cell.tutorialMovable = cell.movable;
                cell.tutorialHurtable = cell.hurtable;
                cell.movable = false;
                cell.hurtable = false;
            }
        }
    }

    this.oldGameTryMove = Game.currentGame.tryMove;
    Game.currentGame.tryMove = function (dir, fromPos) {
        var from = Game.currentGame.field.selected;
        if (fromPos) {
            from = Game.currentGame.field.cells[fromPos.row] ? Game.currentGame.field.cells[fromPos.row][fromPos.col] : undefined;
        }

        var isValidMove = Game.currentGame.field.isValidMove(from, dir);
        if (!isValidMove) {
            return;
        }
        this.finishStep();
        Game.currentGame.tryMove(dir, fromPos);
    }.bind(this);

    if (stepData.booster !== undefined) {
        var amount = cleverapps.boosters.getAmount(stepData.booster) + 1;
        cleverapps.boosters.setAmount(stepData.booster, amount);
    }

    this.oldBoostersSetActive = Game.currentGame.boosters.setActive;
    Game.currentGame.boosters.setActive = function (booster) {
        if (stepData.booster !== undefined && booster && booster.id === stepData.booster) {
            this.finishStep();
            Game.currentGame.boosters.setActive(booster);
            this.nextStep(true);
        }
    }.bind(this);

    this.oldBoostersOnExecute = Game.currentGame.boosters.onExecute;
    Game.currentGame.boosters.onExecute = function (targetCell) {
        if (targetCell && this.existInControll(targetCell.x, targetCell.y)) {
            this.finishStep();
            Game.currentGame.boosters.onExecute(targetCell);
        }
    }.bind(this);

    Game.currentGame.availableMove.setDisabled(true);

    if (stepData.cellBooster) {
        if (!stepData.cellBooster.alive || !Game.currentGame || Game.currentGame.outcome !== GameBase.OUTCOME_UNKNOWN) {
            this.finishStep();
            return;
        }

        stepData.light = [];
        for (y = 0; y < Field.SIZE; y++) {
            for (x = 0; x < Field.SIZE; x++) {
                cell = Game.currentGame.field.cells[y][x];
                if (cell && cell instanceof BoosterCell) {
                    stepData.light.push([cell.x, cell.y]);
                }
            }
        }
    }
};

LevelTutorial.prototype.afterResize = function () {
    this.onAfterResize();
};