/**
 * Created by razial on 16.01.2025.
 */

var SimpleLogic = function () {
    this.currentLocationId = 0;
};

SimpleLogic.prototype.reset = function () {
    this.currentLocationId = 0;
};

SimpleLogic.prototype.getMainLocation = function () {
    return this.currentLocationId;
};

SimpleLogic.prototype.getLocationClass = function () {
    return SimpleLocation;
};

SimpleLogic.prototype.getMainScene = function () {
    return SimpleMainScene;
};

SimpleLogic.prototype.listAvailableLocations = function () {
    var ids = [];
    var id = 0;

    var exist = function () {
        if (cleverapps.config.rpg) {
            return bundles["episode_" + id];
        }

        return bundles["simple_background_" + id]
            || bundles["simple_background_horizontal_" + id]
            || bundles["simple_background_vertical_" + id];
    };

    while (exist()) {
        ids.push(id);
        id++;
    }

    return ids;
};

SimpleLogic.prototype.listActiveLocations = function () {
    var locationId = this.currentLocationId;

    var available = cleverapps.meta.listAvailableLocations();
    if (available.length && available[available.length - 1] < locationId) {
        locationId = available[available.length - 1];
    }

    return [locationId];
};

SimpleLogic.prototype.isCompleted = function () {
    var available = cleverapps.meta.listAvailableLocations();
    return !available.length
        || available[available.length - 1] < this.currentLocationId
        || cleverapps.meta.location && cleverapps.meta.location.locationId === this.currentLocationId && cleverapps.meta.location.isCompleted();
};

SimpleLogic.prototype.moveNextLocation = function () {
    this.currentLocationId += 1;
};

SimpleLogic.prototype.processChatMessage = function (message) {
    if (message.metha.simple) {
        // todo fix after SimpleAdapter will be removed
        cleverapps.meta.updateInfo({
            current: message.metha.simple.current,
            stars: 0
        });
    }
};
