/**
 * Created by mac on 2/3/20
 */

var MethaHelper = {
    start: function (f, level) {
        if (cleverapps.isLevels() && level.episodeNo >= levels.user.getEpisodesAmount()) {
            f();
            return;
        }

        if (!LevelStartWindow.isAvailable(level) || cleverapps.config.features.includes("boosters_before") && !WindowBoostersBefore.isAvailable()) {
            level.play(f);
            return;
        }

        cleverapps.focusManager.showControlsWhileFocused("MenuBarGoldItem");

        if (level.building) {
            new BuildingStartWindow({
                building: level.building,
                level: level
            });
        } else {
            new LevelStartWindow(level.getStartWindowOptions());
        }
        cleverapps.focusManager.onceNoWindowsListener = f;
    },

    getCurrentLevel: function (building) {
        if (cleverapps.meta.getType() === Meta.FARM) {
            var label = building.label;

            cleverapps.playButton.disableIncLevel();
            if (label === undefined || label.episodeNo >= levels.user.getEpisodesAmount()) {
                var nextLevel = cleverapps.meta.getMainObject().chooseNextLevel();
                label = {
                    episodeNo: nextLevel.episodeNo,
                    levelNo: nextLevel.levelNo
                };
            }

            if (building.label !== label) {
                building.label = label;
                cleverapps.meta.getMainObject().save();
            }
            cleverapps.playButton.enableIncLevel();

            var level = MethaHelper.getLevel(label.episodeNo, label.levelNo);
            level.building = building;
            return level;
        }

        if (cleverapps.isKnockoutGame()) {
            return cleverapps.knockoutGame.getNextLevel();
        }

        return cleverapps.user.getCurrentLevel();
    },

    getLevel: function (episodeNo, levelNo) {
        return new Level(episodeNo, levelNo);
    }
};
