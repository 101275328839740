/**
 * Created by Denis Kuzin on 13 december 2024
 */

var Map2dScroller = function (map) {
    Map2dScroller.currentScroller = this;

    this.map = map;

    this.loadScroll();

    this.onDiscoverScrollView = function () {};
    this.getScrollZoom = function () {};
    this.isScrollProcessing = function () {};
};

Map2dScroller.prototype.loadScroll = function () {
    var slot = Game.currentGame && Game.currentGame.slot || Meta.SLOT_MAIN;

    var stored = cleverapps.dataLoader.load(DataLoader.TYPES.MAP2D + slot)
        || cleverapps.dataLoader.load(DataLoader.TYPES.SCROLL + slot) || { zoom: 1.2 };

    this.setScrollCell(stored.scrollCell);
    this.zoom = stored.zoom;
};

Map2dScroller.prototype.saveScroll = function () {
    var slot = Game.currentGame && Game.currentGame.slot || Meta.SLOT_MAIN;

    cleverapps.dataLoader.save(DataLoader.TYPES.MAP2D + slot, {
        zoom: this.zoom,
        scrollCell: this.scrollCell
    });
};

Map2dScroller.prototype.getScrollView = function () {
    return this.onDiscoverScrollView();
};

Map2dScroller.prototype.onUpdateScroll = function () {
    var game = Game.currentGame;
    if (this.map.stopped || game && !game.showUpFinished) {
        return;
    }

    this.setScrollCell(this.map.getScreenCenterCell() || this.scrollCell, true);
    this.setZoom(this.getScrollZoom() || this.zoom);
};

Map2dScroller.prototype.setScrollCell = function (scrollCell, save) {
    if (scrollCell === undefined) {
        this.scrollCell = undefined;
        return;
    }

    if (scrollCell.x === undefined || scrollCell.y === undefined) {
        cleverapps.throwAsync("Incorrect scrollCell value for Map2d."
            + " x:" + scrollCell.x
            + " y:" + scrollCell.y);
        return;
    }

    if (!cc.pointEqualToPoint(this.scrollCell, scrollCell)) {
        this.scrollCell = cc.p(scrollCell.x, scrollCell.y);

        if (save) {
            this.saveScroll();
        }
    }
};

Map2dScroller.prototype.setZoom = function (zoom) {
    if (this.zoom !== zoom) {
        this.zoom = zoom;

        this.saveScroll();
    }
};

Map2dScroller.prototype.getBasicZoom = function () {
    var zoom = 1;

    if (this.zoom) {
        zoom = this.zoom;
    } else if (cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL) {
        zoom = connector.info.isMobile ? 1.85 : 1.2;
    }

    if (cleverapps.skins.getSlot("basicZoom", zoom)) {
        zoom = cleverapps.skins.getSlot("basicZoom", zoom);
    }

    if (cleverapps.config.editorMode) {
        zoom = 1;
    }

    if (cleverapps.config.demoMode) {
        zoom = 0.4;
    }

    return zoom;
};

Map2dScroller.prototype.zoomIn = function (unit, options) {
    if (!this.zoomInitParams) {
        this.zoomInitParams = {
            zoom: this.zoom,
            focus: this.map.getMapView().getCell(this.scrollCell.x, this.scrollCell.y)
        };
    }
    var duration = options.duration !== undefined ? options.duration : 0.5;

    this.scrollToUnit(duration, unit, options);
};

Map2dScroller.prototype.zoomOut = function (callback) {
    callback = callback || function () {};

    if (this.zoomInitParams) {
        var current = this.map.getMapView().alignInGrid(this.scrollCell.x, this.scrollCell.y);
        var target = this.map.getMapView().alignInGrid(this.zoomInitParams.focus.cell.x, this.zoomInitParams.focus.cell.y);

        var targetCell = cc.pDistance(current, target) < cleverapps.resolution.getSceneSize().height ? this.zoomInitParams.focus : undefined;
        var scrollView = this.onDiscoverScrollView();

        scrollView.stopAutoScroll();
        scrollView.runAction(new cc.Sequence(
            new cc.ZoomAction(0.5, {
                zoom: this.zoomInitParams.zoom,
                zoomFocus: targetCell
            }),
            new cc.CallFunc(callback)
        ));

        delete this.zoomInitParams;
    } else {
        callback();
    }
};

Map2dScroller.prototype.cancelZoomOut = function () {
    delete this.zoomInitParams;
};

Map2dScroller.prototype.scrollToUnit = function (duration, target, options) {
    this.map.showTiles(target.x, target.y);

    var targetView = target.onGetView ? target.onGetView() : this.map.getMapView().getCell(target.x, target.y);
    var scrollView = this.onDiscoverScrollView();

    var sceneSize = cleverapps.resolution.getSceneSize();
    if (options.position === (cleverapps.UI.DOCK_LEFT | cleverapps.UI.DOCK_RIGHT)) {
        options.position = Map2d.currentMap.isCloseToMapLeft(target) ? cleverapps.UI.DOCK_LEFT : cleverapps.UI.DOCK_RIGHT;
    }

    options.centerOffset = options.centerOffset || { x: 0, y: 0 };
    options.centerOffset.y += cleverapps.scenes.getRunningScene().y;
    if ([cleverapps.UI.DOCK_RIGHT, cleverapps.UI.DOCK_LEFT].includes(options.position)) {
        var x = sceneSize.width / 4;

        options.centerOffset = {
            x: options.position === cleverapps.UI.DOCK_RIGHT ? x : -x,
            y: options.centerOffset.y
        };
    } else if ([cleverapps.UI.DOCK_TOP, cleverapps.UI.DOCK_BOTTOM].includes(options.position)) {
        var y = sceneSize.height / 4;

        options.centerOffset = {
            x: options.centerOffset.x,
            y: options.position === cleverapps.UI.DOCK_TOP ? y : -y

        };
    }

    options.zoom = options.zoom || this.zoom;

    var padding = options.padding || {};
    padding.top = padding.top || 0;
    padding.bottom = padding.bottom || 0;
    padding.right = padding.right || 0;
    padding.left = padding.left || 0;

    var maxTop = (sceneSize.height / 2 - options.centerOffset.y - padding.top) / (targetView.height / 2);
    var maxBottom = (sceneSize.height / 2 + options.centerOffset.y - padding.bottom) / (targetView.height / 2);
    var maxRight = (sceneSize.width / 2 - options.centerOffset.x - padding.right) / (targetView.width / 2);
    var maxLeft = (sceneSize.width / 2 + options.centerOffset.x - padding.left) / (targetView.width / 2);

    options.zoom = Math.min(maxTop, maxBottom, maxRight, maxLeft, options.zoom);

    options.centerOffset.y /= options.zoom;
    options.centerOffset.x /= options.zoom;

    scrollView.stopAutoScroll();
    scrollView.runAction(new cc.Sequence(
        new cc.ZoomAction(duration, {
            zoom: options.zoom,
            zoomFocus: targetView,
            focusCenterOffset: options.centerOffset
        }),
        new cc.CallFunc(options.callback || function () {})
    ));
};
