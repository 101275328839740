/**
 * Created by vladislav on 23.12.2020
 */

var AdsPlayButton = cleverapps.UI.Button.extend({
    ctor: function (properties) {
        this.properties = properties;

        var styles = cleverapps.styles.AdsPlayButton;

        var text = properties.text && AdsPlayButton.TEXTS[properties.text] || "Play";
        if (Messages.has(text)) {
            text = Messages.get(text);
        }
        text = text.replace("%{levelNo}", properties.levelNo);
        if (properties.textToUpperCase) {
            text = text.toUpperCase();
        }

        var options = {
            text: text,
            width: properties.width || styles.width,
            height: properties.height || styles.height,
            onClicked: function () {
                if (typeof FbPlayableAd === "undefined") {
                    console.log("FbPlayableAd failed");
                } else {
                    FbPlayableAd.onCTAClick();
                }
            }
        };

        if (properties.behavior && properties.behavior !== "default") {
            options.spine = bundles.ads_buttons.jsons.btn_light_json;
            options.skin = properties.behavior;
        } else if (properties.images) {
            options.type = cleverapps.styles.UI.Button.Images[properties.images];
        } else {
            options.button_png = bundles.buttons_main.frames.play_button_png;
            options.button_on_png = bundles.buttons_main.frames.play_button_on_png;
        }

        this._super(options);

        this.show();
    },

    hide: function () {
        this.setVisible(false);

        FingerView.remove(this.finger);
        this.finger = undefined;
    },

    show: function () {
        this.setVisible(true);

        if (this.properties.scale) {
            this.baseScale = this.properties.scale;
        }

        var clipProps = cleverapps.wysiwyg.hierarchy.getSelectedClip() ? cleverapps.wysiwyg.hierarchy.getSelectedClip().properties : undefined;
        if (clipProps && clipProps.finger) {
            this.runAction(new cc.CallFunc(function () {
                var finger = this.finger = FingerView.create({
                    image: cleverapps.wysiwyg.googleDriveProvider.getLink(clipProps.finger),
                    scale: 1 / (this.properties.scale || 1)
                });

                var actions = [];

                var origin = finger.prepareTarget({
                    node: this,
                    position: cc.p(this.width / 2, this.height / 2 - 300)
                });
                var target = finger.prepareTarget({
                    node: this,
                    position: cc.p(this.width / 2, this.height / 2)
                });

                if (this.properties.behavior && this.properties.behavior !== "default") {
                    actions.push(new cc.DelayTime(0.5));
                    actions.push(new cc.PlaySound(bundles.ads_buttons.urls.play_shine_2_effect));
                    actions.push(new cc.CallFunc(function () {
                        this.setInteractiveState("adsplaybutton", "pressed");
                        this.applyInteractiveScale();
                    }.bind(this)));
                    actions.push(new cc.DelayTime(1.0));
                    actions.push(new cc.CallFunc(function () {
                        this.removeInteractiveStates("adsplaybutton");
                        this.applyInteractiveScale();
                    }.bind(this)));
                    actions.push(new cc.DelayTime(0.5));
                    actions.push(finger.moveTo(0, origin));
                    actions.push(finger.press());
                    actions.push(finger.moveTo(0.5, target));
                    actions.push(new cc.PlaySound(bundles.ads_buttons.urls.play_shine_1_effect));
                    actions.push(new cc.CallFunc(function () {
                        this.showAlert();
                    }.bind(this)));
                    actions.push(new cc.DelayTime(0.3));
                    actions.push(finger.moveTo(0.5, origin));
                    actions.push(finger.release());
                } else {
                    actions.push(new cc.DelayTime(0.5));
                    actions.push(finger.moveTo(0, target));
                    var i = 0;
                    while (i++ < 10) {
                        actions.push(finger.press());
                        actions.push(new cc.DelayTime(0.3));
                        actions.push(finger.release());
                        actions.push(new cc.DelayTime(0.3));
                    }
                }

                finger.play({
                    actions: actions
                });
            }.bind(this)));
        }

        if (!this.properties.isPreview) {
            this.setScale(0);
            this.runAction(new cc.ScaleTo(0.4, this.properties.scale || 1.0).easing(cc.easeBackOut()));
        }
    }
});

AdsPlayButton.BUNDLES = ["ads_buttons"];

AdsPlayButton.TEXTS = {
    PLAY: "Play",
    LEVEL: "PlayButton.text",
    HARD_LEVEL: "message.hardLevel",
    TRICKY_LEVEL: "message.trickyLevel",
    CONTINUE: "Continue",
    TRY_AGAIN: "TryAgain",
    PLAY_FREE: "wysiwyg.PlayFree",
    PLAY_NOW: "wysiwyg.PlayNow"
};

AdsPlayButton.PROPERTIES = [{
    name: "text", type: "string", items: Object.keys(AdsPlayButton.TEXTS), bubbleUp: true
}, {
    name: "textToUpperCase", type: "checkBox"
}, {
    name: "levelNo", type: "number", value: 1
}, {
    name: "behavior", type: "string", items: ["default", "red", "green"], bubbleUp: true
}];

if (["merge", "match3"].indexOf(cleverapps.config.type) !== -1) {
    AdsPlayButton.PROPERTIES.push({
        name: "images",
        type: "string",
        items: function () {
            return Object.keys(cleverapps.styles.UI.Button.Images);
        }
    });

    cleverapps.overrideTemplates(cleverapps.styles.UI.Button.Images, {
        button_purpure: {
            button_png: bundles.ads_buttons.frames.ads_purpure_button,
            button_on_png: bundles.ads_buttons.frames.ads_purpure_button_on
        }
    });
}

cleverapps.styles.AdsPlayButton = {
    width: 300,
    height: 140,

    x: { align: "center" },
    y: { align: "center", dy: -300 }
};
