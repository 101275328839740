/**
 * Created by Andrey Popov on 1/25/21.
 */

var WysiwygMenubar = function (wysiwyg) {
    this.wysiwyg = wysiwyg;

    cleverapps.EventEmitter.call(this);

    this.copiedItem = undefined;
    this.copiedItems = [];

    this.selectedClipStatus = "";
    this.selectedClipStatusText = ""; 

    this.wysiwyg.hierarchy.on("selectionChanged", this.updateTools.bind(this));
};

WysiwygMenubar.prototype = Object.create(cleverapps.EventEmitter.prototype);
WysiwygMenubar.prototype.constructor = WysiwygMenubar;

WysiwygMenubar.prototype.updateTools = function () {
    var itemType = this.wysiwyg.hierarchy.selectedItem ? this.wysiwyg.hierarchy.selectedItem.type : undefined;

    if (itemType && itemType === HierarchyItem.TYPE.DIRECTORY) {
        this.trigger("updateTool", "Add item", this.wysiwyg.directory !== "wysiwyg");
        this.trigger("updateTool", "Copy", false);
        this.trigger("updateTool", "Paste", this.copiedItem && this.copiedItem.type === HierarchyItem.TYPE.CLIP);
        this.trigger("updateTool", "Make ready", false);
        this.trigger("updateTool", "Delete item", false);
        this.trigger("updateTool", "Fullscreen preview", false);
        this.trigger("updateTool", "Open Google Disk", false);
        return;
    }

    this.trigger("updateTool", "Add item", itemType === HierarchyItem.TYPE.CLIP);
    this.trigger("updateTool", "Copy", itemType !== undefined);
    this.trigger("updateTool", "Make ready", itemType === HierarchyItem.TYPE.CLIP && this.wysiwyg.directory === "wysiwyg/tmp");

    if (this.copiedItem
        && (itemType === HierarchyItem.TYPE.CLIP && this.copiedItem.type === HierarchyItem.TYPE.SCENE
            || [HierarchyItem.TYPE.SCENE, HierarchyItem.TYPE.COMPONENT].indexOf(itemType) !== -1 && [HierarchyItem.TYPE.SCRIPT, HierarchyItem.TYPE.COMPONENT].indexOf(this.copiedItem.type) !== -1)) {
        this.trigger("updateTool", "Paste", true);
    } else {
        this.trigger("updateTool", "Paste", false);
    }

    this.trigger("updateTool", "Delete item", itemType !== undefined);
    this.trigger("updateTool", "Fullscreen preview", true);
    this.trigger("updateTool", "Open Google Disk", true);

    this.wysiwyg.updateClipStatus();
};

WysiwygMenubar.prototype.updateClipStatus = function (status, text) {
    this.selectedClipStatus = status;
    this.selectedClipStatusText = text;
    this.trigger("updateStatusText", this.selectedClipStatusText);
};

WysiwygMenubar.TOOLS = [{
    caption: "Add item",
    icon: bundles.wysiwyg.frames.plus,
    key: "a",
    processor: function () {
        var hierarchy = this.wysiwyg.hierarchy;
        if (!hierarchy.selectedItem) {
            return;
        }

        if (hierarchy.selectedItem.type === HierarchyItem.TYPE.DIRECTORY) {
            var clip = HierarchyItem.createClip("clip_" + hierarchy.getNewClipIndex());
            hierarchy.addItem(clip);
            var sceneItem = HierarchyItem.createScene("scene_" + (clip.children.length + 1), clip);
            hierarchy.addItem(sceneItem);

            hierarchy.save(clip, function () {
                setTimeout(function () {
                    window.location.reload();
                }, 2000);
            });
        } else if (hierarchy.selectedItem.type === HierarchyItem.TYPE.CLIP) {
            hierarchy.addItem(HierarchyItem.createScene("scene_" + (hierarchy.selectedItem.children.length + 1), hierarchy.selectedItem));
        }
    }
}, {
    caption: "Copy",
    icon: bundles.wysiwyg.frames.copy,
    key: "c",
    processor: function () {
        this.copiedItem = this.wysiwyg.hierarchy.selectedItem;
        this.copiedItems = this.wysiwyg.hierarchy.selectedItems;
        this.updateTools();
    }
}, {
    caption: "Paste",
    icon: bundles.wysiwyg.frames.paste,
    key: "v",
    processor: function () {
        if (this.copiedItems.length) {
            this.copiedItems.forEach(function (item) {
                this.wysiwyg.hierarchy.pasteItem(item);
            }.bind(this));
        } else {
            this.wysiwyg.hierarchy.pasteItem(this.copiedItem);
        }
        this.updateTools();
    }
}, {
    caption: "Delete item",
    icon: bundles.wysiwyg.frames.delete,
    key: "z",
    processor: function () {
        if (window.confirm("Selected item will be removed. Continue?")) {
            this.wysiwyg.hierarchy.removeItem(this.wysiwyg.hierarchy.selectedItem);
        }
    }
}, {
    caption: "Make ready",
    icon: bundles.wysiwyg.frames.make_ready,
    key: "e",
    processor: function () {
        this.wysiwyg.hierarchy.makeReady();
    }
}, {
    caption: "Fullscreen preview",
    icon: bundles.wysiwyg.frames.fullscreen_icon,
    key: "p",
    processor: function () {
        var selected = this.wysiwyg.hierarchy.getSelectedClip();
        if (!selected || selected.type === HierarchyItem.TYPE.DIRECTORY) {
            return;
        }

        if (this.wysiwyg.playable) {
            this.wysiwyg.capturePlayableAds(selected);
        } else {
            this.wysiwyg.previewVideo();
        }
    }
}, {
    caption: "Open Google Disk",
    icon: bundles.wysiwyg.frames.video_icon,
    key: "g",
    processor: function () {
        if (this.wysiwyg.playable) {
            var clip = this.wysiwyg.hierarchy.getSelectedClip();
            if ([Wysiwyg.PLAYABLE_STATUSES.READY, Wysiwyg.PLAYABLE_STATUSES.NO_ZIP].indexOf(this.wysiwyg.wysiwygMenubar.selectedClipStatus) !== -1) {
                this.updateClipStatus(this.selectedClipStatus, "BuildZip started...");
                this.wysiwyg.buildPlayable(clip);
            } else {
                this.wysiwyg.capturePlayableAds(clip, true);
            }
        } else {
            connector.platform.openUrl("https://drive.google.com/drive/folders/1TxCMoaNO7ttThSpbTXWVVkKMHdjAlZ15?usp=sharing");
        }
    }
}];