/**
 * Created by mac on 5/6/17.
 */
cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    RPG_LIFE_VIEW_TEXT: {
        name: "default",
        size: 38
    }
});

cleverapps.overrideStyles(cleverapps.styles.FieldView, {
    slideAnimation: {
        controlsPanel: {
            delay: 3.5
        },
        movesView: {
            offsetY: -200
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.Actor, {
    showDelay: 3
});

cleverapps.overrideStyles(cleverapps.styles.GameScene, {
    sound: {
        delay: 3.3
    },

    actor: {
        size: {
            width: 250,
            height: 450
        },
        spinePosition: {
            x: { align: "center" },
            y: { align: "center", dy: -110 }
        },
        mobileScale: 0.7,
        scaleX: -1,
        messageText: {
            width: 850,
            height: 500
        },
        wideModePositions: [
            {
                x: { align: "center", dx: 217 },
                y: { align: "bottom", dy: -50 }
            },
            {
                x: { align: "right", dx: -40 },
                y: { align: "bottom", dy: -50 }
            },
            {
                x: { align: "right", dx: -40 },
                y: { align: "bottom", dy: -50 }
            }
        ],

        animation: Actor.ANIMATIONS.HAPPY
    },

    startAnimation: {
        animation: {
            offsetY: 15
        }
    }
});

cleverapps.styles.UI.BoostersPositions = [
    [
        { x: { align: "center", dx: -250 }, y: { align: "center", dy: 40 } },
        { x: { align: "center", dx: -110 }, y: { align: "center", dy: 40 } },
        { x: { align: "center", dx: 30 }, y: { align: "center", dy: 40 } }
    ],
    [
        { x: { align: "center", dx: -30 }, y: { align: "center", dy: 280 } },
        { x: { align: "center", dx: -30 }, y: { align: "center", dy: 75 } },
        { x: { align: "center", dx: -30 }, y: { align: "center", dy: -130 } }
    ],
    [
        { x: { align: "center", dx: -30 }, y: { align: "center", dy: 310 } },
        { x: { align: "center", dx: -30 }, y: { align: "center", dy: 95 } },
        { x: { align: "center", dx: -30 }, y: { align: "center", dy: -120 } }
    ]
];

cleverapps.overrideStyles(cleverapps.styles.ConfirmExitWindow, {
    animation: {
        height: 550,

        y: { align: "center", dy: -60 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.GoalsView, {
    zOrders: [5, 4, 3],

    goals: {
        offsetY: [-60, -50, 0]
    },

    startAnimation: {
        width: 800,
        goals: {
            scale: [1.0, 1.4, 1.4],
            delay: 0.5,
            y: -50
        }
    },

    goalsLayout: {
        scales: [0.6, 0.75, 0.8]
    },

    positionNoMission: [{
        x: { align: "center", dx: 20 },
        y: { align: "top", dy: 47 }
    }, {
        x: { align: "center", dx: -30 },
        y: { align: "top", dy: 32 }
    }, {
        x: { align: "left" },
        y: { align: "center", dy: -50 }
    }],

    positionWithMission: [{
        x: { align: "center", dx: 20 },
        y: { align: "top" }
    }, {
        x: { align: "center", dx: -30 },
        y: { align: "top" }
    }, {
        x: { align: "left" },
        y: { align: "center", dy: -50 }
    }],

    sizes: {
        noMission: [{
            height: 350,
            width: 500
        }, {
            height: 350,
            width: 850
        }, {
            height: 950,
            width: 350
        }],

        withMission: [{
            height: 350,
            width: 500
        }, {
            height: 350,
            width: 740
        }, {
            height: 950,
            width: 350
        }]
    }
});

cleverapps.overrideStyles(cleverapps.styles.BaseGoalView, {
    width: 100,
    height: 120,

    withTournament: {
        width: 100,
        height: 120
    },

    no_moves: {
        width: 100,
        height: 120,
        scaleX: -0.5,
        scaleY: -0.5
    }

});

cleverapps.overrideStyles(cleverapps.styles.BaseGoalView, {
    enemies: [
        {
            spine: bundles.enemy0.jsons.enemy0,
            sfx: {
                attack: bundles.game.urls.enemy_attack,
                die: bundles.game.urls.enemy_die
            }
        },
        {
            spine: bundles.enemy1.jsons.enemy1,
            sfx: {
                attack: bundles.game.urls.enemy_attack,
                die: bundles.game.urls.enemy_die
            }
        },
        {
            spine: bundles.enemy2.jsons.enemy2,
            sfx: {
                attack: bundles.game.urls.enemy_attack,
                die: bundles.game.urls.enemy_die
            }
        },
        {
            spine: bundles.enemy3.jsons.enemy3,
            sfx: {
                attack: bundles.game.urls.enemy_attack,
                die: bundles.game.urls.enemy_die
            }
        },
        {
            spine: bundles.enemy4.jsons.enemy4,
            sfx: {
                attack: bundles.game.urls.enemy_attack,
                die: bundles.game.urls.enemy_die
            }
        },
        {
            spine: bundles.enemy5.jsons.enemy5,
            sfx: {
                attack: bundles.game.urls.enemy_attack,
                die: bundles.game.urls.enemy_die
            }
        },
        {
            spine: bundles.enemy6.jsons.enemy6,
            sfx: {
                attack: bundles.game.urls.enemy_attack,
                die: bundles.game.urls.enemy_die
            }
        },
        {
            spine: bundles.enemy7.jsons.enemy7,
            sfx: {
                attack: bundles.game.urls.enemy_attack,
                die: bundles.game.urls.enemy_die
            }
        },
        {
            spine: bundles.enemy8.jsons.enemy8,
            sfx: {
                attack: bundles.game.urls.enemy_attack,
                die: bundles.game.urls.enemy_die
            }
        },
        {
            spine: bundles.enemy9.jsons.enemy9,
            sfx: {
                attack: bundles.game.urls.enemy_attack,
                die: bundles.game.urls.enemy_die
            }
        },
        {
            spine: bundles.enemy10.jsons.enemy10,
            sfx: {
                attack: bundles.game.urls.enemy_attack,
                die: bundles.game.urls.enemy_die
            }
        },
        {
            spine: bundles.enemy11.jsons.enemy11,
            sfx: {
                attack: bundles.game.urls.enemy_attack,
                die: bundles.game.urls.enemy_die
            }
        },
        {
            spine: bundles.enemy12.jsons.enemy12,
            sfx: {
                attack: bundles.game.urls.enemy_attack,
                die: bundles.game.urls.enemy_die
            }
        },
        {
            spine: bundles.enemy13.jsons.enemy13,
            sfx: {
                attack: bundles.game.urls.enemy_attack,
                die: bundles.game.urls.enemy_die
            }
        },
        {
            spine: bundles.enemy14.jsons.enemy14,
            sfx: {
                attack: bundles.game.urls.enemy_attack,
                die: bundles.game.urls.enemy_die
            }
        }
    ],
    flag: {
        x: 30,
        y: -120
    },
    actor: {
        scale: 0.8,
        position: {
            x: { align: "left", dx: 0 },
            y: { align: "top", dy: 0 }
        },
        size: {
            width: 200,
            height: 300
        },
        spinePosition: {
            x: { align: "center" },
            y: { align: "bottom" }
        },
        disableAttack: true,
        attacks: [Actor.ANIMATIONS.ATTACK1, Actor.ANIMATIONS.ATTACK2]
    },
    collect: {
        bySpine: {
            "default": {
                x: 0,
                y: 0
            },
            "0": {
                x: 16,
                y: 54
            },
            "1": {
                x: 20,
                y: 52
            },
            "2": {
                x: 13,
                y: 67
            },
            "3": {
                x: 19,
                y: 92
            },
            "4": {
                x: 39,
                y: -18
            },
            "5": {
                x: 6,
                y: -16
            },
            "6": {
                x: 28,
                y: 50
            },
            "7": {
                x: 8,
                y: -7
            },
            "8": {
                x: 17,
                y: 79
            },
            "9": {
                x: 29,
                y: 164
            },
            "10": {
                x: 22,
                y: 60
            },
            "11": {
                x: 19,
                y: 70
            },
            "12": {
                x: 30,
                y: 45
            },
            "13": {
                x: 31,
                y: 40
            },
            "14": {
                x: 15,
                y: 80
            }
        }
    },
    icon: {
        scale: 1.1
    },
    targetWidth: 35
});

PagingScroll.VISIBLE_PAGES = 2;

cleverapps.overrideStyles(cleverapps.styles.PagingScrollView, {
    pageHeight: cleverapps.styles.SCENE_HEIGHT / 2.3,

    parallax: {
        speed: [0.15, 0.30],
        bundle: "main",
        patternSize: {
            width: 512,
            height: 512
        }
    },
    firstOffset: 525
});

cleverapps.overrideStyles(cleverapps.styles.IslandView, {
    locked: {
        alignment: {
            x: 0,
            y: 0
        }
    },

    positions: {
        0: {
            x: 0,
            y: 0
        },

        1: {
            x: 0,
            y: 0
        }
    },

    positionsMobile: {
        0: {
            x: 0,
            y: 0
        },

        1: {
            x: 0,
            y: 0
        }
    },

    animatePass: {
        delay: 0.5
    }
});

cleverapps.overrideStyles(cleverapps.styles.LevelsView, {
    levels_1: [],
    levels_2: []
});

cleverapps.overrideStyles(cleverapps.styles.UI.MovesView, {
    position: [{
        x: { align: "left", dx: 90 },
        y: { align: "top", dy: -100 }
    }, {
        x: { align: "left", dx: 200 },
        y: { align: "top", dy: -126 }
    }, {
        x: { align: "left", dx: 183 },
        y: { align: "center", dy: 560 }
    }],

    text: {
        margin: { x: 10, y: -10 }
    },

    scale: [0.8, 1, 1],

    icon: false,

    slideAnimation: {
        movesView: {
            offsetY: 300
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.FieldBoosterView, {
    background: true,
    hideWhenDisabled: true,
    amount: {
        x: -114,
        y: 24
    },
    actor: {
        position: {
            y: { align: "center", dy: 15 }
        }
    },
    scales: [0.75, 1, 1],
    locked: false
});

cleverapps.overrideStyles(cleverapps.styles.FieldBoostersView, {
    delay: 3
});

cleverapps.overrideStyles(cleverapps.styles.HardLevelIcon, {
    parentSceneScene: true
});

cleverapps.overrideStyles(cleverapps.styles.ColorFulAnimationView, {
    active: false
});

cleverapps.overrideStyles(cleverapps.styles.MultiColorCellView, {
    shakeField: {
        delay: 1700
    },

    centerAnimation: {
        scale: 1,
        duration: 0.25
    }
});

cleverapps.overrideStyles(cleverapps.styles.RpgLifeView, {
    delta: false,
    icon: {
        x: { align: "center", dx: -150 }
    },

    bar: {
        color: false,
        colorLow: false,
        width: 180,
        height: 50,
        scale: 1.5
    },

    x: { align: "center", dx: 20 }
});

cleverapps.overrideStyles(cleverapps.styles.BaseCellView, {
    wrongMoveAnimationName: "default"
});

cleverapps.overrideStyles(cleverapps.styles.BoosterInfoView, {
    sizes: [
        {
            width: "full",
            addx: 90,
            height: 270
        },
        {
            width: "field",
            height: 200
        },
        {
            width: "field",
            height: 140
        }
    ]
});

cleverapps.overrideStyles(cleverapps.styles.LoginButton, {
    Button: {
        height: 160
    }
});

cleverapps.overrideStyles(cleverapps.styles.LocationView, {
    Background: {
        width: 800
    },
    coming_soon: {
        positions: [
            { x: 0, y: 100 },
            { x: 0, y: 100 },
            { x: 0, y: 100 }
        ],
        clouds: {
            offset: {
                x: 100,
                y: 0
            }
        },
        animation: {
            text: false
        },
        activities: {
            text: {
                width: 420,
                horizontalAlignment: cc.TEXT_ALIGNMENT_LEFT,
                verticalAlignment: cc.VERTICAL_TEXT_ALIGNMENT_CENTER,
                cupMsg: "Compete",
                joinMsg: "Join"
            }
        }
    },
    height: cleverapps.styles.SCENE_HEIGHT / 2.3
});

cleverapps.overrideStyles(cleverapps.styles.IceTileView, {
    goal: {
        scaleX: 1
    }
});

cleverapps.overrideStyles(cleverapps.styles.HeroCellView, {
    animation: {
        overshoot: false,
        direction: {
            left: "out_left",
            right: "out_right",
            up: "out_up",
            down: "out_down"
        }
    }
});

cleverapps.styles.CurrentEpisodeLabel = {
    background: {
        json: bundles.main.jsons.episode_label_json,
        width: 330,
        height: 120
    },

    label: {
        alignment: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: -150 }
        },

        islandText: {
            font: cleverapps.styles.FONTS.ISLAND_LABEL_TEXT,
            padding: 60,
            alignment: {
                x: { align: "center", dx: 4 },
                y: { align: "center", dy: -15 }
            }
        }
    }
};

cleverapps.overrideStyles(cleverapps.styles.ProgressView, {
    x: { align: "center" },
    y: { align: "center", dy: -260 },

    bar: {
        width: 270
    },

    arrows: false
});

cleverapps.overrideStyles(cleverapps.styles.StarsProgressBar, {
    bar: {
        dy: 1
    }
});

cleverapps.overrideStyles(cleverapps.styles.MissionMarkView, {
    x: 30,
    y: 30
});

cleverapps.overrideStyles(cleverapps.styles.TulpanCellView, {
    scale: 1.0
});

cleverapps.overrideStyles(cleverapps.styles.LotteryIcon, {
    positions: {
        "bottom": {
            x: { align: "center", dx: 50 },
            y: { align: "bottom", dy: -70 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.ShareCheckBox, {
    padding: {
        y: 5
    }
});

cleverapps.overrideStyles(cleverapps.styles.WindowGoalView, {
    width: 200,
    height: 270,
    icon: {
        y: { align: "center", dy: 30 },
        scale: 0.65
    }
});

cleverapps.overrideStyles(cleverapps.styles.IceBlockDecoratorCellView, {
    stageDecreaseSound: bundles.game.urls.special_element_stage_decrease,
    dieSound: bundles.game.urls.special_element_die
});

cleverapps.overrideStyles(cleverapps.styles.SharkCellView, {
    stageDecreaseSound: bundles.game.urls.special_element_stage_decrease,
    dieSound: bundles.game.urls.special_element_die
});

cleverapps.overrideStyles(cleverapps.styles.DirtyHoneyTileView, {
    growUpSound: bundles.game.urls.special_element_grow_up
});

cleverapps.overrideStyles(cleverapps.styles.DirtyHoneyDecoratorCellView, {
    growUpSound: bundles.game.urls.special_element_grow_up
});

cleverapps.overrideStyles(cleverapps.styles.PassLevelReward, {
    positions: [
        { x: { align: "left", dx: 80 }, y: { align: "top", dy: -10 } },
        { x: { align: "left", dx: -315 }, y: { align: "top", dy: -30 } },
        { x: { align: "right", dx: 285 }, y: { align: "top", dy: 310 } }
    ]
});

cleverapps.styles.PassLevelReward.prize.icon.scale[PassLevelReward.BOOSTER] = 0.42;

cleverapps.overrideStyles(cleverapps.styles.FireworkCellView, {
    light: {
        x: 0,
        y: 0
    }
});

cleverapps.styles.GingerHouseCellView.imageOffsets[GingerHousePartCell.TYPES.WALL] = { x: 0, y: -47 };
cleverapps.styles.GingerHouseCellView.imageOffsets[GingerHousePartCell.TYPES.DOOR] = { x: -42, y: -63 };
cleverapps.styles.GingerHouseCellView.imageOffsets[GingerHousePartCell.TYPES.WINDOW] = { x: 48, y: -56 };
cleverapps.styles.GingerHouseCellView.imageOffsets[GingerHousePartCell.TYPES.ROOF] = { x: 0, y: 47 };

cleverapps.overrideStyles(cleverapps.styles.BoosterAmountView, {
    amount: {
        offsetY: 3
    }
});

cleverapps.overrideStyles(cleverapps.styles.CloversView, {
    width: 200,
    height: 65,

    icon: {
        x: { align: "left", dx: -20 }
    },

    text: {
        y: { align: "center", dy: 2 }
    },

    position: [
        {
            x: { align: "center" },
            y: { align: "top", dy: -15 }
        },
        {
            x: { align: "center" },
            y: { align: "top", dy: -15 }
        },
        {
            x: { align: "center" },
            y: { align: "top", dy: -15 }
        }
    ]
});

cleverapps.overrideStyles(cleverapps.styles.CanUpgradeHeroWindow, {
    heroes: [{
        x: -70,
        y: 136,
        scale: 1.1
    }, {
        x: 70,
        y: 136,
        scale: 1.1
    }, {
        x: -150,
        y: 50,
        scale: 1.25
    }, {
        x: 150,
        y: 50,
        scale: 1.25
    }, {
        x: -76,
        y: -50,
        scale: 1.4
    }, {
        x: 76,
        y: -50,
        scale: 1.4
    }]
});

cleverapps.overrideStyles(cleverapps.styles.PackWindow, {
    animation: {
        "starterPack0": {
            idleAnimation: "2_idle",
            introAnimation: "2_start"
        },

        "starterPack": {
            idleAnimation: "2_idle",
            introAnimation: "2_start"
        },

        "starterPack2": {
            idleAnimation: "2_idle",
            introAnimation: "2_start"
        }
    },

    rewards: {
        positions: {
            "default": {
                "boosters": {
                    5: {
                        x: -240,
                        y: -27
                    },
                    6: {
                        x: 255,
                        y: -27
                    },
                    7: {
                        x: 280,
                        y: -382
                    }
                },
                "unlimitedLives": {
                    x: -265,
                    y: -379
                },
                "hard": {
                    x: 4,
                    y: -256
                }
            },

            "starterPack0": undefined,
            "starterPack": undefined,
            "starterPack2": undefined

        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.GameScene.STYLING, {
    hard: {
        animation: bundles.game.jsons.game_bg_clouds_json,
        skin: "gameBackground_hard"
    }
});

cleverapps.overrideStyles(cleverapps.styles.GameScene.STYLING.regular[0], {
    animation: bundles.game.jsons.game_bg_clouds_json,
    skin: "gameBackground"
});

cleverapps.overrideStyles(cleverapps.styles.GameScene.STYLING.regular[1], {
    animation: bundles.game.jsons.game_bg_clouds_json,
    skin: "gameBackground1"
});

cleverapps.overrideStyles(cleverapps.styles.GameScene.STYLING.regular[2], {
    animation: bundles.game.jsons.game_bg_clouds_json,
    skin: "gameBackground2"
});

cleverapps.overrideStyles(cleverapps.styles.PlayButtonView, {
    position: [
        {
            x: { align: "center" },
            y: { align: "bottom", dy: 150 }
        },
        {
            x: { align: "center" },
            y: { align: "bottom", dy: 150 }
        },
        {
            x: { align: "center" },
            y: { align: "bottom", dy: 173 }
        }
    ]
});

cleverapps.overrideStyles(cleverapps.styles.RestoreProgressButton, {
    position: [
        {
            x: { align: "left", dx: 20 },
            y: { align: "bottom", dy: 20 }
        },
        {
            x: { align: "right", dx: -20 },
            y: { align: "bottom", dy: 20 }
        },
        {
            x: { align: "right", dx: -20 },
            y: { align: "top", dy: -110 }
        }
    ]
});
