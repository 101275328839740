/**
 * Created by slava on 8/8/18
 */

var TableView = cc.Node.extend({
    ctor: function (table, options) {
        this._super();

        options = options || {};
        options.rowOptions = options.rowOptions || {};

        this.table = table;

        table.view = this;

        this.setAnchorPoint(0.5, 0.5);

        var styles = cleverapps.styles.TableView;

        this.options = options;
        this.counter = 0;

        this.animateStages = [
            this.animateUpdateAmounts.bind(this),
            this.animateSwaps.bind(this),
            this.animateRemoveRows.bind(this)
        ];

        this.RowConstructor = options.rowConstructor || RowView;
        this.rowOptions = options.rowOptions;

        this.rowOptions.clickableArea = this;

        this.innerPadding = this.options.innerPadding || styles.innerPadding;

        var container = this.container = new cc.Node();
        container.setAnchorPoint(0.5, 0.5);

        var data = options.data;

        if (table.size !== data.length) {
            table.updateRows(data);
        }

        var rows = Object.values(table.getRows());

        for (var i = 0; i < rows.length; i++) {
            this.addRow(rows[i], { silent: true });
        }

        this.scroll = new cleverapps.UI.ScrollView(container);
        this.scroll.setBarPadding(styles.barPadding);
        this.scroll.setAnchorPoint(0, 0);
        this.addChild(this.scroll);

        if (options.bg !== false) {
            this.bg = new cc.Scale9Sprite(bundles.table.frames.table_bg_png);
            this.bg.setLocalZOrder(-1);
            this.addChild(this.bg);
        }

        this.updateContainerSize();
        this.updateSize();

        table.on("addRow", this.addRow.bind(this), this);
        table.on("updateRows", this.updateRows.bind(this), this);

        this.inc();
        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.7),
            new cc.CallFunc(this.dec.bind(this))
        ));
        table.updateRows(data);
    },

    cleanup: function () {
        this._super();
        this.table.removeView();
    },

    addRow: function (row, options) {
        var rowView = new this.RowConstructor(row, this.rowOptions);

        rowView.setAnchorPoint(0, 0.5);
        this.container.addChild(rowView);

        var silent = options && options.silent;

        if (!silent) {
            row.updateViewPlace(this.table.getNotInTablePlace(), { silent: true });
        }

        rowView.setPosition(this.calculateRowPosition(rowView, rowView.place));
    },

    updateRows: function () {
        this.updateContainerSize();

        this.animateChanges();
    },

    calculateRowPosition: function (rowView, place) {
        var styles = cleverapps.styles.TableView;
        place = this.table.size - (place !== undefined ? place : rowView.place);
        return cc.p(this.innerPadding, this.innerPadding + (styles.margin + rowView.height) * place + rowView.height / 2);
    },

    updateContainerSize: function () {
        var styles = cleverapps.styles.TableView;
        var width = styles.width;
        var height = 0;

        var rows = Object.values(this.table.getRows()).filter(function (row) {
            return row.place <= this.table.size && row.view;
        }.bind(this));

        for (var i = 0; i < rows.length; i++) {
            width = rows[i].view.width;
            height += rows[i].view.height;

            if (i > 0) {
                height += styles.margin;
            }
        }

        this.container.setContentSize2(width + 2 * this.innerPadding, height + 2 * this.innerPadding);
        this.scroll.updateInnerContent();
    },

    dec: function () {
        this.counter--;
        this.animateChanges();
    },

    inc: function () {
        this.counter++;
    },

    animateChanges: function () {
        for (var i = 0; i < this.animateStages.length && this.counter === 0; i++) {
            this.animateStages[i]();
        }
    },

    animateUpdateAmounts: function () {
        Object.values(this.table.rows).forEach(function (row) {
            row.updateAmountView();
        });
    },

    animateSwaps: function () {
        var best = this.table.size + 2, bestRow;

        Object.values(this.table.rows).forEach(function (row) {
            if (row.getViewPlace() > row.place && row.place < best) {
                best = row.place;
                bestRow = row;
            }
        });

        if (bestRow) {
            var upRow = this.findRowWithViewPlace(bestRow.getViewPlace() - 1);
            if (upRow) {
                this.swapRows(upRow, bestRow);
            }
        }
    },

    animateRemoveRows: function () {
        var removed = false;
        Object.values(this.table.rows).forEach(function (row) {
            if (row.getViewPlace() === row.place && row.place > this.table.size) {
                this.table.removeRow(row);
                removed = true;
            }
        }.bind(this));

        if (removed) {
            this.updateContainerSize();
        }
    },

    swapRows: function (row1, row2) {
        var place1 = row1.getViewPlace();
        var place2 = row2.getViewPlace();

        var silent = !row1.isViewVisible() && !row2.isViewVisible();

        this.inc();
        this.inc();

        row1.updateViewPlace(place2);
        row2.updateViewPlace(place1);

        this.moveRowView(row1.getView(), place2, { callback: this.createListener(this.dec.bind(this)), silent: silent });
        this.moveRowView(row2.getView(), place1, { callback: this.createListener(this.dec.bind(this)), silent: silent });

        var scrollTo;
        if (row1.player) {
            scrollTo = row2.getViewPosition();
        } else if (row2.player) {
            scrollTo = row1.getViewPosition();
        }

        if (scrollTo) {
            this.scroll.scrollTo(scrollTo, 0.2, {
                easing: cc.easeInOut(1)
            });
        }
    },

    moveRowView: function (rowView, place, options) {
        options = options || {};

        var callback = options.callback || function () {};

        if (!rowView) {
            callback();
            return;
        }

        var position = this.calculateRowPosition(rowView, place);

        var finalize = function () {
            rowView.setPosition(position);
            callback();
        };

        if (options.silent) {
            finalize();
            return;
        }

        rowView.runAction(new cc.MoveTo(0.2, position).setFinalize(finalize));
    },

    findRowWithViewPlace: function (place) {
        return Object.values(this.table.rows).find(function (row) {
            return row.getViewPlace() === place;
        });
    },

    findPlayerRow: function () {
        return Object.values(this.table.rows).find(function (row) {
            return row.player;
        });
    },

    updateSize: function () {
        var styles = cleverapps.styles.TableView;
        var height = this.options.height && this.options.height[cleverapps.resolution.mode] && this.options.height[cleverapps.resolution.mode].height || this.options.height || styles.height;

        this.setContentSize2(this.container.width + 2 * styles.padding, height + 2 * styles.padding);

        var scrollHeight = Math.min(height, this.container.height + 1);

        this.scroll.setContentSize2(this.container.width, scrollHeight);
        this.scroll.setPosition(styles.padding, styles.padding + height - scrollHeight);

        if (this.bg) {
            this.bg.setContentSize2(this.width, this.height);
            this.bg.setPositionRound(this.width / 2, this.height / 2);
        }

        if (this.options.scroll !== undefined) {
            this.scroll.scrollTo(this.options.scroll);
        } else if (this.container.height > scrollHeight) {
            var row = this.findPlayerRow();
            if (row && row.view) {
                var target = this.scroll.targetToPoint(row.view);
                if (isNaN(this.container.x) || isNaN(target.x) || isNaN(target.y)) {
                    var msg = row.view.x + "_" + row.view.y;
                    msg += " s " + this.scroll.width + "_" + this.scroll.height + " ic " + this.container.width + "_" + this.container.height;
                    msg += " tp " + target.x + "_" + target.y + " m " + this.scroll.min.x + "_" + this.scroll.min.y + " " + this.scroll.max.x + "_" + this.scroll.max.y;
                    msg += " pl " + row.data.place + " cpl " + row.view.place + " tpl " + this.table.size;

                    try {
                        msg += " " + JSON.stringify({
                            innerContentSize: this.scroll.innerContent && cc.p(this.scroll.innerContent.width, this.scroll.innerContent.height),
                            rowViewType: typeof row.view,
                            rowViewPosition: row.view && row.view.getPosition(),
                            rowViewWorldPos: row.view && row.view.getParent() && row.view.getParent().convertToWorldSpace(row.view.getPosition()),
                            innerContentPosition: this.scroll.innerContent && this.scroll.innerContent.getPosition(),
                            targetNodePosition: this.scroll.innerContent && row.view && row.view.getParent() 
                                && this.scroll.innerContent.convertToNodeSpace(row.view.getParent().convertToWorldSpace(row.view.getPosition()))
                        });
                    } catch (e) {
                        console.error(e);
                    }

                    try {
                        msg += " " + JSON.stringify({
                            innerContentWorldPosition: this.scroll.innerContent && this.scroll.innerContent.getParent()
                                && this.scroll.innerContent.getParent() && this.scroll.innerContent.getParent().convertToWorldSpace(this.scroll.innerContent.getPosition())
                        });
                    } catch (e) {
                        console.error(e);
                    }

                    cleverapps.throwAsync(msg);
                } else {
                    this.scroll.scrollTo(row.view);
                }
            }
        }
    }
});

cleverapps.styles.TableView = {
    width: 500,
    height: 732,

    margin: 4,
    padding: 6,
    innerPadding: 6,
    barPadding: {
        cornerPadding: 20,
        sidePadding: 5
    }
};