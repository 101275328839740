/**
 * Created by andrey on 20.07.17.
 */

var Messages = {
    bundles: [],
    debugTextCodes: {},

    isInitialized: function () {
        return this.bundles.length > 0 && bundles[this.bundles[0]].isLoaded();
    },

    get: function (code, toReplace) {
        toReplace = toReplace || {};

        if (!code) {
            return "";
        }

        if (!this.isInitialized()) {
            return code;
        }

        var message = this._findInDictionaries(code);

        var foundInDictionary = true;

        if (!message) {
            message = code;

            foundInDictionary = false;

            if (message.length > 10 && cleverapps.config.debugMode && message.includes(".") && Game.currentGame && !toReplace.ignoreWarning) {
                console.log("Non-existent key: ", message);
                console.trace();
            }
        }

        if (Array.isArray(message)) {
            for (var i = 0; i < message.length; i++) {
                message[i] = Messages.replace(message[i], toReplace);
            }
        } else {
            message = Messages.replace(message, toReplace);
        }

        if (cleverapps.config.debugMode && foundInDictionary && typeof message === "string") {
            Messages.debugTextCodes[message.toLowerCase().replace(/\s/g, ".")] = code;
        }

        return message;
    },

    _findInDictionaries: function (code) {
        for (var i = this.bundles.length - 1; i >= 0; i--) {
            var bundle = this.bundles[i];

            var dictionary = bundles[bundle].jsons.dictionary_json.getJson();

            if (dictionary && dictionary[code] !== undefined) {
                return dictionary[code];
            }
        }
    },

    has: function (code) {
        var value = this._findInDictionaries(code);
        if (!value) {
            return false;
        }

        return value;
    },

    replace: function (message, toReplace) {
        toReplace = toReplace || {};

        if (typeof message === "string") {
            for (var i in toReplace) {
                var value = toReplace[i];
                if (typeof value === "string") {
                    value = Messages.get(value);
                }

                var template = "%{" + i + "}";
                while (message.indexOf(template) !== -1) {
                    message = message.replace(template, value);
                }
            }
        }
        return message;
    },

    getLocalized: function (obj, toReplace) {
        if (typeof obj === "string") {
            return Messages.get(obj, toReplace);
        }

        if (typeof obj === "object" && cleverapps.intersect(Object.keys(obj), cleverapps.config.languages).length > 0) {
            if (obj[cleverapps.settings.language] !== undefined) {
                obj = obj[cleverapps.settings.language];
            } else {
                obj = obj[cc.sys.LANGUAGE_ENGLISH];
            }
            return Messages.getLocalized(obj, toReplace);
        }

        if (typeof obj !== "object") {
            return obj;
        }

        obj = cleverapps.clone(obj);
        for (var i in obj) {
            obj[i] = Messages.getLocalized(obj[i], toReplace);
        }
        return obj;
    },

    loadLanguage: function (language, callback) {
        if (cleverapps.config.debugMode) {
            Messages.debugTextCodes = {};
        }

        var toLoad = this.bundles.map(function (bundle) {
            var index = bundle.lastIndexOf("_");

            return bundle.slice(0, index + 1) + language;
        });
        var toDelete = this.bundles.slice();

        cleverapps.bundleLoader.deleteBundles(toDelete);

        cleverapps.bundleLoader.loadBundles(toLoad, {
            onSuccess: callback
        });
    },

    addBundle: function (bundleName) {
        this.bundles.push(bundleName);
    },

    removeBundle: function (bundleName) {
        var index = this.bundles.lastIndexOf(bundleName);
        if (index !== -1) {
            this.bundles.splice(index, 1);
        }
    }
};

if (cleverapps.config.debugMode) {
    Messages.getLinkToCode = function (code) {
        for (var i = this.bundles.length - 1; i >= 0; i--) {
            var debugLocalizationMapping = bundles[this.bundles[i]].debugLocalizationMapping;

            for (var url in debugLocalizationMapping) {
                if (debugLocalizationMapping[url][code]) {
                    return url;
                }
            }
        }
    };
}