/**
 * Created by mac on 9/16/23
 */

var Tutorial = function () {
    this.active = undefined;
    this.scenarios = [];
    this.onFinishCallback = function () { };
};

Tutorial.prototype.register = function (scenarioCreator) {
    this.scenarios.push(scenarioCreator);
};

Tutorial.prototype.isActive = function () {
    return this.active !== undefined;
};

Tutorial.prototype.getActive = function () {
    return this.active;
};

Tutorial.prototype.getCurrentActiveStep = function () {
    return this.active && this.active.getCurrentActiveStep();
};

Tutorial.prototype.getCurrentStep = function () {
    return this.active && this.active.getCurrentStep();
};

Tutorial.prototype.getInfo = function () {
    var info = this.active ? {
        name: this.active.name,
        stepNo: this.active.stepNo
    } : undefined;
    return info;
};

Tutorial.prototype.run = function (Scenario, f) {
    this.active = Scenario;

    this.onFinishCallback = f;
    Scenario.start();
};

Tutorial.prototype.gameReady = function () {
    this.completeStep();
    if (cleverapps.focusManager.isFocused()) {
        return;
    }

    this.startScenario();
};

Tutorial.prototype.completeStep = function () {
    var step = this.getCurrentStep();
    if (step && step.isFinished()) {
        if (this.active.isLastStep()) {
            this.active.onFinishScenario();
            this.onFinishCallback();
            this.active = undefined;
        } else {
            this.active.startNextStep();
        }
    }
};

Tutorial.prototype.restoreSavedTutorial = function () {
    if (!cleverapps.loadedSnapshot || !cleverapps.loadedSnapshot.tutorial) {
        return;
    }

    var savedData = cleverapps.loadedSnapshot.tutorial;
    delete cleverapps.loadedSnapshot.tutorial;

    var savedScenario = this.scenarios.find(function (scenario) {
        return scenario.name === savedData.name;
    });
    if (!savedScenario) {
        console.log(savedData, this.scenarios);
        throw "no such tutorial " + savedData.name;
    }

    savedScenario.setStep(savedData.stepNo);

    return savedScenario;
};

Tutorial.prototype.startScenario = function (scenario) {
    var availableScenario = scenario || this.restoreSavedTutorial()
        || this.scenarios.find(function (scenario) {
            return scenario.isAvailable();
        });

    if (availableScenario) {
        cleverapps.focusManager.display({
            focus: availableScenario.name,
            control: [],
            stack: true,
            actions: [
                function (f) {
                    this.run(availableScenario, f);
                }.bind(this)
            ]
        });
    }
};
