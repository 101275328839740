/**
 * Created by vladislav on 2/4/19
 */

var DailyCupRow = RowView.extend({
    createPrizeIcon: function (place) {
        return DailyCupRow.choosePrizeIcon(cleverapps.dailyCup, place);
    },

    getRewards: function () {
        var rewards = cleverapps.dailyCup.listRewards();
        return rewards[this.place - 1];
    },

    getPrizeTooltipText: function () {
        if (this.place <= 3) {
            return "Cup" + this.place + "PlaceTooltip";
        }

        return "CupRewardTooltip";
    }
});

DailyCupRow.choosePrizeIcon = function (cup, place) {
    var rewards = cup.listRewards();
    if (rewards[place - 1]) {
        return new cc.Sprite(bundles.table.frames["prize_" + place] || bundles.table.frames.prize_5);
    }
};