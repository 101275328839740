/**
 * Created by andrey on 03.08.17.
 */

var Level = function (episodeNo, levelNo, options) {
    options = options || {};

    if (arguments.length === 0) {
        return;
    }

    this.episodeNo = episodeNo;
    this.levelNo = levelNo;

    var bundle = bundles[this.bundleId()];
    this.meta = bundle.meta.episode ? Object.assign({}, bundle.meta.episode.levels && bundle.meta.episode.levels[levelNo] || {}) : bundle.meta;

    if (!this.meta.expedition) {
        this.meta.expedition = "main";
    }

    if (cleverapps.config.type === "merge") {
        this.families = Families.listExpeditionCodes(this.meta.expedition);
    }

    this.loadTasks = {};
    this.levelType = options.type ? options.type : Level.CalcLevelType(episodeNo);

    this.update();
};

Level.prototype.bundleId = function () {
    return Level.BundleId(this.episodeNo);
};

Level.BundleId = function (episodeNo) {
    var name = (cleverapps.config.type === "merge" ? "location_" : "episode_") + episodeNo;

    if (cleverapps.config.type === "board" && cleverapps.settings.language !== "en") {
        name += "_" + cleverapps.settings.language;
    }

    return name;
};

Level.prototype.getHumanReadableNumber = function () {
    return cleverapps.humanReadableNumber(this.episodeNo, this.levelNo);
};

// will be overridden in DailyCup & BonusRound
Level.prototype.isRegular = function () {
    return this.levelType === Level.LevelTypes.REGULAR;
};

Level.prototype.isDailyCup = function () {
    return this.levelType === Level.LevelTypes.DAILYCUP;
};

Level.prototype.isBonusRound = function () {
    return this.levelType === Level.LevelTypes.BONUSROUNDS;
};

Level.prototype.isBonusWorldLevel = function () {
    return this.levelType === Level.LevelTypes.BONUS_WORLD;
};

Level.prototype.isAdsLevel = function () {
    return this.levelType === Level.LevelTypes.ADS || this.levelType === Level.LevelTypes.PROMO || this.levelType === Level.LevelTypes.SCREENSHOTS;
};

Level.prototype.isWeeklyTournamentLevel = function () {
    return this.levelType === Level.LevelTypes.WEEKLY_TOURNAMENT;
};

Level.CalcLevelType = function (episodeNo) {
    if (typeof BonusWorld !== "undefined" && BonusWorld.getConfigByEpisode(episodeNo)) {
        return Level.LevelTypes.BONUS_WORLD;
    }

    if (Level.LevelTypeValues[episodeNo]) {
        return episodeNo;
    }

    return Level.LevelTypes.REGULAR;
};

Level.LevelTypes = {
    REGULAR: "regular",
    BONUSROUNDS: "bonusrounds",
    EDITOR: "editor",
    DAILYCUP: "dailycup",
    HIGHSCORE_MODE: "score_mode",
    BONUS_WORLD: "bonus_world",
    ADS: "ads",
    PROMO: "promo",
    SCREENSHOTS: "screenshots",
    PLAYABLE_ADS: "playable_ads",
    ADS_HALLOWEEN: "ads_halloween",
    ADS_XMAS: "ads_xmas",
    ADS_DRAGONIA: "ads_dragonia",
    ADS_UNDERSEA: "ads_undersea",
    ADS_UNDERSEA2: "ads_undersea2",
    ADS_RAPUNZEL: "ads_rapunzel",
    ADS_RAPUNZEL2: "ads_rapunzel2",
    WEEKLY_TOURNAMENT: "weekly_tournament"
};

Level.LevelTypeValues = cleverapps.createSet(Object.values(Level.LevelTypes));

Level.prototype.isCurrentLevel = function () {
    return this.isRegular() && levels.user.isCurrentLevel(this.levelNo, this.episodeNo);
};

Level.prototype.isPassedLevel = function () {
    return levels.user.isPassedLevel(this.levelNo, this.episodeNo);
};

Level.prototype.getTag = function () {
    return this.meta.tag;
};

Level.prototype.isHard = function () {
    return this.getTag() === Level.TAGS.HARD;
};

Level.prototype.isTricky = function () {
    return this.getTag() === Level.TAGS.TRICKY;
};

Level.prototype.isDanger = function () {
    return this.isTricky() && cleverapps.config.features.includes("danger");
};

Level.prototype.isNewRules = function () {
    return this.getTag() === Level.TAGS.RULES;
};

Level.prototype.isBonus = function () {
    return this.getTag() === Level.TAGS.BONUS;
};

Level.prototype.getImageBundle = function () {
    return this.meta.bundleName;
};

Level.prototype.getLevelCellSkins = function () {
    return this.meta.cellSkins;
};

Level.prototype.getLevelEnemies = function () {
    var enemies = {};
    if (this.meta.enemies) {
        this.meta.enemies.forEach(function (type) {
            enemies[type] = Game.GOAL_ENEMY_TYPES[type];
        });
    }
    return enemies;
};

Level.prototype.isNew = function () {
    return this.type !== Level.TYPE_PASSED || this.isCurrentLevel();
};

Level.prototype.isLastLevelOnEpisode = function () {
    var bundle = bundles[this.bundleId()];
    var levelsPerEpisode = bundle.meta.episode.levelsAmount;
    return this.levelNo === levelsPerEpisode - 1;
};

Level.prototype.isLoaded = function () {
    return cleverapps.bundleLoader.isLoaded(this.bundleId());
};

Level.prototype.loadBundles = function (task, bundles, callback) {
    if (this.deleted) {
        callback && callback();
        return;
    }

    if (!this.loadTasks[task]) {
        this.loadTasks[task] = new SuccessWait(bundles);
    }

    this.loadTasks[task].whenLoaded(callback);
};

Level.prototype.loadData = function (callback) {
    if (this.isLoaded()) {
        callback && callback();
        return;
    }
    this.loadBundles("levels", [this.bundleId()], callback);
};

Level.prototype.destructor = function () {
    if (this.deleted) {
        return;
    }

    for (var taskName in this.loadTasks) {
        var task = this.loadTasks[taskName];
        task.destructor();
    }

    this.loadTasks = {};

    this.deleted = true;
};

Level.prototype.load = function (callback) {
    if (this.content && !cleverapps.config.editorMode) {
        callback && callback();
    } else {
        this.loadData(function () {
            var levelData;

            if (this.meta.location) {
                levelData = cc.loader.getRes(bundles[this.bundleId()].jsons.map_json);
            } else {
                var levels = cc.loader.getRes(bundles[this.bundleId()].jsons.levels);
                levelData = Array.isArray(levels) ? levels[this.levelNo] : levels.levels[this.levelNo];
            }

            if (Array.isArray(levelData)) {
                var maxVersion = 0;

                levelData.forEach(function (level, index) {
                    var version = level.version || index;
                    version = parseInt(version);

                    if (version > maxVersion || !this.content) {
                        maxVersion = version;

                        this.content = level;
                    }
                }.bind(this));
            } else {
                this.content = levelData;
            }

            this.hash = Level.CalcHash(this.content);

            callback && callback(this);
        }.bind(this));
    }
};

Level.prototype.unload = function () {
    this.destructor();
};

Level.CalcHash = function (levelContent) {
    return RecursiveHasher(levelContent, ["episodeNo", "levelNo", "version", "hard", "paint", "extra", "gold", "hash", "hashes", "comments", "translate", "definitions", "bundles", "horizontalCards"]);
};

Level.prototype.play = function (f, options) {
    if (cleverapps.config.editorMode) {
        cleverapps.config.editorMode = false;
        this.content = undefined;
        cleverapps.environment.setScene(cleverapps.Environment.SCENE_GAME);
    }

    var scene = new GameScene({
        level: this,
        gameOptions: options
    });

    cleverapps.scenes.replaceScene(scene, f);
};

Level.prototype.onPressed = function () {
    if (cleverapps.lives && cleverapps.lives.isEmpty()) {
        cleverapps.focusManager.display({
            focus: "LevelPressedNoLives",
            control: ["MenuBarLivesItem", "MenuBarGoldItem"],
            action: function (f) {
                new LivesShopWindow();
                cleverapps.focusManager.onceNoWindowsListener = f;
            }
        });
    } else if (cleverapps.config.features.includes("boosters_before") && !WindowBoostersBefore.isAvailable() && this.isCurrentLevel() && !this.isHard()
            || !LevelStartWindow.isAvailable(this)) {
        cleverapps.focusManager.display({
            focus: "LevelPressedPlay",
            action: function (f) {
                this.play(f);
            }.bind(this)
        });
    } else {
        cleverapps.focusManager.display({
            focus: "LevelStartWindow",
            control: "MenuBarGoldItem",
            actions: [
                function (f) {
                    new LevelStartWindow(this.getStartWindowOptions());
                    cleverapps.focusManager.onceNoWindowsListener = f;
                }.bind(this)
            ]
        });
    }
};

Level.prototype.getStartWindowOptions = function () {
    var options = {};

    if (this.isDanger()) {
        options.danger = true;
    } else if (cleverapps.config.features.includes("boosters_before") && WindowBoostersBefore.isAvailable()) {
        options.boostersBefore = true;

        if (Lantern.IsActive(this) && cleverapps.config.features.includes("lantern_start")) {
            options.lantern = true;
        }
    }

    options.level = this;

    return options;
};

Level.prototype.update = function () {
    if (cleverapps.meta.getType() !== Meta.HOSE && cleverapps.meta.getType() !== Meta.SHORTMETA) {
        this.type = Level.TYPE_CURRENT;
        return;
    }

    var episodeNo = levels.user.episode;
    var levelNo = levels.user.level;

    if (cleverapps.meta.getType() === Meta.SHORTMETA) {
        episodeNo = Math.floor(cleverapps.meta.getMainObject().level / Level.LEVELS_PER_EPISODE);
        levelNo = cleverapps.meta.getMainObject().level % Level.LEVELS_PER_EPISODE;
    }

    if (episodeNo > this.episodeNo) {
        this.type = Level.TYPE_PASSED;
    } else if (episodeNo < this.episodeNo) {
        this.type = Level.TYPE_NOTPASSED;
    } else if (levelNo > this.levelNo) {
        this.type = Level.TYPE_PASSED;
    } else if (levelNo === this.levelNo) {
        this.type = Level.TYPE_CURRENT;
    } else {
        this.type = Level.TYPE_NOTPASSED;
    }
};

Level.prototype.nextLevel = function () {
    if (this.building) {
        return this;
    }

    var episodeNo = this.episodeNo;
    var levelNo = this.levelNo + 1;

    if (levelNo >= Level.LEVELS_PER_EPISODE) {
        levelNo = 0;
        episodeNo += 1;
    }

    return MethaHelper.getLevel(episodeNo, levelNo);
};

Level.prototype.clone = function () {
    var level = new Level();
    Object.assign(level, this);
    return level;
};

Level.AvailableTags = function () {
    if (cleverapps.config.subtype === "stacks") {
        return [Level.TAGS.HARD, Level.TAGS.TRICKY, Level.TAGS.BONUS];
    }

    if (cleverapps.config.type === "board") {
        return [Level.TAGS.HARD, Level.TAGS.BONUS];
    }

    if (cleverapps.config.type === "match3") {
        return [Level.TAGS.RULES, Level.TAGS.HARD, Level.TAGS.TRICKY, Level.TAGS.BONUS];
    }

    return [Level.TAGS.HARD];
};

Level.CreateByNumber = function (x, options) {
    options = options || {};

    var episodeNo = Math.floor(x / Level.LEVELS_PER_EPISODE);
    var levelNo = x % Level.LEVELS_PER_EPISODE;

    var total = levels.User.CalcEpisodesAmount(cleverapps.config.importMode);
    if (episodeNo >= total) {
        episodeNo = total;
        levelNo = 0;
        var seed = x - cleverapps.humanReadableNumber(episodeNo, levelNo) + 1;

        return levels.user.getRandomLevel(seed, options.type);
    }

    return new Level(episodeNo, levelNo, options);
};

Level.LEVELS_PER_EPISODE = 15;

Level.TYPE_PASSED = 0;
Level.TYPE_CURRENT = 1;
Level.TYPE_NOTPASSED = 2;

Level.TAGS = {};
Level.TAGS.HARD = "hard";
Level.TAGS.TRICKY = "tricky";
Level.TAGS.BONUS = "bonus";
Level.TAGS.RULES = "rules";

if (typeof cc === "undefined") {
    module.exports = Level;
}
