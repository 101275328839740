/**
 * Created by iamso on 05.08.19.
 */

var TreasureSearchWindow = CleverappsWindow.extend({
    onWindowLoaded: function (mission) {
        this.treasureSearch = new TreasureSearch(mission);

        this.treasureSearch.on("finish", this.createListener(this.finish.bind(this)));
        this.treasureSearch.on("completeStage", this.createListener(this.onReward.bind(this)));

        this._super({
            title: "TreasureSearchWindow.Title",
            name: "treasuresearchwindow",
            content: this.generateContent(mission),
            help: this.showHelp.bind(this),
            foreground: cleverapps.config.ui === "tropical" ? bundles.windows.frames.window_foreground_png : undefined
        });

        Mission.logEvent(cleverapps.EVENTS.MISSION_OPEN, mission.getName());

        mission.needShowStartWindow = false;
    },

    onReward: function (stage, rewardsList) {
        this.rewardsList = rewardsList;
    },

    generateContent: function (mission) {
        var styles = cleverapps.styles.TreasureSearchWindow;

        var treasureSearchView = new TreasureSearchView(this.treasureSearch);

        var content = new cc.Node();
        content.setContentSize2(treasureSearchView.getContentSize());
        content.addChild(treasureSearchView);
        treasureSearchView.setPositionRound(styles.content);

        if (mission.isRunning()) {
            var timer = new Timer(mission.getTimeLeft(), this.close.bind(this), {
                width: styles.timer.width,
                timerBg: bundles.treasuresearch.frames.timer_bg
            });
            timer.setPositionRound(styles.timer.positions);
            content.addChild(timer, 10);
            timer.replaceParentSamePlace(treasureSearchView);
        }

        return content;
    },

    finish: function () {
        this.runAction(new cc.Sequence(
            new cc.DelayTime(cleverapps.styles.TreasureSearchWindow.delay),
            new cc.CallFunc(function () {
                this.close();
            }.bind(this))
        ));
    },

    showHelp: function () {
        GuideWindow.createMissionRulesWindow(this.treasureSearch.mission);
    },

    onClose: function () {
        if (this.rewardsList) {
            this.rewardsList.updateViewAmount();
            delete this.rewardsList;
        }
    }
});

TreasureSearchWindow.prototype.listBundles = function () {
    return ["treasuresearch"];
};

cleverapps.styles.TreasureSearchWindow = {
    delay: 11.5,

    content: {
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: 40 }
    },

    timer: {
        width: 250,
        positions: [{
            x: { align: "center", dx: 0 }, y: { align: "top", dy: -80 }
        }, {
            x: { align: "center", dx: 0 }, y: { align: "top", dy: -80 }
        }, {
            x: { align: "center", dx: 0 }, y: { align: "top", dy: -80 }
        }]
    }
};
