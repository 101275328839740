/**
 * Created by slava on 14/8/17
 */

var AvailableMove = function () {
    cleverapps.EventEmitter.call(this);

    this.visible = false;
    this.running = true;
    this.disabled = false;
    this.scaredInterval = undefined;

    this.off();
};

AvailableMove.prototype = Object.create(cleverapps.EventEmitter.prototype);
AvailableMove.prototype.constructor = AvailableMove;

AvailableMove.prototype.turnOff = function () {
    this.setVisible(false);
    if (this.scaredInterval) {
        clearInterval(this.scaredInterval);
    }
    this.running = false;
};

AvailableMove.prototype.turnOn = function () {
    if (this.disabled) {
        return;
    }
    this.running = true;
    this.boo();
};

AvailableMove.prototype.setDisabled = function (disabled) {
    if (this.disabled !== disabled) {
        this.disabled = disabled;
        if (disabled) {
            this.off();
        } else {
            this.on();
        }
    }
};

AvailableMove.prototype.setMove = function (move) {
    this.move = move;
    this.boo();
};

AvailableMove.prototype.setVisible = function (visible) {
    if (this.visible !== visible) {
        this.visible = visible;
        this.trigger("changeVisible", this.visible, this.move);
    }
};

AvailableMove.prototype.boo = function () {
    if (this.running === false) {
        return;
    }

    this.setVisible(false);

    if (this.scaredInterval) {
        clearInterval(this.scaredInterval);
    }

    this.scaredInterval = setTimeout(function () {
        console.log("afterScare");
        this.setVisible(true);
    }.bind(this), AvailableMove.TIMEOUT);
};

AvailableMove.TIMEOUT = 3000;