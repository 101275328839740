/**
 * Created by vladislav on 1/23/19
 */

var SharkCell = function (x, y) {
    BaseCell.call(this, x, y);
    this.movable = false;
    this.lives = SharkCell.MAX_LIVES;
    this.putInPoolAvailable = true;

    var game = Game.currentGame;
    if (!(game instanceof FakeGame) && game.bindings["beforeStartActions"].indexOf(SharkCell.onGameStart) === -1) {
        game.on("beforeStartActions", SharkCell.onGameStart);
        game.counter.registerStage(213, SharkCell.process);
    }
};

SharkCell.prototype = Object.create(BaseCell.prototype);
SharkCell.prototype.constructor = SharkCell;

SharkCell.prototype.load = function (data) {
    if (data[1]) {
        this.lives = parseInt(data[1]);
    }
};

SharkCell.prototype.bundleId = function () {
    return "shark";
};

SharkCell.prototype.save = function () {
    if (this.lives !== SharkCell.MAX_LIVES) {
        return SharkCell.CODES.concat([this.lives]);
    }
    return SharkCell.CODES;
};

SharkCell.prototype.getViewClass = function () {
    return SharkCellView;
};

SharkCell.prototype.hurt = function () {
    if (!this.alive) {
        return;
    }

    this.alive = false;
    this.onDisappear();
    Game.currentGame.counter.setTimeout(function () {
        this.deleteSelf();
    }.bind(this), 1000);
};

SharkCell.prototype.boom = function () {
    this.hurt();
};

SharkCell.isProhibited = function (cell) {
    return [IceCreamMakerCell, CandleCell, DirtMakerCell, DonutBoxCell].indexOf(cell.constructor) !== -1;
};

SharkCell.prototype.deleteSelf = function () {
    SharkCell.appearAmountNext++;
    this.delete();

    if (Game.currentGame && Game.currentGame.field) {
        Game.currentGame.field.cells[this.y][this.x] = undefined;
    }
};

SharkCell.getVariants = function () {
    var variants = [];

    for (var row = 0; row < Field.SIZE; row++) {
        for (var col = 0; col < Field.SIZE; col++) {
            var currCell = Game.currentGame.field.cells[row][col];
            if (Game.currentGame.field.isCellInField(row, col) && currCell.canDelete()) {
                var viable = true;
                for (var i = 0; i < BaseCell.neighbors.length; i++) {
                    var x = BaseCell.neighbors[i].x + currCell.x, y = BaseCell.neighbors[i].y + currCell.y;
                    if (Game.currentGame.field.isCellInField(y, x)) {
                        var neighborCell = Game.currentGame.field.cells[y][x];
                        if (SharkCell.isProhibited(neighborCell) || !neighborCell.movable || !neighborCell.getColor()) {
                            viable = false;
                            break;
                        }
                    } else {
                        viable = false;
                        break;
                    }
                }
                if (viable) {
                    variants.push(currCell);
                }
            }
        }
    }

    return variants;
};

SharkCell.create = function (cellToReplace) {
    cellToReplace.delete();

    var sharkCell = new SharkCell(cellToReplace.x, cellToReplace.y);
    Game.currentGame.field.addCell(sharkCell);

    sharkCell.onCreateNew();

    Game.currentGame.counter.setTimeout(function () {
    }, 700);

    SharkCell.appearAmount--;
};

SharkCell.prototype.hurtDuration = function () {
    return 0.8;
};

SharkCell.prototype.flyDuration = function () {
    return 1.8;
};

SharkCell.prototype.process = function () {
    this.lives--;
    if (this.lives > 0) {
        this.onChangeLivesListener();
        Game.currentGame.counter.setTimeout(function () {
        }, this.hurtDuration() * 1000);
    } else {
        this.onTakeMove(function () {
            if (Game.currentGame && Game.currentGame.moves > 0) {
                Game.currentGame.setMoves(Game.currentGame.moves - 1, {
                    delay: 300
                });
            }
            this.deleteSelf();
        }.bind(this));

        Game.currentGame.counter.setTimeout(function () {
        }, this.flyDuration() * 1000);
    }
};

SharkCell.process = function () {
    if (!Game.currentGame.sharkCellExists || !Game.currentGame.sharkCellCanProcess) {
        return;
    }
    Game.currentGame.sharkCellCanProcess = false;

    for (var row = 0; row < Field.SIZE; row++) {
        for (var col = 0; col < Field.SIZE; col++) {
            var currCell = Game.currentGame.field.cells[row][col];
            if (currCell && currCell.constructor === SharkCell) {
                currCell.process();
            }
        }
    }

    var variants = SharkCell.getVariants();
    while (SharkCell.appearAmount > 0 && variants.length > 0) {
        var index = cleverapps.Random.random(variants.length);
        SharkCell.create(variants[index]);
        variants.splice(index, 1);
    }
};

SharkCell.prepareToProcess = function () {
    if (!Game.currentGame.sharkCellExists) {
        return;
    }

    SharkCell.appearAmount += SharkCell.appearAmountNext;
    SharkCell.appearAmountNext = 0;
};

SharkCell.onGameStart = function () {
    Game.currentGame.sharkCellExists = true;

    SharkCell.appearAmount = SharkCell.appearAmountNext = 0;
};

SharkCell.MAX_LIVES = 3;