/**
 * Created by mac on 5/6/17.
 */

// switch off pool - load 1x1 images instead of some real images
window.ENABLE_IMAEG_POOL = false;

cleverapps.EVENTS = cleverappsEvents;

cleverapps.whenAllInitialized = function (callback) {
    if (cleverapps.allInitialized) {
        callback();
    }

    if (!cleverapps.__whenAllInitializedCallbacks) {
        cleverapps.__whenAllInitializedCallbacks = [];
    }
    cleverapps.__whenAllInitializedCallbacks.push(callback);
};

cleverapps.afterAllLoadingFinish = function () {
    cleverapps.allInitialized = true;

    cleverapps.timeStart = Date.now();

    if (cleverapps.__whenAllInitializedCallbacks) {
        cleverapps.__whenAllInitializedCallbacks.forEach(function (callback) {
            callback();
        });
    }
};

cleverapps.afterResourceLoaded = function () {
    if (typeof document !== "undefined") {
        document.title = Messages.get(cleverapps.config.title);
    }

    if (LogTimeStart.active) {
        LogTimeStart.active.stop();
    }

    if (cleverapps.getRequestParameters().testLoadingTime) {
        cleverapps.notification.create("Loading finished", {
            debugInfo: true
        });
        return;
    }

    cleverapps.LocalizationManager.run();

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.APP_INITIALIZE);
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.DEBUG.RENDER_TYPE + (cc._renderType === cc.game.RENDER_TYPE_CANVAS ? "canvas" : "webgl"));

    if (cleverapps.dataLoader.checksumMatch !== undefined) {
        cleverapps.eventLogger.logEvent(cleverapps.dataLoader.checksumMatch ? cleverapps.EVENTS.DEBUG.CLEARANCE.DATALOADER_CHECKSUM_MATCH : cleverapps.EVENTS.DEBUG.CLEARANCE.DATALOADER_CHECKSUM_DIFFERENT);
    }

    cleverapps.Shaders.initialize();

    cleverapps.flags.update();
    cleverapps.returnBonus = new ReturnBonus();
    cleverapps.promoCodes = new PromoCodes();

    cleverapps.placements = new Placements();

    cc.renderer.assignedZStep = 1 / 100000;

    cleverapps.bannerAd = new BannerAd();

    cleverapps.tutorial = new Tutorial();

    cleverapps.giveUsersGifts();

    cleverapps.focusManager.distract({
        focus: "afterAllLoadingFinish",
        actions: [
            function (f) {
                cleverapps.resolution.showCanvas();

                connector.platform.startGameCallback(f);
            },

            function (f) {
                cleverapps.afterAllLoadingFinish();

                cleverapps.userDelete.checkDeletion(f);
            },

            function (f) {
                if (cleverapps.loadedSnapshot && cleverapps.loadedSnapshot.scene) {
                    cleverapps.reloadSnapshotScene(cleverapps.loadedSnapshot.scene, function () {
                        var ads = cleverapps.loadedSnapshot.ads;
                        if (ads && ads.running) {
                            cleverapps.rewardedAdsManager.showInterstitial(AdsLimits.TYPES.INTERSTITIAL_INACTIVE);
                        }
                        f();
                    });
                    return;
                }

                cleverapps.Plot.onStartup(f);
            }
        ]
    });
};

cleverapps.createGlobalObjects = function () {
    [cleverapps.importSnapshot,
        cleverapps.unitsDemoMode,
        cleverapps.personDemo].forEach(function (debugObj) {
        if (debugObj) {
            debugObj();
        }
    });

    cleverapps.bundleLoader = new BundleLoader();
    cleverapps.timeouts = new Timeouts();
    cleverapps.flags = new cleverapps.Flags();
    cleverapps.gameModes = new GameModes();
    cleverapps.eventBus = new cleverapps.EventEmitter();
    cleverapps.notification = new cleverapps.Notification();
    cleverapps.centerHint = new CenterHint();
    cleverapps.aims = new Aims();
    cleverapps.resolution = new ResolutionManager();

    cleverapps.dataLoader = new DataLoader();
    cleverapps.gameModes.load();
    cleverapps.instantTournament = new InstantTournament();
    cleverapps.scoreTracker = new ScoreTracker();
    cleverapps.gameManager = new GameManager();
    cleverapps.country = new Country();
    cleverapps.permissions = new UserPermissions();

    cleverapps.abTest = new cleverapps.ABTest();
    cleverapps.userDelete = new UserDelete();

    Missions.initialize();

    Offers.initialize();
    VirtualProducts.initialize();
    RewardedAdOverlay.initialize();
    cleverapps.PaymentsHistory.initialize();

    cleverapps.synchronizer = new cleverapps.Synchronizer();
    cleverapps.eventLogger = new EventsLogger();
    cleverapps.conversionManager = new ConversionManager();

    cleverapps.focusManager = new FocusManager();

    cleverapps.playSession = new PlaySession();

    cleverapps.persons = new PersonsLibrary();

    cleverapps.info = new cleverapps.Information();

    cleverapps.expBySlots = {};
    Meta.SLOTS.forEach(function (slot) {
        cleverapps.expBySlots[slot] = new Exp(slot);
    });
    cleverapps.exp = cleverapps.expBySlots[Meta.SLOT_MAIN];

    var isNewUser = cleverapps.dataLoader.haveSavedData() === false;

    if (isNewUser) {
        console.log("Creating new user");
        cleverapps.synchronizer.reset();
    }

    cleverapps.paymentsLogic = new PaymentsLogic();
    cleverapps.settings = new cleverapps.Settings(isNewUser);

    cleverapps.user = levels.user = new levels.User(isNewUser);

    if (!isNewUser) {
        connector.platform.calcPromo(function (promo) {
            if (promo) {
                cleverapps.eventLogger.logEvent(promo);
                if (cleverapps.user.isReturnedPlayer()) {
                    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.RETURNS + promo);
                }
            }
        });
    }

    if (cleverapps.isKnockoutGame()) {
        cleverapps.knockoutGame = new KnockoutGame();
    }

    cleverapps.meta = new Meta();
    cleverapps.meta.load();

    cleverapps.consoleStream = new cleverapps.ConsoleStream();
    cleverapps.lazyAssets = new LazyAssets();

    cleverapps.userStatus = new UserStatus();

    cleverapps.scenes = new cleverapps.Scenes();
    cleverapps.environment = new cleverapps.Environment();

    cleverapps.restoreProgress = new RestoreProgress();

    cleverapps.skins = new cleverapps.SkinManager();
    cleverapps.windows = new Windows();

    cleverapps.persist = new Persist();

    cleverapps.audio = new cleverapps.Audio();

    cleverapps.versionChecker = new VersionChecker();

    cleverapps.info.reportPhone();
    cleverapps.info.reportUserAgent();
    cleverapps.info.reportMaxTextureSize();
    cleverapps.info.reportSysLanguage();

    ConsentTcfApi.init();

    cleverapps.crossPromo = new cleverapps.CrossPromo();

    cleverapps.friends = new FriendsCache();
    cleverapps.invitalbleFriends = new FriendsCache(true);
    cleverapps.requests = new Requests();
    cleverapps.invites = new Invites();
    cleverapps.activities = new Activities();

    cleverapps.paymentsHistory = new cleverapps.PaymentsHistory();

    cleverapps.userIdsHistory = new UserIdsHistory();

    cleverapps.localPushes = new cleverapps.LocalPushes();

    cleverapps.serverFlags = new cleverapps.ServerFlags();

    cleverapps.boosters = new cleverapps.Boosters(isNewUser);

    cleverapps.forces = new Forces();

    levels.friendSorter = new FriendSorter();
    levels.friendRequests = new levels.FriendRequests();

    cleverapps.eventManager = new EventManager();
    cleverapps.missionManager = new MissionManager();

    // should keep for client version compat
    cleverapps.competitionStatistics = new CompetitionStatistics();

    cleverapps.cupsLeadersTable = new Table();

    cleverapps.dailyCup = new Cup(CupsConfig.TYPE_DAILY);
    cleverapps.weeklyCup = new Cup(CupsConfig.TYPE_WEEKLY);
    cleverapps.clanCupInner = new Cup(CupsConfig.TYPE_CLAN_INNER);
    cleverapps.clanCup = new Cup(CupsConfig.TYPE_CLAN, cleverapps.clanCupInner);

    cleverapps.rewardedAdsManager = new RewardedAdsManager();

    cleverapps.travelBook = new TravelBook();

    cleverapps.offerManager = new OfferManager();

    if (WebWorker.IsAvailable()) {
        cleverapps.webWorker = WebWorker.Build();
    }

    cleverapps.exclamation = new Exclamation();
    cleverapps.tooltipManager = new TooltipManager();

    cleverapps.chat = new Chat();

    cleverapps.keyboardController = new KeyboardController();

    cleverapps.appTracking = new AppTracking();

    if (cleverapps.config.debugMode) {
        cleverapps.createDebugGlobalObjects();
    }

    if (cleverapps.InitByType[cleverapps.config.type]) {
        cleverapps.InitByType[cleverapps.config.type](isNewUser);
    }

    if (cleverapps.InitByMeta[cleverapps.meta.getType()]) {
        cleverapps.InitByMeta[cleverapps.meta.getType()](isNewUser);
    }

    if (isNewUser) {
        cleverapps.user.initializeNewPlayer();
    }

    cleverapps.config.features.forEach(function (feature) {
        if (cleverapps.InitByFeature[feature]) {
            cleverapps.InitByFeature[feature](isNewUser);
        }
    });

    if (["merge", "match3", "differences", "solitaire", "board", "blocks", "tile3"].indexOf(cleverapps.config.type) !== -1) {
        cleverapps.dailyTasks = new DailyTasks(isNewUser);
    }
    cleverapps.subscription = new Subscription();
    cleverapps.vipAdsAdapter = new VipAdsAdapter();
    cleverapps.adsLimits = new AdsLimits();

    cleverapps.toolbar = new Toolbar();
    cleverapps.sideBar = new SideBar();
    cleverapps.menuBar = new MenuBar();

    cleverapps.competitionPlayers = new CompetitionPlayers();

    cleverapps.missionManager.init();
    cleverapps.travelBook.init();

    cleverapps.noAds = new NoAds();
    cleverapps.offerManager.initialize();

    return isNewUser;
};

cleverapps.InitByType = {};
cleverapps.InitByMeta = {};
cleverapps.InitByFeature = {};

cleverapps.checkWebpSupport = function (done) {
    if (connector.platform.oneOf(connector.WECHAT) && connector.info.os === cc.sys.OS_ANDROID && connector.info.androidVersion >= 11) {
        cleverapps.config.webpSupport = true;
    }

    if (cleverapps.config.webpSupport !== undefined) {
        done();
        return;
    }

    if (connector.platform.oneOf(connector.WECHAT) && connector.info.os === cc.sys.OS_ANDROID) {
        var canvas = document.createElement("canvas");
        canvas.width = 1;
        canvas.height = 1;

        var context = canvas.getContext && canvas.getContext("2d");

        cleverapps.config.webpSupport = context && canvas.toDataURL("image/webp").startsWith("data:image/webp");

        done();

        return;
    }

    // https://developers.google.com/speed/webp/faq#how_can_i_detect_browser_support_for_webp
    var kTestImages = {
        lossy: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
        lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
        alpha: "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA=="
    };

    var count = 0;
    var supported = 0;

    var callback = cleverapps.waitNoMore(5000, cleverapps.once(function () {
        cleverapps.config.webpSupport = supported === Object.keys(kTestImages).length;
        done();
    }));

    Object.keys(kTestImages).forEach(function (feature) {
        cc.loader.loadImg("data:image/webp;base64," + kTestImages[feature], function (status, img) {
            var result = !status && img && (img.width > 0) && (img.height > 0);

            if (!result) {
                console.log("webp " + feature + " not supported");
            }

            count++;
            if (result) {
                supported++;
            }

            if (!result || count === Object.keys(kTestImages).length) {
                callback();
            }
        });
    });
};

cleverapps.onStart = function () {
    console.log("game started");

    if (typeof document !== "undefined") {
        document.title = cleverapps.config.title;
    }

    new ActionPlayer([
        function (f) {
            window.onConnectorInit = f;
        },

        function (f) {
            if (connector.config.payApiURL.startsWith("https://pay.labsystech.ru") && !["vk", "ok", "mm", "yandex", "fotostrana"].includes(connector.info.source)) {
                cleverapps.throwAsync("Incorrect ru source: " + connector.info.source + " href: " + window.location.href);
            }

            if (cleverapps.config.debugMode && !cleverapps.isLocalhost() && !connector.info.isNative && window.location.protocol !== "https:") {
                throw "https required: " + window.location;
            }

            f();
        },

        function (f) {
            var enPublishUrl = cleverapps.config.enPublishUrl;
            if (!connector.platform.oneOf(connector.OK, connector.VK, connector.YANDEX, connector.MM, connector.FOTOSTRANA)
                || !enPublishUrl) {
                f();
                return;
            }

            var localStorage = connector.dataLoader.getLocalStorage();
            var prefix = cleverapps.config.name + connector.platform.getLocalStoragePrefix();
            var userData = localStorage.getItem(prefix);

            if (userData) {
                f();
                return;
            }

            cleverapps.getFrameData(enPublishUrl, {
                prefix: prefix,
                callback: function (data) {
                    data = data || {};

                    var frameUserId;
                    var userId = connector.platform.getUserID();

                    try {
                        if (data[prefix]) {
                            frameUserId = JSON.parse(data[prefix]).id;
                        }
                    } catch (e) {
                        console.error("parse frameUserId error", e);
                    }

                    console.log("frameUserId", frameUserId, "userId", userId);

                    if (!frameUserId || userId && !PlatformLogic.isTmpId(userId) && userId !== frameUserId) {
                        console.log("ignore frame data - " + frameUserId + " != " + userId);
                        f();
                        return;
                    }

                    for (var key in data) {
                        localStorage.setItem(key, data[key]);
                    }

                    f();
                }
            });
        },

        function (f) {
            cc.sys.isNative = connector.info.isNative;

            cleverapps.platformLogic = PlatformLogic.create();
            cleverapps.platformLogic.onInitialize();

            cleverapps.onPlatformStart();

            f();
        }
    ]).play();
};

cleverapps.onPlatformStart = function () {
    var isNewUser = cleverapps.createGlobalObjects();

    var resumeFrame = -1000;
    var showListener = cc.eventManager.addCustomListener(cc.game.EVENT_SHOW, function () {
        resumeFrame = cc.director.getTotalFrames();
    });

    var done = cleverapps.wait(5, function () {
        var callback = function () {
            cc.eventManager.removeListener(showListener);

            cleverapps.silentIntro = cc.game.isPaused()
                || (cc.director.getTotalFrames() - resumeFrame) <= 60
                || cleverapps.loadedSnapshot;

            cleverapps.afterResourceLoaded(isNewUser);
        };

        var needChangeLanguage = isNewUser && cleverapps.settings.language !== cleverapps.Settings.getDefaultLanguage() && !cleverapps.config.importMode;
        if (needChangeLanguage) {
            cleverapps.settings.setLanguage(cleverapps.Settings.getDefaultLanguage());
        }

        cleverapps.abTest.updateGroups();

        if (needChangeLanguage) {
            Messages.loadLanguage(cleverapps.settings.language, callback);
        } else {
            callback();
        }
    });

    cleverapps.abTest.initialize(cleverapps.waitNoMore(2000, done));

    var logNewUser = cleverapps.wait(4, function () {
        if (isNewUser && cleverapps.user.isFirstSession()) {
            cleverapps.playSession.reportNewUser();
        }
    });

    var firstSyncCallback = cleverapps.once(done);
    var platformCallback = cleverapps.once(function () {
        done();
        logNewUser();
    });
    var socialCallback = cleverapps.once(function () {
        done();
        logNewUser();
    });
    var loadingCallback = cleverapps.once(done);

    if (!isNewUser) {
        logNewUser();
    } else {
        cleverapps.user.calcChannel(cleverapps.waitNoMore(10000, logNewUser));
    }

    if (cleverapps.flags.norest) {
        firstSyncCallback();
        logNewUser();
    } else {
        cleverapps.synchronizer._onceFirstSyncCompletedListener = cleverapps.once(function () {
            firstSyncCallback();
            logNewUser();
        });
    }

    var onSocialConnected = cleverapps.waitNoMore(5000, function () {
        if (!cleverapps.config.importMode && !connector.social.isConnected()) {
            firstSyncCallback();
            socialCallback();
            return;
        }

        if (!cleverapps.config.importMode) {
            var waitLonger = connector.social.isLoggedIn() && cleverapps.user.level === 0 && cleverapps.user.episode === 0;
            setTimeout(firstSyncCallback, waitLonger ? 10000 : 3000);
        }

        cleverapps.versionChecker.check();

        if (!connector.social.isLoggedIn()) {
            if (!connector.platform.withTmpID() && !connector.platform.withDeviceID() && connector.social.oneOf(connector.CRAZY)) {
                connector.dataLoader.cleanAll();
                cleverapps.dataLoader.cleanAll();
                connector.dataLoader.setEnabled(false);
                cleverapps.dataLoader.setEnabled(false);
                window.location.reload();
            } else {
                cleverapps.synchronizer.syncWhenReady();
            }
        }

        socialCallback();
    });

    connector.platform.on("changeStatus", function (status) {
        cleverapps.eventLogger.logEvent((status === connector.STATUS_CONNECTED) ? cleverapps.EVENTS.PLATFORM.CONNECT : cleverapps.EVENTS.PLATFORM.DISCONNECT);
    });

    connector.platform.on("pendingRequestsFreeze", function () {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.DEBUG.PENDING_REQUESTS_FREEZE);
    });

    if (connector.social.isLoggedIn()) {
        var id = connector.platform.getUserID();
        cleverapps.SocialManager.onLogin({ oldId: id, newId: id });
    }

    connector.social.on("login", function (params) {
        cleverapps.SocialManager.onLogin(params);
    });

    if (connector.platform.oneOf(connector.INSTANT, connector.SAMSUNG) || (isNewUser && connector.platform.oneOf(connector.YANDEX))) {
        connector.platform.whenConnected(platformCallback);
    } else {
        platformCallback();
    }

    if (connector.social.isConnected()) {
        onSocialConnected();
    } else {
        connector.social.once("changeStatus", onSocialConnected);
    }

    connector.player.on("changed", function () {
        cleverapps.SocialManager.onPlayerInfoChanged();
    });

    connector.platform.whenConnected(function () {
        if (connector.platform.oneOf(connector.SP_MOBAGE)) {
            cleverapps.resolution.setContainerPadding({
                bottom: 27
            });
        }

        if (connector.platform.oneOf(connector.MM) && !connector.info.isMobile) {
            if (window.outerHeight && window.innerHeight) {
                var maxH = window.outerHeight - 250;
                cleverapps.resolution.setupDesignResolution(cc.size(window.innerWidth, maxH));
            }
        }

        if (cleverapps.platformLogic.onConnected) {
            cleverapps.platformLogic.onConnected();
        }

        if (cleverapps.platformLogic.logPushes) {
            cleverapps.platformLogic.logPushes();
        }
    });

    cleverapps.platformLogic.initGameEvents();
    cleverapps.platformLogic.onStart();

    var connectThrottled = cleverapps.timeredThrottle(10000, function () {
        if (cleverapps.flags.disableReconnect) {
            return;
        }

        connector.platform.connect();
        connector.social.connect();
        connector.payments.connect();
        connector.ads.connect();
    });

    cc.eventManager.addCustomListener(cc.game.EVENT_SHOW, connectThrottled);
    cleverapps.timeouts.setInterval(connectThrottled, cleverapps.parseInterval("30 seconds"));

    cleverapps.permissions.queuePermission(function (f) {
        connector.ads.askPermission(f);
    });

    connector.ads.on("pluginChanged", function () {
        cleverapps.permissions.queuePermission(function (f) {
            connector.ads.askPermission(f);
        });
    });

    cleverapps.permissions.queuePermission(function (f) {
        cleverapps.localPushes.askPermission(f);
    });

    if (connector.platform.oneOf(connector.MSSTART)) {
        cleverapps.abTest.whenGroupsUpdated(function () {
            connector.config.msstart.iap = cleverapps.ABTest.MSSTART_IAP()
                || cleverapps.config.debugMode && ["riddles"].includes(cleverapps.config.name);

            connector._paymentsManager.whenIdle(function () {
                connector.payments._refreshPlugin();
            });
        });
    }

    cleverapps.checkWebpSupport(function () {
        cleverapps.resolution.setupResolutionScale();

        if (cleverapps.snapshotBar) {
            cleverapps.snapshotBar.createView();
        }

        if (connector.info.isNative && !cleverapps.config.webpSupport && resolutions[cleverapps.resolution.resolutionName].webp) {
            alert("Error no webp supported");
            cleverapps.throwAsync("Error no webp supported");
        }

        var start = Date.now();

        var loaderScene = new LoaderScene(ResourceProcessor.calcProbablyNeed(), function () {
            cleverapps.bundleLoader.loadBundles(ResourceProcessor.calcAlwaysNeed(), {
                onSuccess: function () {
                    if (cleverapps.dataLoader.load(DataLoader.TYPES.THROWERROR)) {
                        cleverapps.dataLoader.remove(DataLoader.TYPES.THROWERROR);
                        throw "test error on load";
                    }

                    cleverapps.ConsoleStream.sendLoadingTime("loaderScene " + (Date.now() - start) + "ms");

                    var loadingDone = function () {
                        if (cleverapps.snapshotToLoad) {
                            cleverapps.loadSnapshot(cleverapps.snapshotToLoad, loadingCallback);
                            delete cleverapps.snapshotToLoad;
                        } else {
                            loadingCallback();
                        }
                    };

                    var hashParams = cleverapps.getRequestParameters(location.hash);
                    if (hashParams.clipId && !hashParams.wysiwyg) {
                        cleverapps.wysiwyg = new Wysiwyg(hashParams.directory);
                        cleverapps.wysiwyg.loadClipFromUrlParams(loadingDone);
                    } else {
                        loadingDone();
                    }
                }
            });
        });

        cc.director.runScene(loaderScene);
        cc.director.setNextScene();
    });
};
