/**
 * Created by andrey on 22.08.17.
 */

var SuppliesWindow = CleverappsWindow.extend({
    onWindowLoaded: function (offer) {
        this.logic = offer.logic;

        this.selectedStage = Math.min(this.logic.getStage(), this.logic.reward.length - 1);

        this._super({
            name: "supplieswindow",
            title: "Supplies.windowTitle",
            content: this.generateContent(),
            styles: cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? undefined : cleverapps.styles.SuppliesWindow.window,
            openSound: bundles.supplies_window.urls.weekly_window_effect
        });

        if (this.logic.isReady()) {
            this.onRewardReady();
        }

        cleverapps.paymentsLogic.onChangeStatus(this.updateButton.bind(this), this);
        this.updateButton();
        this.logic.onProductBought = this.createListener(this.onRewardReady.bind(this));
    },

    onShow: function () {
        this._super();

        this.runAction(new cc.Sequence(
            new cc.DelayTime(1),
            new cc.CallFunc(this.logic.onWindowShowUpFinished.bind(this.logic))
        ));
    },

    updateButton: function () {
        if (this.button) {
            if (connector.payments.isConnected()) {
                this.button.enable();
            } else {
                this.button.disable();
            }
        }
    },

    generateContent: function () {
        var styles = cleverapps.styles.SuppliesWindow;

        var content = new cc.Node();
        content.setAnchorPoint(0.5, 0.5);
        content.setContentSize2(styles.size);
        content.setPositionRound(styles);
        content.setScale2(styles.scales);

        if (this.logic.canBuy()) {
            this.product = this.logic.getProduct();
            content.addChild(this.createButton());
        }

        this.paper = new SupplyPaperView(this.selectedStage, this.logic);
        this.paper.setPositionRound(styles.paper.positions);
        content.addChild(this.paper);

        content.addChild(this.createDescription());

        if (this.logic.getTimeLeft()) {
            content.addChild(this.createTextWithTimer());
        }

        content.addChild(this.createDaysGrid());

        if (this.product) {
            content.addChild(this.createOldPriceText());
        }

        return content;
    },

    createDaysGrid: function () {
        var styles = cleverapps.styles.SuppliesWindow.daysLayout;

        var dayViews = this.dayViews = this.logic.reward.map(function (day, index) {
            var dayView = new SupplyDayView({
                day: index,
                isLast: index === (this.logic.reward.length - 1),
                onClick: this.changeSelection.bind(this),
                isSelected: index === this.selectedStage,
                stage: this.logic.getStage()
            });

            return dayView;
        }.bind(this));

        var grid = new cleverapps.GridLayout(dayViews.slice(0, 6), {
            columns: 3,
            margin: styles.margin,
            padding: styles.padding
        });

        var daysLayout = new cleverapps.Layout([grid, dayViews[this.logic.reward.length - 1]], { direction: cleverapps.UI.HORIZONTAL });
        daysLayout.setPositionRound(styles.positions);
        return daysLayout;
    },

    createDescription: function () {
        var styles = cleverapps.styles.SuppliesWindow.text;

        var description;
        if (this.logic.getStage() === 0) {
            description = cleverapps.UI.generateOnlyText("Supplies.bowWindowText", cleverapps.styles.FONTS.SUPPLIES_WINDOW_TEXT);
        } else if (this.logic.getStage() >= this.logic.reward.length) {
            description = cleverapps.UI.generateOnlyText("Supplies.gotAllRewards", cleverapps.styles.FONTS.SUPPLIES_WINDOW_TEXT);
        } else {
            description = cleverapps.UI.generateOnlyText("Supplies.rewardWindowText", cleverapps.styles.FONTS.SUPPLIES_WINDOW_TEXT);
        }

        this.description = description;

        description.setPositionRound(styles.positions);
        description.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        description.setVerticalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        description.fitTo(styles.width, styles.height);
        description.setCascadeOpacityEnabledRecursively(true);

        return description;
    },

    createTextWithTimer: function () {
        var styles = cleverapps.styles.SuppliesWindow.textWithTimer;

        var timer = new Timer(this.logic.getTimeLeft(), this.onRewardReady.bind(this));
        var text = cleverapps.UI.generateOnlyText("Supplies.comeBackWindowText", cleverapps.styles.FONTS.SUPPLIES_WINDOW_TEXT);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.fitTo(styles.width, styles.height);

        if (cleverapps.config.debugMode) {
            cleverapps.UI.onClick(timer, function () {
                this.onRewardReady();
            }.bind(this));
        }

        var textWithTimer = new cleverapps.Layout([text, timer], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });
        textWithTimer.setPositionRound(styles.positions);
        textWithTimer.setCascadeOpacityEnabledRecursively(true);

        return textWithTimer;
    },

    createOldPriceText: function () {
        var styles = cleverapps.styles.SuppliesWindow.strikethrough;

        var node = new cc.Node();

        var oldPriceText;
        var text = cleverapps.UI.LabelTTF.GetLocalizedText("Supplies.oldPrice", {
            price: this.logic.calcOldPrice(this.product)
        });

        var font = cleverapps.styles.FONTS.SUPPLIES_WINDOW_TEXT;

        if (TextWithIcon.IsApplicable(text)) {
            oldPriceText = new TextWithIcon(text, { font: font });
        } else {
            oldPriceText = cleverapps.UI.generateOnlyText(text, font);
        }

        node.addChild(oldPriceText);
        node.setContentSize(oldPriceText.getContentSize());
        oldPriceText.setPositionRound(node.width / 2, node.height / 2);

        var strikethrough = new cc.Scale9Sprite(bundles.digits.frames.underline_png);
        strikethrough.setContentSize2(oldPriceText.width, styles.height);
        strikethrough.setColor(cleverapps.styles.COLORS.DARK_RED);
        node.addChild(strikethrough);
        strikethrough.setPositionRound(node.width / 2, node.height / 2);

        node.setPositionRound(styles.positions);
        node.setCascadeOpacityEnabledRecursively(true);
        node.setOpacity(170);

        this.oldPriceText = node;

        return node;
    },

    createButton: function () {
        var styles = cleverapps.styles.SuppliesWindow.button;

        var button = this.button = new cleverapps.UI.Button({
            width: styles.width,
            height: styles.height,
            text: this.product.getCurrentPrice(),
            onClicked: function () {
                this.logic.buyProduct();
            }.bind(this)
        });

        var badge = new cc.Sprite(bundles.supplies_window.frames.badge);
        var badgeText = cleverapps.UI.generateOnlyText("300%\nFREE", cleverapps.styles.FONTS.SUPPLIES_BADGE);
        badgeText.fitTo(styles.badge.text.width, styles.badge.text.height);
        badgeText.setPositionRound(styles.badge.text);
        badgeText.setRotation(styles.badge.text.rotation);
        badge.addChild(badgeText);
        badge.setPositionRound(styles.badge);
        button.addChild(badge);
        button.setPositionRound(styles.positions);
        button.setCascadeOpacityEnabledRecursively(true);

        return button;
    },

    getPerson: function () {
        return cleverapps.persons.choose("seller", "worker");
    },

    changeSelection: function (day, callback) {
        if (this.receivingRewards) {
            return;
        }

        this.selectedStage = day;

        this.dayViews.forEach(function (dayView) {
            if (dayView.day === day) {
                dayView.select();
            } else {
                dayView.unselect();
            }
        });

        this.paper.updateStage(day, false, callback);
    },

    onRewardReady: function () {
        if (this.isRunning()) {
            var player = new ActionPlayer();

            if (this.selectedStage !== this.logic.getStage()) {
                player.add(function (f) {
                    this.changeSelection(this.logic.getStage(), f);
                }.bind(this));
            }

            player.add(function (f) {
                this.receivingRewards = true;

                var stage = this.logic.getStage();
                var parent = this.dayViews[stage].parent;
                var zOrder = this.dayViews[stage].getLocalZOrder();

                this.dayViews[stage].replaceParentSamePlace(this.window);
                this.dayViews[stage].setLocalZOrder(3);

                this.dayViews[this.logic.getStage()].receiveRewards(function () {
                    this.dayViews[stage].replaceParentSamePlace(parent);
                    this.dayViews[stage].setLocalZOrder(zOrder);

                    f();
                }.bind(this));
            }.bind(this));

            player.add(function (f) {
                [this.description, this.oldPriceText, this.button].filter(Boolean).forEach(function (toDelete) {
                    toDelete.stopAllActions();
                    toDelete.runAction(new cc.Sequence(
                        new cc.FadeOut(0.2),
                        new cc.RemoveSelf()
                    ));
                });
                delete this.description;
                delete this.oldPriceText;
                delete this.button;

                this.paper.receiveRewards(f);
                this.logic.nextStage();

                this.runAction(new cc.Sequence(
                    new cc.DelayTime(0.5),
                    new cc.CallFunc(function () {
                        if (!this.closed) {
                            this.receivingRewards = false;
                            this.close();
                        }
                    }.bind(this))
                ));
            }.bind(this));

            player.play();
        } else {
            cleverapps.focusManager.display({
                focus: "suppliesRewardWindow",
                action: function (f) {
                    new RewardWindow(this.logic.getRewards(), { event: cleverapps.EVENTS.EARN.PURCHASE });
                    cleverapps.focusManager.onceNoWindowsListener = function () {
                        this.logic.nextStage();
                        f();
                    }.bind(this);
                }.bind(this)
            });
        }
    },

    close: function () {
        if (this.receivingRewards) {
            return;
        }

        this._super();
    }
});

SuppliesWindow.prototype.listBundles = function () {
    return ["supplies_window"];
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    SUPPLIES_WINDOW_TEXT: {
        name: "nostroke",
        size: 42,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    SUPPLIES_PAPER_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    SUPPLIES_DAYS_TEXT: {
        size: 34,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    SUPPLIES_BADGE: {
        size: 30,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    }
});

cleverapps.styles.SuppliesWindow = {
    window: {
        padding: {
            left: 24,
            right: 27,
            top: 30,
            bottom: 20
        }
    },

    x: { align: "left" },
    y: { align: "center" },
    scales: [1, 1, 1],

    size: [
        { width: 800, height: 1165 },
        { width: 1300, height: 650 },
        { width: 1300, height: 650 }
    ],

    text: {
        positions: [
            { x: { align: "center" }, y: { align: "center", dy: -70 } },
            { x: { align: "center", dx: 210 }, y: { align: "top", dy: -34 } },
            { x: { align: "center", dx: 210 }, y: { align: "top", dy: -34 } }
        ],

        width: 800,
        height: 60
    },

    textWithTimer: {
        positions: [
            { x: { align: "center" }, y: { align: "bottom", dy: 30 } },
            { x: { align: "center", dx: 200 }, y: { align: "bottom", dy: 74 } },
            { x: { align: "center", dx: 200 }, y: { align: "bottom", dy: 74 } }
        ],

        width: 550,
        height: 60,
        margin: 10
    },

    paper: {
        positions: [
            { x: { align: "center" }, y: { align: "top", dy: -30 } },
            { x: { align: "left", dx: 40 }, y: { align: "center", dy: -10 } },
            { x: { align: "left", dx: 40 }, y: { align: "center", dy: -10 } }
        ]
    },

    daysLayout: {
        positions: [
            { x: { align: "center", dx: -8 }, y: { align: "bottom", dy: 75 } },
            { x: { align: "center", dx: 210 }, y: { align: "center", dy: 16 } },
            { x: { align: "center", dx: 210 }, y: { align: "center", dy: 16 } }
        ],

        padding: {
            x: 20,
            y: 20
        },
        margin: {
            x: 20,
            y: 20
        }
    },

    strikethrough: {
        height: 4,

        positions: [
            { x: { align: "center" }, y: { align: "bottom", dy: 20 } },
            { x: { align: "center", dx: 137 }, y: { align: "bottom", dy: 80 } },
            { x: { align: "center", dx: 137 }, y: { align: "bottom", dy: 80 } }
        ]
    },

    button: {
        width: 300,
        height: 115,

        positions: [
            { x: { align: "center" }, y: { align: "bottom", dy: -120 } },
            { x: { align: "center", dx: 120 }, y: { align: "bottom", dy: -60 } },
            { x: { align: "center", dx: 120 }, y: { align: "bottom", dy: -60 } }
        ],

        badge: {
            x: { align: "right", dx: 73 },
            y: { align: "center", dy: 24 },
            text: {
                x: { align: "center", dx: 15 },
                y: { align: "center", dy: 3 },
                rotation: 32,
                width: 90,
                height: 68
            }
        }
    }
};
