/**
 * Created by slava on 14/8/17
 */

var AvailableMoveView = cc.Node.extend({
    ctor: function (availableMove) {
        this._super();

        availableMove.on("changeVisible", this.setVisible.bind(this), this);
    },

    setVisible: function (visible, move) {
        if (!Game.currentGame || !Game.currentGame.field || !Game.currentGame.field.cells) {
            return;
        }

        this.removeFinger();
        if (this.cells) {
            this.cleanCells();
        }

        if (!visible) {
            return;
        }

        var moveOptions = move.options;

        if (!moveOptions) {
            return;
        }

        moveOptions.onShow && moveOptions.onShow();

        if (move.type === Match3.SMART_HINT) {
            this.showBoosterAdvice(moveOptions);
        } else if (move.type === Match3.DEFAULT_HINT) {
            this.showSwapAdvice(moveOptions);
        }
    },

    showBoosterAdvice: function (moveOptions) {
        if (Game.levelTutorialView && Game.levelTutorialView.tutorial && !Game.levelTutorialView.tutorial.finished) {
            return;
        }

        var clicks = [
            cleverapps.boosters.getBoosterById(moveOptions.boosterId).onGetView(),
            moveOptions.target
        ].filter(Boolean);

        this.finger = FingerView.hintClick(clicks);
    },

    showSwapAdvice: function (moveOptions) {
        cleverapps.audio.playSound(bundles.game.urls.available_move_effect);

        var cellA, cellB;
        var cells = [];
        if (moveOptions.shape) {
            cells = moveOptions.shape.cells;
        } else {
            cellA = moveOptions.start;
            cellB = Game.currentGame.field.cells[cellA.y + moveOptions.dir.row] && Game.currentGame.field.cells[cellA.y + moveOptions.dir.row][cellA.x + moveOptions.dir.col];
            if (!cellA || !cellB) {
                return;
            }
            cells.push(cellA);
            cells.push(cellB);
        }

        cells.forEach(function (cell) {
            var currentCell = cell.innerCell || cell;
            currentCell.onAvailableMoveListener(moveOptions);
        });

        this.cells = cells;
    },

    removeFinger: function () {
        FingerView.remove(this.finger);
        this.finger = undefined;
    },

    cleanCells: function () {
        this.cells.forEach(function (cell) {
            var currentCell = cell.innerCell || cell;
            currentCell.onStartMoveListener({ x: cell.x, y: cell.y }, { moveInterval: 0.1, easing: cc.easeOut(1) });
        });

        this.cells = undefined;
    }
});
