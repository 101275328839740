/**
 * Created by slava on 8/8/18
 */

var RowView = cc.Scale9Sprite.extend({
    ctor: function (row, options) {
        options = options || {};

        this.row = row;
        this.options = options;

        row.view = this;

        var styles = cleverapps.styles.RowView;

        this.clickableArea = options.clickableArea;
        this.dataIconAnimation = options.dataIconAnimation;

        var background = this.row.player ? bundles.table.frames.row_you_png : bundles.table.frames.row_player_png;
        this._super(background);

        this.setContentSize2(styles.width, styles.height);

        this.updatePlace(row.place);

        var place = this.createPlace(row.place);
        var avatar = this.createAvatar();
        var name = this.createName();
        name.setPositionRound(styles.name);

        var prize;

        if (options.prizes !== false) {
            prize = this.createPrize(row.place);
        }

        var data = this.createData();

        var nameWidth = styles.width - 2 * styles.paddingX - [place, avatar, prize, data].filter(Boolean).reduce(function (width, item) {
            return width + item.width * item.scaleX + styles.content.margin;
        }, 0);

        cleverapps.UI.fitToBox(name, {
            width: nameWidth,
            height: styles.name.height
        });

        var nameWrapper = new cc.Node();
        nameWrapper.setContentSize2(nameWidth, styles.height);
        nameWrapper.setAnchorPoint(0.5, 0.5);
        nameWrapper.addChild(name);

        this.content = new cleverapps.Layout([place, avatar, nameWrapper, prize, data].filter(Boolean), {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.content.margin
        });

        this.addChild(this.content);
        this.content.setPositionRound(styles.content);
    },

    createPlace: function (place) {
        var styles = cleverapps.styles.RowView;

        var placeNode = this.placeNode = new cc.Node();
        placeNode.setAnchorPoint(0.5, 0.5);

        var content;
        var badgeIcon = bundles.table.frames["badge_" + place];
        if (this.options.badges !== false && badgeIcon) {
            content = new cc.Sprite(badgeIcon);
        } else {
            content = this.createPlaceText(place);
        }

        placeNode.setContentSize2(this.options.badges !== false ? styles.place.withBadge.width : styles.place.width, styles.content.height);

        placeNode.addChild(content);
        content.setPositionRound(placeNode.width / 2, placeNode.height / 2);

        return placeNode;
    },

    createPlaceText: function (place) {
        var font = this.player ? cleverapps.styles.FONTS.ROW_PLAYER_TEXT : cleverapps.styles.FONTS.ROW_TEXT;
        var text = place;
        if (this.notInTop) {
            text = "-";
        }
        return cleverapps.UI.generateImageText(text, font);
    },

    createAvatar: function () {
        var styles = cleverapps.styles.RowView;

        var avatar = new cleverapps.UI.Avatar(this.row.avatar);
        cleverapps.UI.fitToBox(avatar, {
            height: styles.content.height
        });
        return avatar;
    },

    createName: function () {
        var userName = cleverapps.UI.cutPlayerName(this.row.text
            || typeof this.row.id === "string" && "Player_" + this.row.id.substring(this.row.id.length - 3) || "", 12);

        var font = this.row.player ? cleverapps.styles.FONTS.ROW_PLAYER_TEXT : cleverapps.styles.FONTS.ROW_TEXT;

        return cleverapps.UI.generateOnlyText(userName, font);
    },

    createPrize: function (place) {
        var styles = cleverapps.styles.RowView;

        var prizeNode = this.prizeNode = new cc.Node();
        prizeNode.setAnchorPoint(0.5, 0.5);
        prizeNode.setContentSize2(styles.prize.width, styles.content.height);

        var prize = this.createPrizeIcon(place);
        if (prize) {
            cleverapps.UI.fitToBox(prize, {
                height: styles.content.height
            });
            prizeNode.addChild(prize);
            prize.setPositionRound(prizeNode.width / 2, prizeNode.height / 2);

            this.addPrizeBannerTooltip(prizeNode);
        }

        return prizeNode;
    },

    createPrizeIcon: function (place) {
        var iconsAmount = cleverapps.config.type === "merge" ? 3 : 5;
        if (place <= iconsAmount) {
            return new cc.Sprite(bundles.table.frames["prize_" + place]);
        }
    },

    addPrizeBannerTooltip: function (target) {
        var text = this.getPrizeTooltipText();

        cleverapps.tooltipManager.create(target, {
            rewards: this.getRewards(),
            text: text,
            clickableArea: this.clickableArea
        });
    },

    getPrizeTooltipText: function () {
        return Messages.get("PrizeTooltip");
    },

    getRewards: function () {
        return undefined;
    },

    createData: function () {
        var styles = cleverapps.styles.RowView;

        var image = this.player ? bundles.table.frames.amount_bg_you_png : bundles.table.frames.amount_bg_png;

        var content = this.createDataContent();

        var dataBg = new cc.Scale9Sprite(image);
        dataBg.setContentSize2(content.width + 2 * styles.data.paddingX, styles.data.bg.height);

        if (this.getTooltipText()) {
            cleverapps.tooltipManager.create(dataBg, {
                text: this.getTooltipText(),
                location: cleverapps.UI.Tooltip.LOCATION_BELOW,
                clickableArea: this.clickableArea
            });
        }

        dataBg.addChild(content);
        content.setPositionRound(styles.data.content);
        dataBg.y = styles.data.offsetY;

        this.updateAmount(this.row.amount, true);

        return dataBg;
    },

    createDataContent: function () {
        var styles = cleverapps.styles.RowView.data;

        var items = [];
        var icon = this.options.dataIcon;
        if (icon) {
            this.dataIcon = new cc.Sprite(icon);
            if (styles.icon.rotation) {
                this.dataIcon.setRotation(styles.icon.rotation);
            }
            items.push(this.dataIcon);
        }

        this.dataTextWidth = icon ? styles.text.width : styles.text.withoutIcon.width;
        this.dataTextHeight = styles.text.height;

        this.dataAmount = cleverapps.UI.generateImageText("222", cleverapps.styles.FONTS.ROW_DATA_TEXT);
        this.dataAmount.fitTo(this.dataTextWidth, this.dataTextHeight);

        items.push(this.dataAmount);

        return new cleverapps.Layout(items, {
            margin: styles.margin,
            direction: cleverapps.UI.HORIZONTAL
        });
    },

    getTooltipText: function () {
        return Messages.get("ResultTooltip");
    },

    updatePlace: function (place) {
        if (this.place === place) {
            return;
        }
        this.place = place;

        this.refreshPlace(this.place);
        this.refreshPrize(this.place);
        this.setLocalZOrder(this.place);
    },

    updateAmount: function (amount, silent) {
        if (!this.dataAmount || this.amount === amount) {
            return;
        }

        this.amount = amount;
        this.dataAmount.stopAllActions();
        this.dataAmount.setString(this.amount);
        this.dataAmount.fitTo(this.dataTextWidth, this.dataTextHeight);

        if (silent) {
            return;
        }

        this.dataAmount.runAction(new cc.Sequence(
            new cc.ScaleTo(0.2, 1.5),
            new cc.ScaleTo(0.2, 1)
        ));

        // if (this.dataIcon && this.dataIconAnimation && this.player && cleverapps.config.debugMode) {
        //     var animation = new cleverapps.Spine(this.dataIconAnimation);
        //     // animation.debugBorder();
        //     animation.setAnimation(0, "animation");
        //     animation.setCompleteListenerRemove();
        //     animation.setPosition(this.convertToNodeSpace(this.dataIcon.parent.convertToWorldSpace(this.dataIcon.getPosition())));
        //     this.addChild(animation);
        // }
    },

    refreshPrize: function (place) {
        if (!this.prizeNode) {
            return;
        }

        var position = this.prizeNode.getPosition();
        this.prizeNode.removeFromParent(true);

        this.prizeNode = this.createPrize(place);
        this.prizeNode.setPositionRound(position);
        this.content.addChild(this.prizeNode);
    },

    refreshPlace: function (place) {
        if (!this.placeNode) {
            return;
        }

        var position = this.placeNode.getPosition();

        this.placeNode.removeFromParent(true);

        this.placeNode = this.createPlace(place);
        this.content.addChild(this.placeNode);
        this.placeNode.setPositionRound(position);
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    ROW_DATA_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },
    ROW_PLAYER_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },
    ROW_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});

cleverapps.styles.RowView = {
    width: 700,
    height: 82,

    paddingX: 15,

    content: {
        height: 62,
        margin: 10,
        x: { align: "center" },
        y: { align: "center" }
    },

    place: {
        withBadge: {
            width: 100
        },

        width: 50
    },

    name: {
        height: 50,

        x: { align: "center" },
        y: { align: "center", dy: -3 }
    },

    prize: {
        width: 80
    },

    data: {
        paddingX: 10,

        offsetY: 0,

        bg: {
            height: 60
        },

        margin: 7,

        content: {
            x: { align: "center" },
            y: { align: "center", dy: 2 }
        },

        icon: {
            rotation: -10
        },

        text: {
            withoutIcon: {
                width: 75
            },

            width: 65,
            height: 50
        }
    }
};