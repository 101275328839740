/**
 * Created by Andrey Popov on 3/15/21.
 */

var MinimalDialogue = cc.Node.extend({
    avoidNode: "MinimalDialogue",

    ctor: function (options) {
        this._super();
        this.options = options || {};
        this.targetRect = this.options.targetRect || this.options.rects && this.options.rects[0];
        this.setAnchorPoint(0.5, 0.5);
        this.setLocalZOrder(20);
        this._setGlobalZOrder(true);

        if (this.options.text) {
            this.items = [this.options.text];
        }

        if (this.options.items) {
            this.items = this.options.items;
        }

        if (options.control) {
            cleverapps.focusManager.registerControl(options.control, this.createListener(function (visible) {
                if (visible) {
                    this.showUp();
                } else {
                    this.hide(undefined, true);
                }
            }));
        }
    },

    display: function () {
        this.updateSize();

        if (!this.options.hidden) {
            if (MinimalDialogue.lastShown && MinimalDialogue.lastShown + 30000 > Date.now()) {
                this.options.shortAnimations = true;
            }
            MinimalDialogue.lastShown = Date.now();

            this.showUp();
        }

        if (cleverapps.flags.debugMinimal && this.isDynamicPosition()) {
            MinimalDialogueDebug.create(this);
        }
    },

    updateSize: function () {
        this.stopAllActions();
        this.createContent({ person: true });

        if (!this.calculatePosition()) {
            var maxDimensions = this.calcMaxDimensions();

            this.createContent({ person: true, maxDimensions: maxDimensions });

            if (!this.calculatePosition()) {
                this.createContent({ person: false, maxDimensions: maxDimensions });

                if (!this.calculatePosition()) {
                    this.defaultSlot(maxDimensions);
                }
            }
        }

        this.basePosition = this.getPosition();
    },

    completeAnimationOnResize: function () {
        if (this.hideCallback) {
            var callback = this.hideCallback;
            this.hideCallback = undefined;

            callback();
        }
    },

    updateOrientation: function () {
        var scene = cleverapps.scenes.getRunningScene();
        var styles = cleverapps.styles.MinimalDialogue;
        if (this.x > scene.width / 2 + 3) {
            if (this.personNode) {
                this.textBgArrow.setPositionRound(styles.text.arrow.right);
                this.textBgArrow.setScaleX(-1);
                this.personNode.setScaleX(-Math.abs(this.personNode.getScaleX()));
                this.personNode.setPositionRound({ align: "right" }, { align: "center" });
            }
            this.bg.setAnchorPoint(1, 0.5);
            this.bg.setPositionRound({ align: "left" }, { align: "center" });
        } else {
            if (this.personNode) {
                this.textBgArrow.setPositionRound(styles.text.arrow.left);
                this.textBgArrow.setScaleX(1);
                this.personNode.setScaleX(Math.abs(this.personNode.getScaleX()));
                this.personNode.setPositionRound({ align: "left" }, { align: "center" });
            }
            this.bg.setAnchorPoint(0, 0.5);
            this.bg.setPositionRound({ align: "right" }, { align: "center" });
        }
    },

    removeContent: function () {
        if (this.options.items) {
            this.options.items.forEach(function (item) {
                if (item.parent) {
                    item.removeFromParent(true);
                    item.setPosition(0, 0);
                }
            });
        }

        if (this.bg) {
            this.bg.removeAllChildren();
            this.bg.removeFromParent(true);
        }

        if (this.personNode) {
            this.personNode.removeFromParent();
            this.personNode = undefined;
        }

        delete this.personBackground;
        delete this.personAnimation;
    },

    createContent: function (options) {
        this.removeContent();

        var styles = cleverapps.styles.MinimalDialogue;

        var personSize = cc.size(0, 0);
        var personNode;

        if (options.person) {
            personNode = this.personNode = this.createPerson();
            var margin = styles.person.margin;
            personSize = cc.size(personNode.width * personNode.scale + margin, personNode.height * personNode.scale);
        }

        var scene = cleverapps.scenes.getRunningScene();
        var availableRect = cc.rectSubPadding(scene.getBoundingBox(), cc.padding(styles.padding));
        availableRect.width = Math.min(availableRect.width, styles.maxWidth);

        var availableBgRect = cc.rect(
            availableRect.x,
            availableRect.y,
            availableRect.width - personSize.width,
            Math.min(availableRect.height, styles.text.maxHeight)
        );

        if (options.maxDimensions) {
            availableBgRect.width = Math.min(availableRect.width, options.maxDimensions.width);
            availableBgRect.height = Math.min(availableRect.height, options.maxDimensions.height);
        }

        var textPadding = cc.padding(cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL
            ? styles.text.verticalPadding : styles.text.padding);
        var availableTextRect = cc.rectSubPadding(availableBgRect, textPadding);

        var items = this.items.map(function (item) {
            if (typeof item === "string") {
                var font = cleverapps.resolution.resizeFont(cleverapps.styles.FONTS.FORCE_MESSAGE_TEXT);
                var text = cleverapps.UI.generateOnlyText(item, font);
                text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
                if (text.width > availableTextRect.width) {
                    text.setDimensions(availableTextRect.width, 0);
                }
                return text;
            }

            cleverapps.UI.fitToBox(item, {
                width: availableTextRect.width,
                maxScale: item.scale
            });

            return item;
        });

        var height = items.reduce(function (height, item) {
            return height + item.height * item.scaleY;
        }, 0);

        if (height > availableTextRect.height) {
            items.forEach(function (item) {
                var itemHeight = item.height * availableTextRect.height / height;
                if (item.fitTo) {
                    item.fitTo(availableTextRect.width, itemHeight);
                } else {
                    cleverapps.UI.fitToBox(item, {
                        width: availableTextRect.width,
                        height: itemHeight
                    });
                }
            });
        }

        this.contentItems = items;
        var direction = this.options.direction !== "undefined" ? this.options.direction : cleverapps.UI.VERTICAL;
        this.content = new cleverapps.Layout(items, {
            margin: styles.margin,
            direction: direction
        });

        var contentHeight = Math.max(this.content.height, styles.text.minHeight);
        var bgRect = cc.rectAddPadding(cc.rect(0, 0, this.content.width, contentHeight), textPadding);
        this.bg = cleverapps.UI.createScale9Sprite(bundles.dialogues_minimal.frames.bg_minimal_png, cleverapps.UI.Scale9Rect.TwoPixelXY);
        this.bg.setContentSize2(bgRect);

        this.bg.addChild(this.content);

        if (styles.text.arrow && personNode) {
            this.textBgArrow = new cc.Sprite(bundles.dialogues_minimal.frames.mini_arrow_bg_png);
            this.bg.addChild(this.textBgArrow);
        }

        this.content.setPositionRound(
            textPadding.left + this.content.width / 2,
            textPadding.bottom + contentHeight / 2
        );

        this.bg.setCascadeOpacityEnabledRecursively(true);
        this.bg.setOpacity(0);
        this.content.setCascadeOpacityEnabledRecursively(true);

        this.setContentSize2(personSize.width + bgRect.width, Math.max(personSize.height, bgRect.height));

        this.addChild(this.bg);

        if (personNode) {
            this.addChild(personNode);
        }

        this.updateOrientation();
        this.setVisible(false);

        this.shown = false;
    },

    createPerson: function () {
        var personNode = new cc.Node();
        personNode.setAnchorPoint(0.5, 0.5);

        var personBackground = this.personBackground = new cleverapps.Spine(bundles.dialogues_minimal.jsons.bg_person);
        personBackground.setPositionRound(personNode.width / 2, personNode.height / 2);
        personNode.addChild(personBackground);

        var role = "hero";
        if (this.options.person && cleverapps.persons.getMinimalJson(this.options.person)) {
            role = cleverapps.skins.getSlot(this.options.person) || this.options.person;
        }
        var json = cleverapps.persons.getMinimalJson(role);

        this.personAnimation = new cleverapps.Spine(json);
        this.personAnimation.setPositionRound(cleverapps.styles.MinimalDialogue.person);
        personBackground.addChild(this.personAnimation);
        cleverapps.UI.wrap(personNode);

        var skinSlot = role + "_minimal";
        var skin = cleverapps.skins.getSlot(skinSlot);
        if (skin) {
            this.personAnimation.setSkin(skin);
        }

        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            personNode.setScale(0.8);
        }

        return personNode;
    },

    calculatePosition: function () {
        var sceneSize = cleverapps.resolution.getSceneSize();
        var styles = cleverapps.styles.MinimalDialogue;

        if (this.options.forcePosition) {
            if (this.options.forcePosition === Dialogue.POSITIONS.TOP) {
                this.setPositionRound(sceneSize.width / 2, sceneSize.height - this.height / 2 - styles.positions.top.y);
            } else if (this.options.forcePosition === Dialogue.POSITIONS.TOP_LOWER) {
                this.setPositionRound(sceneSize.width / 2, sceneSize.height - this.height / 2 - styles.positions.topLower.y);
            } else if (this.options.forcePosition === Dialogue.POSITIONS.BOTTOM) {
                this.setPositionRound(sceneSize.width / 2, this.height / 2 + styles.positions.bottom.y);
            } else if (this.options.forcePosition === Dialogue.POSITIONS.CENTER) {
                this.setPositionRound(sceneSize.width / 2, sceneSize.height / 2);
            } else {
                this.setPositionRound(this.options.forcePosition);
            }
        } else if (this.isDynamicPosition()) {
            return this.calcDynamicPosition();
        } else {
            this.setPositionRound(sceneSize.width / 2, this.height / 2 + styles.positions.bottom.y);
        }
        return true;
    },

    isDynamicPosition: function () {
        return this.options.rects && this.options.rects.length;
    },

    listRects: function () {
        var targetOffset = cleverapps.styles.MinimalDialogue.targetOffset;
        return this.options.rects.map(function (rect) {
            if (rect !== this.targetRect) {
                return rect;
            }
            return cc.rectAddPadding(rect, targetOffset);
        });
    },

    listAllPositions: function (rects) {
        var sceneSize = cleverapps.resolution.getSceneSize();
        var sceneBox = cc.rect(0, 0, sceneSize.width, sceneSize.height);
        var available = cc.rectSubPadding(sceneBox, cc.padding(this.height / 2, this.width / 2));

        var styles = cleverapps.styles.MinimalDialogue;
        var padding = cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? styles.rectsPaddingMobile : styles.rectsPadding;

        var positions = [];
        for (var i = 0; i < rects.length; i++) {
            var rect = rects[i];
            var slots = this.calcPositionSlots(rect, padding);

            for (var j = 0; j < slots.length; j++) {
                positions.push(cc.nearestToRect(available, slots[j]));
            }
        }

        return positions;
    },

    listDialogueRects: function (options) {
        options = options || {};

        var dialogueRects = [
            this.bg.getSceneBoundingBox()
        ];

        if (this.personNode && !options.withoutPerson) {
            dialogueRects.unshift(this.personNode.getSceneBoundingBox());
        }

        return dialogueRects;
    },

    calcIntersection: function (dialogueRects, rects) {
        var maxIntersectionArea = 0;
        var maxIntersectionRect;

        dialogueRects.forEach(function (dialogueRect) {
            rects.forEach(function (rect) {
                var intersection = cc.rectIntersection(dialogueRect, rect);

                var area = intersection.width > 2 && intersection.height > 2 ? intersection.width * intersection.height : 0;
                if (area > maxIntersectionArea) {
                    maxIntersectionArea = area;
                    maxIntersectionRect = intersection;
                }
            }, this);
        }, this);

        if (maxIntersectionArea > 0) {
            return {
                area: maxIntersectionArea,
                rect: maxIntersectionRect
            };
        }
    },

    calcMaxDimensions: function () {
        var rects = this.listRects();
        var dialogueRect = this.listDialogueRects({ withoutPerson: true }).reduce(function (dialogueRect, rect) {
            return cc.rectUnion(dialogueRect, rect);
        });

        var cumulative = cc.size();
        var center = cc.rectGetCenter(dialogueRect);

        rects.forEach(function (rect) {
            var intersection = cc.rectIntersection(dialogueRect, rect);
            var intCenter = cc.rectGetCenter(intersection);

            var height = intersection.y - dialogueRect.y + intersection.height;
            if (intCenter.y > center.y) {
                height = dialogueRect.height - intersection.y - dialogueRect.y;
            }

            var width = intersection.x - dialogueRect.x + intersection.width;
            if (intCenter.x > center.x) {
                width = dialogueRect.width - intersection.x - dialogueRect.x;
            }

            cumulative.width = width > cumulative.width ? width : cumulative.width;
            cumulative.height = height > cumulative.height ? height : cumulative.height;
        });

        if (cumulative.width / dialogueRect.width < cumulative.height / dialogueRect.height) {
            cumulative.height = 0;
        } else {
            cumulative.width = 0;
        }

        return cc.size(
            dialogueRect.width - cumulative.width,
            dialogueRect.height - cumulative.height
        );
    },

    defaultSlot: function (maxDimensions) {
        var styles = cleverapps.styles.MinimalDialogue;
        this.createContent({ person: true, maxDimensions: maxDimensions });
        this.setPositionRound(
            this.width / 2 + styles.default.leftOffset,
            cleverapps.resolution.getSceneSize().height * 2 / 3
        );
        this.updateOrientation();
    },

    pickVariant: function (variants) {
        var gravityPoint = cc.rectGetCenter(this.targetRect);
        var scene = cleverapps.scenes.getRunningScene();
        if (this.options.gravitate === DialogueView.GRAVITATE.BOTTOM) {
            gravityPoint = cc.p(scene.width / 2, 0);
        } else if (this.options.gravitate === DialogueView.GRAVITATE.TOP) {
            gravityPoint = cc.p(scene.width / 2, scene.height);
        }

        var invalidVariants = variants.filter(function (variant) {
            return variant.intersection;
        });

        var validVariants = variants.filter(function (variant) {
            return !variant.intersection;
        });
        if (validVariants.length > 0) {
            return validVariants.sort(function (a, b) {
                return cc.pDistance(a.position, gravityPoint) - cc.pDistance(b.position, gravityPoint);
            })[0];
        }

        return invalidVariants[0];
    },

    calcDynamicPosition: function () {
        var rects = this.listRects();

        var variants = [];

        this.listAllPositions(rects).forEach(function (position) {
            this.setPositionRound(position);
            this.updateOrientation();

            var dialogueRects = this.listDialogueRects();

            variants.push({
                position: position,
                dialogueRects: dialogueRects,
                intersection: this.calcIntersection(dialogueRects, rects)
            });
        }, this);

        var pickedVariant = this.pickVariant(variants);

        this.pickedVariant = pickedVariant;
        this.variants = variants;

        this.updateOrientation();

        this.setPositionRound(pickedVariant.position);
        return !pickedVariant.intersection;
    },

    calcPositionSlots: function (rect, padding) {
        var left = { x: rect.x - this.width / 2 - padding, y: rect.y + rect.height / 2 },
            right = { x: rect.x + rect.width + this.width / 2 + padding, y: rect.y + rect.height / 2 },
            up = { x: rect.x + rect.width / 2, y: rect.y + rect.height + this.height / 2 + padding },
            down = { x: rect.x + rect.width / 2, y: rect.y - this.height / 2 - padding };

        return [up, down, left, right];
    },

    showUp: function () {
        if (this.shown) {
            return;
        }
        this.shown = true;
        this.stopAllActions();
        this.bg.stopAllActions();
        this.content.stopAllActions();
        this.bg.setOpacity(0);
        this.bg.setVisible(true);

        this.runAction(new cc.Sequence(
            new cc.DelayTime(this.options.delay !== undefined ? this.options.delay : 0.5),
            new cc.Show(),
            new cc.CallFunc(function () {
                var showUpAnimation = "minimal_showup";

                if (this.options.shortAnimations) {
                    showUpAnimation = "minimal_short_showup";
                    this.fadeInShowUp();
                } else {
                    this.bubbleShowUp();
                }

                if (this.personAnimation) {
                    this.personAnimation.setVisible(true);
                    this.personAnimation.setAnimationAndIdleAfter(showUpAnimation, "talk_minimal");

                    this.personBackground.setVisible(true);
                    this.personBackground.setAnimationAndIdleAfter(showUpAnimation, "minimal_idle");
                }

                cleverapps.audio.playSound(bundles.dialogues.urls.dialogue_effect);
            }.bind(this))
        ));
    },

    fadeInShowUp: function () {
        var styles = cleverapps.styles.MinimalDialogue;

        this.setPosition(this.basePosition.x, this.basePosition.y - styles.moveY);
        this.runAction(new cc.MoveTo(0.3, this.basePosition.x, this.basePosition.y).easing(cc.easeOut(2)));

        this.bg.runAction(new cc.FadeIn(0.3));
    },

    fadeOutHide: function () {
        var styles = cleverapps.styles.MinimalDialogue;

        this.runAction(new cc.MoveBy(0.3, 0, -styles.moveY).easing(cc.easeIn(2)));

        this.bg.runAction(new cc.Sequence(
            new cc.FadeOut(0.3),
            new cc.Hide()
        ));
    },

    bubbleShowUp: function () {
        this.bg.setScale(0.46);

        this.bg.runAction(new cc.Sequence(
            new cc.DelayTime(0.15),
            new cc.ScaleTo(0.3, 1.16, 1.26).easing(cc.easeIn(1)),
            new cc.ScaleTo(0.2, 1).easing(cc.easeOut(1))
        ));

        this.bg.runAction(new cc.Sequence(
            new cc.DelayTime(0.15),
            new cc.FadeIn(0.17)
        ));
    },

    bubbleHide: function () {
        this.bg.runAction(new cc.Sequence(
            new cc.DelayTime(0.13),
            new cc.ScaleTo(0.17, 1.16, 1.26).easing(cc.easeIn(1)),
            new cc.ScaleTo(0.17, 0).easing(cc.easeOut(1)),
            new cc.Hide()
        ));
    },

    hide: function (callback, silent) {
        if (!this.shown) {
            callback && callback();
            return;
        }

        this.stopAllActions();
        this.bg.stopAllActions();
        this.content.stopAllActions();

        if (silent) {
            this.bg.setOpacity(0);
            this.bg.setVisible(false);

            this.personBackground && this.personBackground.setVisible(false);
            this.personAnimation && this.personAnimation.setVisible(false);

            this.shown = false;

            callback && callback();

            return;
        }

        if (this.options.shortAnimations) {
            this.fadeOutHide();

            if (this.personAnimation) {
                this.personAnimation.setAnimation(0, "minimal_short_hide", false);
                this.personBackground.setAnimation(0, "minimal_short_hide", false);
            }
        } else {
            this.bubbleHide();

            if (this.personAnimation) {
                this.personAnimation.setAnimation(0, "minimal_hide", false);
                this.personBackground.setAnimation(0, "minimal_hide", false);
            }
        }

        if (this.personAnimation) {
            this.personAnimation.setCompleteListenerOnce(function () {
                this.personAnimation.setVisible(false);
                this.personBackground.setVisible(false);
            }.bind(this));
        }

        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.6),
            new cc.CallFunc(function () {
                callback && callback();
            })
        ));

        this.hideCallback = callback;
        this.shown = false;
    },

    remove: function (callback, silent) {
        this.hide(function () {
            this.removeFromParent();

            callback && callback();
        }.bind(this), silent);
    }
});

cleverapps.styles.MinimalDialogue = {
    rectsPadding: 10,
    rectsPaddingMobile: 5,

    targetOffset: {
        left: 15, right: 20, top: 15, bottom: 25
    },

    maxWidth: 1000,
    margin: 10,

    moveY: 20,

    padding: {
        x: 10,
        y: 10
    },

    person: {
        x: { align: "center" },
        y: { align: "center" },
        margin: 10
    },

    text: {
        maxHeight: 450,
        minHeight: 90,
        padding: {
            top: 35,
            bottom: 35,
            left: 40,
            right: 40
        },
        verticalPadding: {
            top: 25,
            bottom: 25,
            left: 30,
            right: 30
        }
    },

    positions: {
        topLower: {
            y: 200
        },
        top: {
            y: 100
        },
        bottom: {
            y: 20
        }
    },

    default: {
        leftOffset: 80
    }
};