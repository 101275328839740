/**
 * Created by olga on 26.06.2024
 */

var PuzzlesComponent = cc.Node.extend({
    ctor: function (collection) {
        this._super();

        this.collection = collection;
        this.stickers = [];
        this.newStickers = [];

        this.createPlaceholders(collection.stickers);
        this.createStickerViews(collection.stickers);

        this.newPuzzlesSlots = new NewPuzzlesSlotsView(this);

        var layout = new cleverapps.Layout([this.puzzleBg, this.newPuzzlesSlots], {
            direction: cleverapps.UI.VERTICAL,
            margin: cleverapps.styles.PuzzlesComponent.margin
        });

        this.addChild(layout);
        this.setContentSize(layout.width, layout.height);
        layout.setPositionRound(this.width / 2, this.height / 2);

        this.setPositionRound(cleverapps.styles.PuzzlesComponent);

        this.collection.on("complete", this.onCompleteAnimation.bind(this), this);
    },

    createPlaceholders: function (stickers) {
        var styles = cleverapps.styles.PuzzlesComponent;
        var puzzleBg = this.puzzleBg = new cc.Sprite(bundles.stickers_collection_window.frames.puzzle_bg_png);
        puzzleBg.setLocalZOrder(1);

        this.placeholders = [];
        stickers.forEach(function () {
            var placeholder = new cc.Node();
            placeholder.setAnchorPoint(0.5, 0.5);
            placeholder.setContentSize2(styles.placeholder);
            this.placeholders.push(placeholder);
        }.bind(this));

        var layout = new cleverapps.GridLayout(this.placeholders, {
            columns: PuzzlesComponent.COLLUMNS_AMOUNT
        });
        layout.setLocalZOrder(1);

        layout.setPositionRound(styles.layout);
        puzzleBg.addChild(layout);
    },

    createStickerViews: function (stickers) {
        stickers.forEach(function (sticker, index) {
            var ViewClass = sticker.getViewClass();
            var stickerView = new ViewClass(sticker, {
                placeholder: this.placeholders[index],
                withUpdate: true
            });

            if (sticker.hasAttention()) {
                this.addControls(stickerView);
                this.newStickers.push(stickerView);
            } else {
                this.putUpPuzzle(stickerView);
            }
            this.stickers.push(stickerView);
        }.bind(this));
    },

    onCompleteAnimation: function () {
        var styles = cleverapps.styles.PuzzlesComponent.puzzleCompleteAnimation;
        var animation = new cleverapps.Spine(bundles.stickers_collection_window.jsons.puzzle_light_win_json);

        animation.setLocalZOrder(12);
        this.addChild(animation);
        animation.setPositionRound(styles);
        animation.setAnimation(0, "animation", false);
        animation.setCompleteListenerRemove();

        cleverapps.audio.playSound(bundles.stickers_collection_window.urls.puzzle_final_put_effect);
    },

    putUpPuzzle: function (stickerView, fromSlot) {
        var placeholder = stickerView.placeholder;
        if (stickerView.parent !== placeholder) {
            placeholder.addChild(stickerView);
        }

        stickerView.setScale(1);
        stickerView.setPositionRound(cleverapps.styles.PuzzleStickerView.position);
        stickerView.shadow.setLocalZOrder(0);
        stickerView.shadow.replaceParentSamePlace(this.puzzleBg);

        if (fromSlot) {
            stickerView.placeholder.setLocalZOrder(0);
            stickerView.sticker.setAttention(false);
            stickerView.pointHandler.remove();
            stickerView.pointHandler = undefined;
            this.newStickers.splice(this.newStickers.indexOf(stickerView), 1);
            this.newPuzzlesSlots.takeStickerFromSlot(stickerView);
            stickerView.animatePutUp();
        }
    },

    addControls: function (stickerView) {
        stickerView.pointHandler = cleverapps.UI.onDrag(stickerView.image, {
            instantDrag: true,
            followPointer: stickerView.createListener(function (touch) {
                var displacement = stickerView.parent.convertTouchToNodeSpaceDisplacement(touch);
                stickerView.setPositionRound(this.startDragPosition.x + displacement.x, this.startDragPosition.y + displacement.y);
            }.bind(this)),
            onDragStart: this.createListener(this.onDragStart.bind(this, stickerView)),
            onDragEnd: this.createListener(this.onDragEnd.bind(this, stickerView))
        });
    },

    onDragStart: function (stickerView) {
        if (this.dragging) {
            return false;
        }

        this.dragging = true;
        var window = cleverapps.windows.currentWindow();
        window.removeStickerTooltip();
        this.newPuzzlesSlots.setDisableScroll(true);

        stickerView.replaceParentSamePlace(stickerView.placeholder, { keepScale: true });
        this.startDragPosition = stickerView.getPosition();
        stickerView.placeholder.setLocalZOrder(10);

        stickerView.animateDragStart();
        return true;
    },

    onDragEnd: function (stickerView) {
        var position = stickerView.calculateViewPosition();
        if (cc.rectContainsPoint(stickerView.placeholder.getGlobalBoundingBox(), position)) {
            this.putUpPuzzle(stickerView, true);
            this.dragging = false;
        } else {
            stickerView.animateEndDrag({
                targetPos: this.startDragPosition,
                animateWrong: cc.rectContainsPoint(this.puzzleBg.getGlobalBoundingBox(), position),
                callback: function () {
                    this.dragging = false;
                    stickerView.placeholder.setLocalZOrder(0);
                    stickerView.replaceParentSamePlace(stickerView.slot, { keepScale: true });
                }.bind(this)
            });
        }
    },

    onClose: function () {}
});

PuzzlesComponent.COLLUMNS_AMOUNT = 3;

cleverapps.styles.PuzzlesComponent = {
    x: { align: "center" },
    y: { align: "center", dy: -50 },

    placeholder: {
        width: 241,
        height: 241
    },

    margin: 20,

    layout: {
        x: { align: "center", dx: 2 },
        y: { align: "center", dy: 2 }
    },

    puzzleCompleteAnimation: {
        x: { align: "center", dx: 5 },
        y: { align: "center", dy: 135 }
    }
};