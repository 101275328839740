/**
 * Created by Andrey Popov on 2/1/22.
 */

var LevelPassWindow = CleverappsWindow.extend({
    onWindowLoaded: function (mission) {
        this.mission = mission;

        this._super({
            name: "LevelPassWindow",
            title: "LevelPassWindow.title",
            content: this.createContent(),
            closeButton: true,
            styles: cleverapps.styles.LevelPassWindow.window,
            foreground: bundles.passforeground.frames.window_foreground_png,
            help: function () {
                new GuideWindow(mission.rulesOptions);
            }
        });
    },

    onShow: function () {
        this._super();
        this.mission.logic.handleShowWindow();
    },

    createContent: function () {
        var styles = cleverapps.styles.LevelPassWindow;
        var width = this.calcContentWidth();

        return new PassWindowContent({
            components: [{
                name: "progress",
                class: PassProgress
            }, {
                name: "header",
                class: PassHeader
            }, {
                name: "tickets",
                class: PassTickets,
                params: { close: this.close.bind(this) }
            }],
            width: width,
            styles: styles,
            passLogic: this.mission.logic
        });
    },

    calcContentWidth: function () {
        var styles = cleverapps.styles.LevelPassWindow;
        var width = cleverapps.resolution.getSceneSize().width - 2 * styles.paddingX;
        if (width < styles.minWidth) {
            width = styles.minWidth;
        }
        if (width > styles.maxWidth) {
            width = styles.maxWidth;
        }
        return width;
    },

    listBundles: function () {
        return ["pass"];
    }
});

cleverapps.styles.LevelPassWindow = {
    minWidth: 900,
    maxWidth: 1800,
    height: 900,
    paddingX: 50,

    window: {
        padding: {
            left: 0,
            right: 0,
            top: 160,
            bottom: 50
        }
    },

    "progress": {
        x: { align: "right", dx: -25 },
        y: { align: "top", dy: -210 }
    },

    "header": {
        x: { align: "center" },
        y: { align: "top", dy: -10 }
    },

    "tickets": {
        x: { align: "left", dx: 10 },
        y: { align: "top", dy: -243 }
    }
};