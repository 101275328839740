/**
 * Created by mac on 12/7/20
 */

var Map2d = function (groundMap, options) {
    Map2d.currentMap = this;

    this.counter = new Counter();

    cleverapps.EventEmitter.call(this);

    options = options || {};
    this.field = options.field;
    this.families = options.families;

    this.unitsSkin = options.units;
    this.tilesSkin = options.tiles;

    this.visibleBox = this.prepareVisibleBox(options.visibleBox || Map2d.VISIBLE_BOX);
    this.tilesVisibleRectFrame = options.tilesVisibleRectFrame || Map2d.TILES_VISIBLE_RECT_FRAME;
    this.waterBorder = options.waterBorder !== undefined ? options.waterBorder : Map2d.WATER_BORDER;
    this.regions = options.regions || {};
    this.decorators = new Map2dDecorators(options.decorators || []);
    this.fences = new Map2dFences(this);
    this.edges = new Map2dEdges(this);
    this.terrains = (options.terrains || []).map(function (line) {
        return line.split("");
    });

    groundMap = this.groundMap = groundMap.map(function (row) {
        if (typeof row === "string") {
            row = row.split("").map(cleverapps.castType);
        }
        return row;
    });

    this.width = groundMap[0].length;
    this.height = groundMap.length;

    var water = this.map(groundMap, function (cell) {
        return (cell === Map2d.TILE_WATER || cell === Map2d.TILE_WATER_UNIT) ? cell : undefined;
    });

    var borders = Map2d.BORDERS ? this.map(groundMap, function (cell) {
        return (cell === Map2d.TILE_WATER || cell === Map2d.TILE_WATER_UNIT) ? Map2d.TILE_BORDER : undefined;
    }) : [];

    var ground = this.map(groundMap, function (cell) {
        return (cell === Map2d.TILE_WATER || cell === Map2d.TILE_WATER_UNIT) ? undefined : cell;
    });

    var chess = this.map(groundMap, function (cell) {
        if (cell === Map2d.TILE_WATER) {
            return undefined;
        }

        if ([Map2d.TILE_IMPASSABLE_LEVEL_1, Map2d.TILE_IMPASSABLE_LEVEL_2].includes(cell)) {
            if (cleverapps.config.debugMode) {
                return Map2d.TILE_STARRED_GROUND_CHESS;
            }

            return undefined;
        }

        return Map2d.TILE_GROUND_CHESS;
    });

    var units = this.map(groundMap, function () {
        return undefined;
    });

    var fog = this.map(groundMap, function () {
        return undefined;
    });

    this.layers = [water, borders, ground, chess, units, fog];

    this.onAdd = function () {};
    this.onAddUnit = function () {};
    this.onAnimationsAdd = function () {};
    this.onMove = function () {};
    this.onDiscoverMapView = function () {};
    this.stopDragging = function () {};

    this.showTiles = function () {};
    this.hideTiles = function () {};
    this.removeTiles = function () {};
    this.showLayerTile = function () {};

    this.onUnitAvailableListener = function () {};
    this.onUnitFreshListener = function () {};

    this.pointer = {
        x: 0,
        y: 0
    };

    this.constructTerrains();
    this.constructGrowth();

    this.growFrameData = {};
    this.borderTypes = {};

    this.screenRect = cc.rect(0, 0, 1, 1);
    this.visitRect = cc.rect(0, 0, 1, 1);
    this.showRect = cc.rect(0, 0, 1, 1);
    this.visibleCells = {};

    this.initVisibleBoxRect();

    this.removeQueue = new Map2dRemoveQueue(this);

    this.workers = new Workers();
    this.customers = new Customers();

    this.fogs = cleverapps.config.editorMode ? new EditorFogs() : new Fogs(this, options.isNewGame);

    this.saveData = { units: {}, fogs: [] };

    if (cleverapps.config.type === "merge") {
        this.unitGreeters = new UnitGreeters();

        if (cleverapps.config.debugMode && !cleverapps.config.editorMode) {
            this.orangery = new Orangery(MergeOrangery);
        }
    }

    if (cleverapps.config.name === "wordsoup") {
        this.orangery = new Orangery(HomefixOrangery);
    }

    this.scroller = new Map2dScroller(this);
};

Map2d.prototype = Object.create(cleverapps.EventEmitter.prototype);
Map2d.prototype.constructor = Map2d;

Map2d.TILE_WATER = 0;
Map2d.TILE_GREEN_LEVEL_1 = 1;
Map2d.TILE_GREEN_LEVEL_2 = 2;
Map2d.TILE_IMPASSABLE_LEVEL_1 = 3;
Map2d.TILE_IMPASSABLE_LEVEL_2 = 4;
Map2d.TILE_WATER_UNIT = 9;
Map2d.TILE_GROW = 10;
Map2d.TILE_BORDER = 11;
Map2d.TILE_GROUND_CHESS = 12;
Map2d.TILE_STARRED_GROUND_CHESS = 13;

Map2d.LAYER_WATER = 0;
Map2d.LAYER_BORDERS = 1;
Map2d.LAYER_GROUND = 2;
Map2d.ABOVE_GROUND = 3;
Map2d.LAYER_UNITS = 4;
Map2d.LAYER_FOG = 5;

var Iso = {
    SAME: {
        x: 0,
        y: 0
    },

    UP_LEFT: {
        y: -1,
        x: 0
    },

    UP_RIGHT: {
        y: 0,
        x: 1
    },

    UP_ABOVE: {
        y: -1,
        x: 1
    },

    DOWN_LEFT: {
        y: 0,
        x: -1
    },

    DOWN_RIGHT: {
        y: 1,
        x: 0
    },

    DOWN_BELOW: {
        y: 1,
        x: -1
    },

    HORIZONTAL_LEFT: {
        y: -1,
        x: -1
    },

    HORIZONTAL_RIGHT: {
        y: 1,
        x: 1
    }
};

var ISO_NEIGHBORS = [Iso.DOWN_LEFT, Iso.DOWN_RIGHT, Iso.UP_LEFT, Iso.UP_RIGHT];

Map2d.prototype.load = function (data) {
    Object.keys(data).forEach(function (key) {
        var position = Map2d.ParsePositionKey(key);
        this.loadUnit(position.x, position.y, data[key]);
    }.bind(this));
};

Map2d.prototype.getInfo = function () {
    return this.saveData;
};

Map2d.prototype.saveUnit = function (unit) {
    if (cleverapps.config.adminMode || cleverapps.config.editorMode) {
        return;
    }

    if (unit.isLifted() || UnitSyncer.DISABLED_CODES[unit.code]) {
        return;
    }

    if (unit.x && unit.y) {
        var key = Map2d.GetPositionKey(unit.x, unit.y);
        this.saveData.units[key] = unit.getInfo();
    }

    if (!cleverapps.config.saveUnitsEnabled) {
        return;
    }

    this.trigger("saveUnit", unit);
};

Map2d.prototype.removeUnit = function (unit) {
    var key = Map2d.GetPositionKey(unit.x, unit.y);
    delete this.saveData.units[key];
    this.trigger("removeUnit", unit);
};

Map2d.prototype.saveFogs = function () {
    this.saveData.fogs = this.fogs.getInfo();
    this.trigger("saveFogs", this.fogs);
};

Map2d.prototype.onUnitFresh = function (unit) {
    this.onUnitFreshListener(unit);
};

Map2d.prototype.onUnitRemoved = function (unit) {
    this.customers.onUnitRemoved(unit);

    if (unit.getData().feedableTarget) {
        Feedable.processFeedable();
    }
};

Map2d.prototype.onUnitAvailable = function (unit) {
    this.customers.onUnitAvailable(unit);

    var worker = unit.findComponent(InstantWorker);
    if (worker) {
        this.workers.addInstant(worker);
    }

    this.onUnitAvailableListener(unit);
};

Map2d.prototype.focusOnUnit = function (unit, options) {
    options = options || {};

    var actions = [];

    if (options.zoom) {
        actions.push(new cc.ZoomAction(options.zoom.duration, { zoom: options.zoom.zoom }));
    }

    actions = actions.concat([
        new cc.CellScrollAction(unit, {
            allowScrollWithFocus: options.allowScrollWithFocus,
            duration: options.duration,
            skipFocusReport: options.skipFocusReport,
            visibleBox: options.visibleBox
        }).easing(cc.easeInOut(2)),
        new cc.CallFunc(function () {
            options.action && options.action();
        }),
        new cc.DelayTime(options.delay || 0),
        new cc.CallFunc(function () {
            options.callback && options.callback();
        })
    ]);

    this.scroller.getScrollView().runAction(
        new cc.Sequence(actions)
    );
};

Map2d.prototype.focusOnTarget = function (target, options) {
    options = options || {};

    this.scroller.getScrollView().runAction(new cc.Sequence(
        new cc.ScrollAction(target, {
            allowScrollWithFocus: options.allowScrollWithFocus,
            duration: options.duration,
            skipFocusReport: options.skipFocusReport
        }).easing(cc.easeInOut(2)),
        new cc.CallFunc(function () {
            options.callback && options.callback();
        })
    ));
};

Map2d.prototype.exploreArea = function (fakeUnit, callback) {
    this.scroller.getScrollView().runAction(new cc.Sequence(
        new cc.ZoomAction(0.7, { zoom: 0.6 }),
        new cc.CellScrollAction(fakeUnit, {
            allowScrollWithFocus: true
        }).easing(cc.easeInOut(2)),
        new cc.DelayTime(0.4),
        new cc.ZoomAction(0.8, {
            zoom: 0.9,
            zoomFocus: Map2d.currentMap.getMapView().getCell(fakeUnit.x, fakeUnit.y)
        }),
        new cc.CallFunc(function () {
            setTimeout(function () {
                cleverapps.centerHint.createTextHint("Fog.explore");
            }, 2000);
        }),
        new cc.CellScrollAction({
            x: fakeUnit.x + 20,
            y: fakeUnit.y + 20
        }, {
            duration: 6,
            allowScrollWithFocus: true
        }).easing(cc.easeInOut(1)),
        new cc.CallFunc(callback)
    ));
};

Map2d.prototype.getFocusCell = function () {
    return this.scroller.scrollCell;
};

Map2d.prototype.initFocusCell = function () {
    var scrollCell = undefined;
    this.scroller.zoom = this.scroller.getBasicZoom();
    scrollCell = this.fogs.blocks.fog0 && this.fogs.blocks.fog0.head;
    if (cleverapps.config.demoMode) {
        var units = this.listAvailableUnits();
        var geometricCenter = units.reduce(function (total, unit) {
            return cc.pAdd(total, cc.pMult(unit, 1 / units.length));
        }, cc.p(0, 0));
        scrollCell = cc.p(Math.floor(geometricCenter.x), Math.floor(geometricCenter.y));
    }
    this.scroller.setScrollCell(scrollCell);
};

Map2d.prototype.onPositionChanged = function () {
    this.scroller.onUpdateScroll();
};

Map2d.prototype.showAllUnits = function (units, createRemoveUnitAction, callback) {
    var first = units[0];
    var last = units[units.length - 1];
    var lines = last.y - first.y;
    var delay = Math.max(1000, 2400 * this.getCenterDistance(first) / this.getWidth());
    var total = Math.min(Math.max(1500, 100 * lines), 6000);

    this.scroller.getScrollView().runAction(
        new cc.Sequence(
            new cc.ZoomAction(0.7, { zoom: 0.5 }),
            new cc.DelayTime(1),
            new cc.CellScrollAction(first, { duration: delay / 1000, allowScrollWithFocus: true }).easing(cc.easeIn(1.4)),
            new cc.DelayTime(0.2),
            new cc.ZoomAction(0.4, { zoom: 0.9 }),
            new cc.CallFunc(function () {
                cleverapps.focusManager.showControlsWhileFocused(["MenuBarGoldItem", "MenuBarCoinsItem"]);
            }),
            new cc.Spawn(
                createRemoveUnitAction(total, units),
                new cc.CellScrollAction(last, { duration: total / 1000, allowScrollWithFocus: true }).easing(cc.easeIn(1.4))
            ),
            new cc.DelayTime(1.4),
            new cc.CallFunc(function () {
                cleverapps.focusManager.hideControlsWhileFocused(["MenuBarGoldItem", "MenuBarCoinsItem"]);
            }),
            new cc.CallFunc(callback)
        )
    );
};

Map2d.prototype.prepareVisibleBox = function (visibleBox) {
    if (Array.isArray(visibleBox)) {
        visibleBox = visibleBox.slice().reverse().find(function (visibleBox) {
            return cleverapps.user.checkAvailable(visibleBox.available)
                || cleverapps.config.editorMode || cleverapps.config.adminMode;
        });
    }

    if (!visibleBox.cutLeft && !visibleBox.cutRight) {
        visibleBox.cutRight = (1 - visibleBox.width) / 2;
        visibleBox.cutLeft = visibleBox.cutRight;
    }

    if (!visibleBox.cutTop && !visibleBox.cutBottom) {
        visibleBox.cutTop = (1 - visibleBox.height) / 2;
        visibleBox.cutBottom = visibleBox.cutTop;
    }

    return visibleBox;
};

Map2d.prototype.initWorkers = function () {
    this.workers.regular.forEach(function (worker) {
        worker.run();
    });

    this.workers.runSpecialWorkers();
    this.workers.onChangeTotalAmount();

    var worker = this.workers.findLeastBusy(Buildable);
    if (worker && worker.getTimeLeft() <= 0) {
        Map2d.currentMap.focusOnUnit(worker.unit, {
            allowScrollWithFocus: true
        });
    }
};

Map2d.prototype.getMapView = function () {
    return this.onDiscoverMapView();
};

Map2d.prototype.initFogs = function (saved) {
    this.fogs.load(saved);
    this.fogs.calcFogStates();
    this.fogs.hideBalloons();
    this.blockedGrounds = new FogBlockedGround(this.fogs);
    this.saveFogs();
    this.saveData.fogs = this.fogs.getInfo();
};

Map2d.prototype.stop = function () {
    Feedable.Clear();
    this.counter.turnOff();

    this.stopped = true;

    this.listAvailableUnits().forEach(function (unit) {
        unit.destructor();
    });
    this.decorators.destructor();

    this.workers && this.workers.destructor();
    this.unitGreeters && this.unitGreeters.destructor();

    this.hideTiles = function () {};
    this.removeTiles = function () {};
    this.showTiles = function () {};
    this.showLayerTile = function () {};
    this.onUnitAvailableListener = function () {};
    this.onUnitFreshListener = function () {};

    this.trigger("stop");
    Map2d.currentMap = undefined;
    Map2dScroller.currentScroller = undefined;
};

Map2d.prototype.setPointer = function (x, y) {
    this.pointer.x = x;
    this.pointer.y = y;
};

Map2d.prototype.initVisibleBoxRect = function () {
    var size = this.width + this.height;
    var top = this.width - 1;
    var bottom = -this.height + 1;

    var minX = size / 2 - size * (0.5 - this.visibleBox.cutLeft) - this.waterBorder;
    var maxX = size / 2 + size * (0.5 - this.visibleBox.cutRight) + this.waterBorder;
    var minY = bottom + size * this.visibleBox.cutBottom - this.waterBorder;
    var maxY = top - size * this.visibleBox.cutTop + this.waterBorder;

    this.visibleBoxRect = cc.rect(
        Math.floor((minX + minY) / 2),
        Math.floor((minX - minY) / 2),
        Math.ceil((maxX - minX) / 2),
        Math.ceil((maxY - minY) / 2)
    );
};

Map2d.prototype.getVisibleBoxRect = function () {
    return this.visibleBoxRect;
};

Map2d.prototype.map = function (array2d, iterator) {
    return array2d.map(function (row, y) {
        return row.map(function (cell, x) {
            return iterator(cell, x, y);
        }, this);
    }, this);
};

Map2d.prototype.iterateLayerCells = function (layerId, iterator) {
    // if (layerId === Map2d.LAYER_WATER) {
    //     return;
    // }
    //
    // if (layerId === Map2d.LAYER_BORDERS) {
    //     return;
    // }
    //
    // if (layerId === Map2d.LAYER_GROUND) {
    //     return;
    // }

    // if (layerId === Map2d.LAYER_FOG) {
    //     return;
    // }
    //
    // if (layerId === Map2d.LAYER_UNITS) {
    //     return;
    // }
    if (layerId === Map2d.LAYER_WATER && !cleverapps.config.editorMode) {
        this.iterateWaterCells(iterator);
        return;
    }

    this.layers[layerId].forEach(function (row, y) {
        row.forEach(function (object, x) {
            if (cc.isoRectContainsPoint(this.visibleBoxRect, x, y)) {
                iterator(x, y, object);
            }
        }, this);
    }, this);
};

Map2d.prototype.iterateWaterCells = function (iterator) {
    cc.iterateIsoRect(this.visibleBoxRect, function (x, y) {
        iterator(x, y, this.getValue(Map2d.LAYER_WATER, x, y));
    }.bind(this));
};

Map2d.prototype.getGround = function (dir) {
    return this.layers[Map2d.LAYER_GROUND][this.pointer.y + dir.y] && this.layers[Map2d.LAYER_GROUND][this.pointer.y + dir.y][this.pointer.x + dir.x];
};

Map2d.prototype.getTileAboveGround = function (dir) {
    return this.layers[Map2d.ABOVE_GROUND][this.pointer.y + dir.y] && this.layers[Map2d.ABOVE_GROUND][this.pointer.y + dir.y][this.pointer.x + dir.x];
};

Map2d.prototype.isValid = function (x, y) {
    return x >= 0 && x < this.width && y >= 0 && y < this.height;
};

Map2d.prototype.getTerrainCode = function (x, y) {
    if (typeof x === "object" && x.direction) {
        y = this.pointer.y + x.y;
        x = this.pointer.x + x.x;
    }

    return this.terrains[y] && this.terrains[y][x] || Map2d.TERRAIN_GREEN;
};

Map2d.prototype.getGroundTerrainCode = function (x, y) {
    if (typeof x === "object" && x.direction) {
        y = this.pointer.y + x.y;
        x = this.pointer.x + x.x;
    }

    if (this.isGround(x, y)) {
        var terrain = this.getTerrainCode(x, y);
        return terrain !== Map2d.TERRAIN_EMPTY ? terrain : undefined;
    }
};

Map2d.prototype.setTerrain = function (x, y, value) {
    this.terrains[y][x] = value;
};

Map2d.prototype.getTerrainType = function (x, y) {
    var tilesTexture = Map2d.getTilesTexture(this.tilesSkin);

    return {
        name: tilesTexture + "_" + Map2d.TERRAINS[this.getTerrainCode(x, y)],
        defaultTiles: tilesTexture + "_" + bundles[tilesTexture].meta.defaultTerrain
    };
};

Map2d.prototype.getTerrainConfig = function (x, y) {
    var type = this.getTerrainType(x, y);
    return Map2d.TERRAIN_CONFIG[type.name] || Map2d.TERRAIN_CONFIG.default;
};

Map2d.prototype.getTerrainFrames = function (x, y) {
    var type = this.getTerrainType(x, y);
    return (Map2d.TERRAIN_BUNDLES[type.name] || bundles[type.defaultTiles]).frames;
};

Map2d.prototype.getTerrainFrameData = function (name, x, y) {
    var frames = this.getTerrainFrames(x, y);
    var config = this.getTerrainConfig(x, y);
    return { res: frames[name], options: config.frames[name] || {} };
};

Map2d.prototype.getValue = function (layerId, x, y) {
    if (typeof x === "object") {
        if (x.direction) {
            y = this.pointer.y + x.y;
            x = this.pointer.x + x.x;
        }
    }

    if (layerId === Map2d.LAYER_WATER && (x < 0 || x >= this.getWidth() || y < 0 || y >= this.getHeight())
        && cc.isoRectContainsPoint(this.visibleBoxRect, x, y) && !cleverapps.config.editorMode) {
        return Map2d.TILE_WATER;
    }

    return this.layers[layerId][y] && this.layers[layerId][y][x];
};

Map2d.prototype.setValue = function (layer, dir, value) {
    var x = this.pointer.x + dir.x;
    var y = this.pointer.y + dir.y;
    this.trigger("removeValue", layer, x, y);
    this.layers[layer][y][x] = value;
    this.trigger("setValue", layer, x, y);
};

Map2d.prototype.add = function (layer, x, y, unit) {
    this.layers[layer][y][x] = unit;
};

Map2d.prototype.getFog = function (x, y) {
    return this.layers[Map2d.LAYER_FOG][y] && this.layers[Map2d.LAYER_FOG][y][x];
};

Map2d.prototype.remove = function (layer, x, y) {
    if (this.dragging && layer === Map2d.LAYER_UNITS && this.dragging.x === x && this.dragging.y === y) {
        cleverapps.throwAsync("Remove draggging unit");
    }
    if (layer === Map2d.LAYER_UNITS) {
        var unit = this.getUnit(x, y);
        if (unit) {
            unit.removeStack = "x - " + x + ", y - " + y + ", stack - " + new Error().stack;
            // console.log(unit.removeStack);
        }
    }
    this.layers[layer][y][x] = undefined;
};

Map2d.prototype.isWater = function (dir) {
    var value = this.getValue(Map2d.LAYER_WATER, dir);
    return [Map2d.TILE_WATER, Map2d.TILE_WATER_UNIT].includes(value);
};

Map2d.prototype.isWaterUnit = function (x, y) {
    return this.layers[Map2d.LAYER_WATER][y] && this.layers[Map2d.LAYER_WATER][y][x] === Map2d.TILE_WATER_UNIT;
};

Map2d.prototype.isGround = function (x, y) {
    var value = this.getValue(Map2d.LAYER_GROUND, x, y);
    return [Map2d.TILE_GREEN_LEVEL_1, Map2d.TILE_GREEN_LEVEL_2].includes(value);
};

Map2d.prototype.isImpassableGround = function (x, y) {
    var value = this.getValue(Map2d.LAYER_GROUND, x, y);
    return [Map2d.TILE_IMPASSABLE_LEVEL_1, Map2d.TILE_IMPASSABLE_LEVEL_2].includes(value);
};

Map2d.prototype.isLand = function (x, y) {
    var value = this.getValue(Map2d.LAYER_GROUND, x, y);
    return [Map2d.TILE_GREEN_LEVEL_1, Map2d.TILE_GREEN_LEVEL_2,
        Map2d.TILE_IMPASSABLE_LEVEL_1, Map2d.TILE_IMPASSABLE_LEVEL_2].includes(value);
};

Map2d.prototype.isGrowth = function (x, y) {
    var value = this.getValue(Map2d.LAYER_GROUND, x, y);
    return Map2d.TILE_GROW === value;
};

Map2d.prototype.getUnit = function (x, y) {
    return this.layers[Map2d.LAYER_UNITS][y] && this.layers[Map2d.LAYER_UNITS][y][x];
};

Map2d.prototype.getUnitWithHead = function (x, y) {
    var unit = this.getUnit(x, y);
    if (unit && unit.head) {
        return unit.head;
    }
    return unit;
};

Map2d.prototype.bfs = function (x, y, filter) {
    if (!filter(x, y)) {
        return [];
    }

    var dirs = ISO_NEIGHBORS;
    var area = [{ x: x, y: y }];

    var used = {};
    used[this.getPositionKey(x, y)] = true;

    var pointer = 0;
    while (pointer < area.length) {
        var p = area[pointer++];
        for (var i = 0; i < dirs.length; i++) {
            var tx = p.x + dirs[i].x;
            var ty = p.y + dirs[i].y;

            var key = this.getPositionKey(tx, ty);
            if (!used[key]) {
                used[key] = true;

                if (filter(tx, ty)) {
                    area.push({ x: tx, y: ty });
                }
            }
        }
    }

    return area;
};

Map2d.prototype.iterateBfs = function (x, y, iterator) {
    var dirs = ISO_NEIGHBORS;
    var queue = [{ x: x, y: y, step: 0 }];

    var used = {};
    used[this.getPositionKey(x, y)] = true;

    var pointer = 0;
    while (pointer < queue.length) {
        var p = queue[pointer++];

        if (iterator(p)) {
            return;
        }

        for (var i = 0; i < dirs.length; i++) {
            var tx = p.x + dirs[i].x;
            var ty = p.y + dirs[i].y;

            var key = this.getPositionKey(tx, ty);
            if (!used[key]) {
                used[key] = true;

                if (tx >= 0 && tx < this.width && ty >= 0 && ty < this.height) {
                    queue.push({ x: tx, y: ty, step: p.step + 1 });
                }
            }
        }
    }
};

Map2d.prototype.getPositionKey = function (x, y) {
    return x + y * this.width;
};

Map2d.prototype.compareMergeable = function (target, x, y) {
    var unit = this.getUnit(x, y);
    return unit && !this.getFog(x, y) && target.isMergeable(unit);
};

Map2d.prototype.compareEqual = function (target, x, y) {
    var unit = this.getUnit(x, y);
    return unit && !unit.isDragged() && !this.getFog(x, y) && Unit.Equals(target, unit);
};

Map2d.prototype.setScreenRect = function (screenRect) {
    this.screenRect = screenRect;
};

Map2d.prototype.setVisitRect = function (visitRect, options) {
    this.lastVisitRectTime = cleverapps.timeouts.time;

    options = options || {};
    var showRect = options.showRect || visitRect;
    var showCells = options.showCells || {};

    if (cc.rectEqualToRect(visitRect, this.visitRect) && cc.rectEqualToRect(showRect, this.showRect)) {
        return;
    }

    var self = this;

    var needShow = function (x, y) {
        return cc.isoRectContainsPoint(showRect, x, y) || showCells[y] && showCells[y][x];
    };

    for (var y in this.visibleCells) {
        for (var x in this.visibleCells[y]) {
            if (!needShow(x, y)) {
                self.hideTiles(x, y);
                self.removeQueue.add(x, y);
                delete this.visibleCells[y][x];
            }
        }
    }

    cc.iterateIsoRect(visitRect, function (x, y) {
        if (!self.visibleCells[y]) {
            self.visibleCells[y] = {};
        }

        if (needShow(x, y)
            && !self.visibleCells[y][x]
            && cc.isoRectContainsPoint(self.visibleBoxRect, x, y)) {
            self.removeQueue.remove(x, y);
            self.showTiles(x, y);

            self.visibleCells[y][x] = true;
        }
    });

    this.visitRect = visitRect;
    this.showRect = showRect;
};

Map2d.prototype.getVisitRect = function () {
    return this.visitRect;
};

Map2d.prototype.isVisibleCell = function (x, y) {
    return this.visibleCells[y] && this.visibleCells[y][x];
};

Map2d.prototype.getScreenCenterCell = function () {
    return cc.isoRectGetCenter(this.screenRect);
};

Map2d.prototype.getMapCenterCell = function () {
    return cc.p(Math.round(this.width / 2), Math.round(this.height / 2));
};

Map2d.prototype.listAvailableGrounds = function () {
    var cells = [];

    for (var y = 0; y < this.getHeight(); y++) {
        for (var x = 0; x < this.getWidth(); x++) {
            if (this.isGround(x, y) && !this.getFog(x, y)) {
                cells.push(cc.p(x, y));
            }
        }
    }

    return cells;
};

Map2d.prototype.listAvailableUnits = function (filter) {
    var units = [];

    if (filter && typeof filter !== "function") {
        filter = Unit.IsApplicable.bind(Unit, filter);
    }

    for (var y = 0; y < this.getHeight(); y++) {
        for (var x = 0; x < this.getWidth(); x++) {
            var unit = this.getUnit(x, y);
            if (unit && (!filter || filter(unit))) {
                units.push(unit);
            }
        }
    }

    return units;
};

Map2d.prototype.listAvailableUnitsInRegion = function (region, targets) {
    var units = [];

    if (!this.regions[region]) {
        return units;
    }

    this.regions[region].positions.forEach(function (cell) {
        var unit = this.getUnit(cell.x, cell.y);
        if (unit && Unit.IsApplicable(targets, unit)) {
            units.push(unit);
        }
    }, this);

    return units;
};

Map2d.prototype.isEmpty = function (x, y) {
    return this.isGround(x, y) && !this.getUnit(x, y) && !this.getFog(x, y);
};

Map2d.prototype.countEmptySlots = function () {
    var amount = 0;
    for (var y = 0; y < this.getHeight(); y++) {
        for (var x = 0; x < this.getWidth(); x++) {
            if (this.isEmpty(x, y)) {
                amount++;
            }
        }
    }
    return amount;
};

Map2d.prototype.findEmptySlot = function (x, y, unit, options) {
    options = options || {};

    var index = ISO_NEIGHBORS;

    var checkEqualCell = function (cell) {
        for (var i = 0; i < index.length; i++) {
            var otherUnit = this.getUnit(cell.x + index[i].x, cell.y + index[i].y);
            if (Unit.Equals(unit, otherUnit) && !this.getFog(cell.x + index[i].x, cell.y + index[i].y)) {
                return true;
            }
        }
    }.bind(this);

    var checkSameLineCell = function (cell) {
        for (var i = 0; i < index.length; i++) {
            var otherUnit = this.getUnit(cell.x + index[i].x, cell.y + index[i].y);
            if (Unit.SameLine(unit, otherUnit) && !this.getFog(cell.x + index[i].x, cell.y + index[i].y)) {
                return true;
            }
        }
    }.bind(this);

    cleverapps.Random.shuffle(index);

    var shape = Unit.GetShape(unit);
    var checkScreen = options.skipCheckScreen !== undefined ? !options.skipCheckScreen : true;
    var firstCell;
    var screenCell;
    var equalCell;
    var sameLineCell;
    var sameLineAndScreenCell;
    var radius = options.radius !== undefined ? options.radius : Map2d.RADIUS_BIG;
    var hasScreenCell = true;

    for (var d = 0; d < 100; d++) {
        if (firstCell) {
            if (d > radius || (!checkScreen && !hasScreenCell)) {
                break;
            }
        }
        hasScreenCell = false;

        for (var dd = 0; dd <= d; dd++) {
            for (var j = 0; j < 2; j++) {
                var rd = dd;
                if (j > 0) {
                    if (dd === 0) {
                        continue;
                    }
                    rd = -dd;
                }
                for (var i = 0; i < index.length; i++) {
                    var nx = x + ((index[i].x === 0) ? rd : (index[i].x * d));
                    var ny = y + ((index[i].y === 0) ? rd : (index[i].y * d));

                    var cells = shape.map(function (part) {
                        return {
                            x: nx + part.x,
                            y: ny + part.y
                        };
                    });

                    var isScreenCell = false;

                    isScreenCell = cells.every(this.isScreenCell.bind(this));
                    if (isScreenCell) {
                        hasScreenCell = true;
                    }

                    var isEqualCell = cells.every(checkEqualCell);
                    var isSameLineCell = cells.every(checkSameLineCell);

                    if (cells.every(function (cell) {
                        return this.isEmpty(cell.x, cell.y);
                    }, this)) {
                        var cell = cc.p(nx, ny);
                        firstCell = firstCell || cell;
                        if (isScreenCell) {
                            screenCell = screenCell || cell;
                            if (isEqualCell || options.skipCheckEqual) {
                                return cell;
                            }
                            if (isSameLineCell) {
                                sameLineAndScreenCell = sameLineAndScreenCell || cell;
                            }
                        }
                        if (isSameLineCell) {
                            sameLineCell = sameLineCell || cell;
                        }
                        if (isEqualCell) {
                            equalCell = equalCell || cell;
                        }
                    }
                }
            }
        }
    }

    return sameLineAndScreenCell || screenCell || equalCell || sameLineCell || firstCell;
};

Map2d.prototype.hasMergeable = function () {
    var mergeable = {};
    var units = this.listAvailableUnits();
    for (var i = 0; i < units.length; i++) {
        var unit = units[i];
        if (unit.isMergeable(unit)) {
            var code = unit.code;
            var stage = unit.stage;
            mergeable[code] = mergeable[code] || {};
            mergeable[code][stage] = mergeable[unit.code][stage] || [];
            mergeable[code][stage].push(unit);
            if (mergeable[code][stage].length > 2) {
                return unit;
            }
        }
    }
};

Map2d.prototype.isScreenCellPosition = function (x, y) {
    return cc.isoRectContainsPoint(this.screenRect, x, y);
};
Map2d.prototype.isScreenCell = function (cell) {
    return this.isScreenCellPosition(cell.x, cell.y);
};

Map2d.prototype.getCenterDistance = function (x, y) {
    var center = this.getScreenCenterCell();
    return center ? cc.pDistance(center, cc.p(x, y)) : 1e3;
};

Map2d.prototype.constructTerrains = function () {
    for (var y = 0; y < this.getHeight(); y++) {
        if (this.terrains.length <= y) {
            this.terrains.push([]);
        }
        for (var x = 0; x < this.getWidth(); x++) {
            if (this.terrains[y].length <= x) {
                this.terrains[y].push(Map2d.TERRAIN_EMPTY);
            }
            if (this.terrains[y][x] === Map2d.TERRAIN_EMPTY && this.isLand(x, y)) {
                this.terrains[y][x] = Map2d.TERRAIN_GREEN;
            }
        }
    }
};

Map2d.prototype.constructGrowth = function (x, y) {
    if (x === undefined) {
        for (y = 0; y < this.getHeight(); y++) {
            for (x = 0; x < this.getWidth(); x++) {
                this.constructGrowth(x, y);
            }
        }
        return;
    }

    this.setPointer(x, y);
    if (this.getGround(Iso.SAME) === Map2d.TILE_GREEN_LEVEL_2 || this.getGround(Iso.SAME) === Map2d.TILE_IMPASSABLE_LEVEL_2) {
        if (this.getGround(Iso.DOWN_LEFT) === Map2d.TILE_GREEN_LEVEL_1 || this.getGround(Iso.DOWN_LEFT) === Map2d.TILE_IMPASSABLE_LEVEL_1) {
            this.setValue(Map2d.LAYER_GROUND, Iso.DOWN_LEFT, Map2d.TILE_GROW);
            this.setValue(Map2d.ABOVE_GROUND, Iso.DOWN_LEFT, cleverapps.config.debugMode ? Map2d.TILE_STARRED_GROUND_CHESS : undefined);
        }
        if (this.getGround(Iso.DOWN_RIGHT) === Map2d.TILE_GREEN_LEVEL_1 || this.getGround(Iso.DOWN_RIGHT) === Map2d.TILE_IMPASSABLE_LEVEL_1) {
            this.setValue(Map2d.LAYER_GROUND, Iso.DOWN_RIGHT, Map2d.TILE_GROW);
            this.setValue(Map2d.ABOVE_GROUND, Iso.DOWN_RIGHT, cleverapps.config.debugMode ? Map2d.TILE_STARRED_GROUND_CHESS : undefined);
        }
        if (this.getGround(Iso.DOWN_BELOW) === Map2d.TILE_GREEN_LEVEL_1 || this.getGround(Iso.DOWN_BELOW) === Map2d.TILE_IMPASSABLE_LEVEL_1) {
            this.setValue(Map2d.LAYER_GROUND, Iso.DOWN_BELOW, Map2d.TILE_GROW);
            this.setValue(Map2d.ABOVE_GROUND, Iso.DOWN_BELOW, cleverapps.config.debugMode ? Map2d.TILE_STARRED_GROUND_CHESS : undefined);
        }
    }
};

Map2d.prototype.getWidth = function () {
    return this.width;
};

Map2d.prototype.getHeight = function () {
    return this.height;
};

Map2d.prototype.setDragging = function (unit) {
    this.dragging = unit;
    this.trigger("changeDragging", unit);
};

Map2d.prototype.findPath = function (source, targets, filter) {
    var vertical = [Iso.DOWN_RIGHT, Iso.UP_LEFT, Iso.UP_RIGHT, Iso.DOWN_LEFT];
    var horizontal = [Iso.UP_RIGHT, Iso.DOWN_LEFT, Iso.DOWN_RIGHT, Iso.UP_LEFT];

    filter = filter || function (x, y) {
        return this.isGround(x, y) && !this.getFog(x, y) && !this.getUnit(x, y);
    }.bind(this);

    var steps = [{ x: source.x, y: source.y, vertical: true }];

    var visited = {};
    visited[source.x + source.y * this.getWidth()] = true;

    targets = cleverapps.toArray(targets).reduce(function (targets, cell) {
        targets[cell.x + cell.y * this.getWidth()] = true;
        return targets;
    }.bind(this), {});

    for (var i = 0; i < steps.length; ++i) {
        var step = steps[i];

        if (targets[step.x + step.y * this.getWidth()]) {
            break;
        }

        var dirs = step.vertical ? vertical : horizontal;

        for (var j = 0; j < dirs.length; ++j) {
            var dir = dirs[j];
            var tx = step.x + dir.x;
            var ty = step.y + dir.y;

            if (!visited[tx + ty * this.getWidth()]) {
                visited[tx + ty * this.getWidth()] = true;

                if (filter(tx, ty)) {
                    steps.push({
                        x: tx,
                        y: ty,
                        vertical: dir.x === 0,
                        prevStep: step
                    });
                }
            }
        }
    }

    var path = [];

    if (i === steps.length) {
        return path;
    }

    while (step) {
        path.push(cc.p(step.x, step.y));
        step = step.prevStep;
    }
    return path.reverse();
};

Map2d.prototype.getGrowFrameData = function (x, y) {
    return this.growFrameData[y] && this.growFrameData[y][x];
};

Map2d.prototype.highlightUnit = function (unit, f, options) {
    options = options || {};
    f = f || function () {};
    var unitView = unit.onGetView();
    var mapView = this.onDiscoverMapView();

    if (!mapView.highlighter && unitView) {
        mapView.runAction(new cc.Sequence(
            new cc.HighlightUnit(unit, options || { spotlight: true }),
            new cc.DelayTime(0.2),
            new cc.CallFunc(f)
        ));
    } else {
        f();
    }
};

Map2d.prototype.unhighlightUnit = function (f) {
    f = f || function () {};
    this.onDiscoverMapView().runAction(new cc.Sequence(
        new cc.UnhighlightUnit(),
        new cc.CallFunc(f)
    ));
};

Map2d.prototype.setGrowFrameData = function (x, y, data) {
    if (cleverapps.config.editorMode) {
        return;
    }

    if (!this.growFrameData[y]) {
        this.growFrameData[y] = {};
    }
    this.growFrameData[y][x] = data;
};

Map2d.prototype.getBorderType = function (x, y) {
    return this.borderTypes[y] && this.borderTypes[y][x];
};

Map2d.prototype.setBorderType = function (x, y, border) {
    if (cleverapps.config.editorMode) {
        return;
    }

    if (!this.borderTypes[y]) {
        this.borderTypes[y] = {};
    }
    this.borderTypes[y][x] = border;
};

Map2d.prototype.loadUnit = function (x, y, data) {
    if (data.code === "intact") {
        var fakeUnit = this.fogs.getFakeUnit(x, y);
        if (fakeUnit && !fakeUnit.head) {
            data.code = fakeUnit.code;
            data.stage = fakeUnit.stage;
        } else if (cleverapps.config.debugMode) {
            cleverapps.throwAsync("can't restore intact unit " + x + " " + y);
            return;
        } else {
            return;
        }
    }

    this.currentLoadingUnitPosition = { x: x, y: y };

    var unit = new Unit(data, x, y);
    unit.setPosition(x, y);
    this.add(Map2d.LAYER_UNITS, x, y, unit);
    this.saveData.units[Map2d.GetPositionKey(x, y)] = data;
    this.onAddUnit(x, y, unit);
    return unit;
};

Map2d.prototype.findLargestGroup = function (units) {
    var used = {};
    var largestArea = [];

    units.forEach(function (unit) {
        if (used[Unit.GetPositionKey(unit)]) {
            return;
        }

        var area = this.bfs(unit.x, unit.y, this.compareEqual.bind(this, unit));
        area.forEach(function (cell) {
            used[Unit.GetPositionKey(cell)] = true;
        });

        if (area.length > largestArea.length) {
            largestArea = area;
        }
    }, this);

    return largestArea.map(function (cell) {
        return this.getUnit(cell.x, cell.y);
    }, this);
};

Map2d.prototype.isUnitClick = function (unit) {
    return unit === this.getUnit(unit.x, unit.y);
};

Map2d.prototype.isFogClick = function (fog) {
    return fog === this.getFog(fog.x, fog.y);
};

Map2d.prototype.onDebugCellClick = function (cell) {
    if (cleverapps.config.debugMode) {
        console.log(cell);

        var unit = this.getUnit(cell.x, cell.y);
        if (unit) {
            console.log(unit.getConsoleInfo());
        }

        var fog = this.getFog(cell.x, cell.y);
        if (fog && fog.fogBlock) {
            console.log(fog.fogBlock.getConsoleInfo());
        }
    }
};

Map2d.prototype.createUnitFromOrangery = function (template, cell) {
    var fog = this.getFog(cell.x, cell.y);
    if (fog) {
        return;
    }

    var unit = this.getUnit(cell.x, cell.y);
    if (template.eraser && unit) {
        unit.debugRemove();
        return;
    }

    if (!template.eraser && !unit) {
        if (Unit.CreateDebug(template, cell)) {
            cleverapps.audio.playSound(bundles.merge.urls.spawn_landing_effect);
        }
    }
};

Map2d.prototype.isCloseToMapLeft = function (cell) {
    var centerCell = this.getScreenCenterCell();
    return Map2dInnerView.IsoToScreen(cell.x, cell.y).x < Map2dInnerView.IsoToScreen(centerCell.x, centerCell.y).x;
};

Object.keys(Iso).forEach(function (key) {
    Iso[key].direction = true;
    Iso[key].key = key;
});

Map2d.GetPositionKey = function (x, y) {
    if (y === undefined) {
        y = x.y;
        x = x.x;
    }
    return x + "_" + y;
};

Map2d.ParsePositionKey = function (key) {
    var parts = key.split("_");
    return {
        x: parseInt(parts[0]),
        y: parseInt(parts[1])
    };
};

Map2d.getTilesTexture = function (skin) {
    return "tiles_" + skin;
};

Map2d.getUnitsTexture = function (skin) {
    return "units_" + skin;
};

Map2d.RADIUS_SMALL = 3;
Map2d.RADIUS_BIG = 5;

Map2d.MAXSIZE = 80;

Map2d.VISIBLE_BOX = {
    width: 0.5,
    height: 0.5
};

Map2d.TILES_VISIBLE_RECT_FRAME = cc.rect(-1, -2, 2, 4);

Map2d.WATER_BORDER = 10;

Map2d.TERRAIN_EMPTY = ".";
Map2d.TERRAIN_GREEN = "g";

Map2d.TERRAINS = {
    g: "green",
    i: "ice",
    p: "purple",
    s: "sand",
    b: "blue",
    f: "fort",
    y: "yellow",
    m: "default",
    r: "garage",
    t: "tavern"

};

Map2d.TERRAIN_BUNDLES = {};

Map2d.AVAILABLE_TILES = [];
Map2d.AVAILABLE_UNITS = [];

Map2d.BORDERS = false;

if (cleverapps.config.type === "merge") {
    Map2d.BORDERS = true;
}

Object.keys(bundles).forEach(function (name) {
    if (name.match(/^tiles_[^_]+$/)) {
        Map2d.AVAILABLE_TILES.push(name);
    }

    if (name.match(/^units_[^_]+$/)) {
        Map2d.AVAILABLE_UNITS.push(name);
    }
});

Object.keys(Map2d.TERRAINS).forEach(function (code) {
    Map2d.AVAILABLE_TILES.forEach(function (tilesTexture) {
        var terrain = Map2d.TERRAINS[code];

        var bundle = bundles[tilesTexture + "_" + terrain];
        if (!bundle) {
            return;
        }

        Map2d.TERRAIN_BUNDLES[tilesTexture + "_" + terrain] = bundle;

        Object.keys(bundle.frames).forEach(function (frameId) {
            var baseId = frameId.replace("_" + terrain, "");

            if (bundle.frames[frameId] && !bundle.frames[baseId]) {
                bundle.frames[baseId] = bundle.frames[frameId];
            }
        });
    });
});

Map2d.InsertDefaults = function (map, defaults) {
    defaults.forEach(function (item) {
        if (item.code === "unknown" || !Families[item.code] || !Families[item.code].units.length) {
            console.error("Trying to insert non-existent unit '" + item.code + "_" + item.stage + "'");
            item.code = "unknown";
            item.stage = 0;
        }

        item = Fogs.ReadMapUnit(item);
        if (!item || map.getFog(item.x, item.y)) {
            return;
        }

        var unit = new Unit(item);

        var x = item.x;
        var y = item.y;

        unit.setPosition(x, y);
        map.add(Map2d.LAYER_UNITS, x, y, unit);
        unit.destructor();
    });
};

Map2d.LOAD_UNITS_COMPARATOR = function () {
    var getTypeIndex = function (unit) {
        var familyUnits = Families[unit.code] && Families[unit.code].units || [];
        var index = 0;

        if (familyUnits[unit.stage] && familyUnits[unit.stage].heroitem) {
            index = unit.stage + 1;

            if (index === familyUnits.length) {
                index += 1000;
            }
        }

        return index;
    };

    return function (unit1, unit2) {
        var typeIndex1 = getTypeIndex(unit1);
        var typeIndex2 = getTypeIndex(unit2);

        return (typeIndex2 - typeIndex1) || unit1.code.localeCompare(unit2.code) || (unit2.stage - unit1.stage);
    };
};

Map2d.mapEvent = function (type, options) {
    cleverapps.eventBus.trigger("mapEvent", type, options);
};

Map2d.MERGE = "merge";
Map2d.SPAWN = "spawn";
Map2d.HARVEST = "harvest";
Map2d.OPENFOG = "openfog";
Map2d.OPENDRAGON = "opendragon";
Map2d.START_MINING = "startmining";
Map2d.COMPLETE_MINING = "completemining";
Map2d.MINE = "mine";
Map2d.START_BUILDING = "startbuilding";
Map2d.BUILD = "build";
Map2d.SPEED_UP = "speedup";
Map2d.PRIZE_HARVESTED = "prizeharvested";
Map2d.BUY_FREE_UNIT = "buyfreeunit";
Map2d.CLICK_UNIT = "clickunit";
Map2d.OPENCHEST = "openchest";
Map2d.ENTER_UNITS_SHOP = "enterunitsshop";
Map2d.MISSION_TREE_CLOSED = "missiontreeclosed";
Map2d.RUDOLF_COMPLETED = "rudolfcompleted";
Map2d.DRAG = "drag";
Map2d.FIELD_HARVEST = "field_harvest";
Map2d.FIELD_PLANT = "field_plant";
Map2d.COMPLETE_ORDER = "complete_order";
Map2d.FINISH_QUEST = "finish_quest";
Map2d.OPEN_UNIT = "open_unit";
Map2d.COLLECT_PIXEL = "collect_pixel";
Map2d.USE_UP_INSTANT_WORKER = "use_up_instant_worker";
Map2d.FEED_HERO = "feed_hero";
Map2d.REFILL = "refill";
Map2d.FACTORY_SPEEDUP = "factory_speedup";
Map2d.BEFORE_EXCHANGE = "before_exchange";
Map2d.EXCHANGE = "exchange";
Map2d.FORCE = "force";
Map2d.CLOSE_WINDOWS = "close_winodws";
Map2d.RECIPE_READY = "recipe_ready";
Map2d.RECIPE_NEW = "recipe_new";

Map2d.FOGS_UNITS_ONE_LAYER = true;

if (cleverapps.config.name === "wordsoup") {
    Map2d.FOGS_UNITS_ONE_LAYER = false;
}
