/**
 * Created by Andrey Popov on 12/1/20.
 */

var Chat = function () {
    this.messages = [];

    cleverapps.EventEmitter.call(this);

    this.editingMode = false;
    this.closing = false;
    this.hasUnread = false;
    this.wasUnreadMessages = false;
    this.activeDialogueId = undefined;
    this.categoryTag = undefined;

    this.baseChatUrl = "/chat";
    // test new CRM locally
    // this.baseChatUrl = "http://localhost:3205/" + cleverapps.config.name + "/chat";

    this.loadSave();

    cleverapps.timeouts.setInterval(this.checkReload.bind(this), Chat.CHECK_RELOAD_INTERVAL);

    connector.platform.on("userIdChanged", function () {
        this.load();
    }.bind(this));

    this.load();
};

Chat.prototype = Object.create(cleverapps.EventEmitter.prototype);
Chat.prototype.constructor = Chat;

Chat.IsAvailable = function () {
    if (cleverapps.config.wysiwygMode) {
        return false;
    }

    if (cleverapps.chat.hasUnread) {
        return true;
    }

    if (connector.platform.oneOf(connector.MOBAGE, connector.SP_MOBAGE, connector.GDCOM, connector.COOLMATHGAMES, connector.DIGITAL, connector.DIGITALWEB, connector.YOUTUBE, connector.GAMESNACK)) {
        return false;
    }

    if (cleverapps.paymentsHistory.classify() > 0) {
        return true;
    }

    var conditions = {};
    if (cleverapps.config.type === "merge") {
        var startLevel = connector.platform.oneOf(connector.INSTANT) ? 6 : 9;
        if (Game.currentGame && cleverapps.gameLevel.getLevel() < startLevel) {
            return false;
        }
    } else {
        conditions.level = 1;
    }
    conditions.registered = "3 days";

    return levels.user.checkAvailable(conditions);
};

Chat.prototype.loadSave = function () {
    var info = cleverapps.dataLoader.load(DataLoader.TYPES.CHAT) || {};

    this.processed = info.processed || {};
};

Chat.prototype.storeSave = function () {
    cleverapps.dataLoader.save(DataLoader.TYPES.CHAT, {
        processed: this.processed
    });
};

Chat.prototype.load = function (callback, errorCallback) {
    this.lastLoad = Date.now();

    cleverapps.RestClient.get(
        this.baseChatUrl + "/messages/" + encodeURIComponent(connector.platform.getUserID()),
        {},
        function (messages) {
            this.processMessages(Array.isArray(messages) ? messages : []);

            MenuControlButton.updateAttention();

            if (callback) {
                callback();
            }
        }.bind(this),

        function (error) {
            MenuControlButton.updateAttention();
            
            if (errorCallback) {
                errorCallback(error);
            } else {
                this.printConnectionError(error);
            }
        }.bind(this)
    );
};

Chat.prototype.processMessages = function (messages) {
    var serverMessages = messages.map(ChatMessage.fromData);

    this.messages = [];
    this.hasUnread = false;

    var dateStr = undefined;
    var changeConsoleStreamMode = undefined;
    var dialogueId = undefined;

    serverMessages.forEach(function (message) {
        if (dialogueId && message.dialogueId !== dialogueId) {
            this.messages.push(ChatMessage.createSeparator(dialogueId));
        }
        dialogueId = message.dialogueId;

        var messageDate;
        try {
            messageDate = new Date(message.postDate).toLocaleDateString(cleverapps.settings.language || "en-US", { year: "numeric", month: "long", day: "numeric" });
        } catch (e) {
            messageDate = new Date(message.postDate).toDateString();
        }

        if (messageDate !== dateStr) {
            dateStr = messageDate;
            this.messages.push(ChatMessage.createServiceMessage(messageDate));
        }

        if (message.isResponse === 1 && message.readByUser === 0 || message.hasGift() && !message.isGiftTaken() && message.isApplicable()) {
            this.hasUnread = true;
            this.wasUnreadMessages = true;
        }

        if (message.hasGift() && this.processed[message.id]) {
            if (message.isGiftTaken()) {
                delete this.processed[message.id];
                this.storeSave();
            } else {
                cleverapps.RestClient.post(this.baseChatUrl + "/acceptGift", { id: message.id });
            }
        }

        if (message.isTurningOnConsoleStream()) {
            changeConsoleStreamMode = true;
            this.messages.push(ChatMessage.createServiceMessage("ChatConsoleStreamSwitchOn", message.id));
        } else if (message.isTurningOffConsoleStream()) {
            changeConsoleStreamMode = false;
            this.messages.push(ChatMessage.createServiceMessage("ChatConsoleStreamSwitchOff", message.id));
        } else {
            this.messages.push(message);
        }
    }.bind(this));

    if (changeConsoleStreamMode !== undefined && changeConsoleStreamMode) {
        cleverapps.consoleStream.enable(this.postMessage.bind(this, "Disable console stream", "stream_off", true));
    } else if (changeConsoleStreamMode !== undefined && !changeConsoleStreamMode) {
        cleverapps.consoleStream.disable();
    }

    var showCategories = false;

    if (this.messages.length === 0) {
        this.messages.push(ChatMessage.createServiceMessage("ChatGreetingMessage"));
        showCategories = !this.wasUnreadMessages;
    } else if (this.getLastMessage() && ["resolved", "no answer needed"].indexOf(this.getLastMessage().status) !== -1) {
        this.messages.push(ChatMessage.createSeparator(dialogueId));
        showCategories = true;
    } else {
        this.activeDialogueId = dialogueId;
    }

    if (showCategories) {
        this.messages.push(ChatMessage.createCategoryButtons());
    }

    if (!this.closing) {
        this.trigger("removeMessagesByType", ChatMessage.MESSAGE_TYPE.TEMPORARY);
        this.trigger("messagesUpdated");
    }
};

Chat.prototype.postMessage = function (message, categoryTag, silent) {
    var tmpMessage = ChatMessage.createTemporaryMessage(message);
    this.messages.push(tmpMessage);
    if (!silent) {
        this.trigger("messagesUpdated");
    }

    var playerName = cleverapps.user.getPlayerInfo().name;
    if (playerName === Messages.get("Friends.You")) {
        playerName = Messages.get("LeadersWindow.player");
    }

    var payerLevel = cleverapps.paymentsHistory.classify();
    if (payerLevel <= cleverapps.PaymentsHistory.BRACKET_NONE && cleverapps.paymentsHistory.isPayer()) {
        payerLevel = cleverapps.PaymentsHistory.BRACKET_WEAK;
    }

    var level = cleverapps.user.getHumanReadableNumber();
    if (cleverapps.user.isPassedAll()) {
        var chosen = cleverapps.user.getRandomLevel();
        level = cleverapps.humanReadableNumber(chosen.episodeNo, chosen.levelNo);
    }

    cleverapps.RestClient.post(
        this.baseChatUrl + "/message",
        {
            message: message,
            userid: connector.platform.getUserID(),
            isresponse: 0,
            payerlevel: payerLevel,
            name: playerName,
            language: cleverapps.settings.language,
            source: connector.info.source,
            categoryTag: categoryTag || this.categoryTag,
            level: level,
            version: cleverapps.config.version
        },
        function () {
            this.categoryTag = undefined;
            this.trigger("updatePlaceholder", undefined);

            this.load(function () {
                this.trigger("removeMessagesByType", ChatMessage.MESSAGE_TYPE.CATEGORIES);
            }.bind(this));
        }.bind(this),
        function () {
            var index = this.messages.indexOf(tmpMessage);
            if (index !== -1) {
                this.messages.splice(index, 1);
            }

            if (!silent) {
                this.trigger("removeMessagesByType", ChatMessage.MESSAGE_TYPE.TEMPORARY);
                this.trigger("returnMessageBack", message);
                this.printConnectionError();
            }
        }.bind(this)
    );
};

Chat.prototype.selectCategory = function (categoryTag, placeholder) {
    if (this.categoryTag === categoryTag) {
        this.categoryTag = undefined;
        this.trigger("updatePlaceholder", "");
    } else {
        this.categoryTag = categoryTag;
        this.trigger("updatePlaceholder", placeholder);
    }

    this.trigger("messagesUpdated");
};

Chat.prototype.getLastMessage = function () {
    var chatMessages = cleverapps.chat.messages.filter(function (dialogueMessage) {
        return dialogueMessage.dialogueId && dialogueMessage.status;
    });

    return chatMessages[chatMessages.length - 1];
};

Chat.prototype.markAsRead = function () {
    cleverapps.RestClient.post(this.baseChatUrl + "/markAsRead", { userid: connector.platform.getUserID() });
};

Chat.prototype.acceptGift = function (message, take) {
    if (this.processed[message.id]) {
        this.load();
        return;
    }

    var useRewardWindow = false;

    if (take) {
        if (message.progress) {
            cleverapps.user.level = parseInt(message.progress.level);
            cleverapps.user.episode = parseInt(message.progress.episode);
            cleverapps.user.save();

            if (cleverapps.meta.getType() === Meta.HOSE) {
                // todo fix after HoseAdapter will be removed
                cleverapps.meta.updateInfo({});
            }
        } else if (message.restoreProgressId) {
            cleverapps.synchronizer.getProgress(message.restoreProgressId);
        } else if (message.presents) {
            useRewardWindow = true;
            var reward = cleverapps.clone(message.presents, true);
            delete reward.expedition;
            cleverapps.chatPresents = {
                reward: reward,
                callback: function () {
                    cleverapps.chat.processed[message.id] = true;
                    cleverapps.chat.storeSave();
                    cleverapps.RestClient.post(this.baseChatUrl + "/acceptGift", { id: message.id }, function () {
                        cleverapps.chat.load();
                    });
                }.bind(this)
            };
            this.closing = true;

            var page = cleverapps.travelBook.getPageById(message.presents.expedition);
            if (page) {
                cleverapps.focusManager.display({
                    focus: "closeScene",
                    action: function (f) {
                        page.gotoExpedition(f);
                    }
                });
            } else {
                cleverapps.scenes.getRunningScene().closeAction();
            }
        } else if (message.metha) {
            cleverapps.meta.processChatMessage(message);
        } else if (message.harvested) {
            var harvested = new Harvested(Meta.SLOT_MAIN);
            Object.keys(message.harvested).forEach(function (code) {
                if (UnitsLibrary.getInstance("main").listAvailableByType("fruit").indexOf(code) !== -1) {
                    harvested.add(code, message.harvested[code]);
                    cleverapps.user.incProgressCompare();
                }
            });
        }

        cleverapps.audio.playSound(bundles.main.urls.shop_buy_effect);
    }

    if (!useRewardWindow) {
        this.processed[message.id] = true;
        this.storeSave();
        cleverapps.RestClient.post(this.baseChatUrl + "/acceptGift", { id: message.id }, function () {
            cleverapps.chat.load();
        });
    }
};

Chat.prototype.printConnectionError = function () {
    this.messages.push(ChatMessage.createServiceMessage(Messages.get("RestartWindow.AnErrorOccured"), "error_" + Date.now()));
    this.trigger("messagesUpdated");
};

Chat.prototype.checkReload = function () {
    if (!Chat.IsAvailable()) {
        return;
    }

    if (!cleverapps.environment.hasScene([cleverapps.Environment.SCENE_MAIN, cleverapps.Environment.SCENE_CHAT])) {
        return;
    }

    var reloadInterval = cleverapps.environment.isChatScene() ? Chat.CHAT_RELOAD_INTERVAL : Chat.RELOAD_INTERVAL;
    if (this.lastLoad + reloadInterval > Date.now()) {
        return;
    }

    this.load();
};

Chat.prototype.giveRewards = function () {
    var unit = cleverapps.config.type === "merge" && cleverapps.chatPresents.reward.unit;
    if (unit) {
        var left = unit.amount - Map2d.currentMap.countEmptySlots();
        if (left > 0) {
            delete cleverapps.chatPresents;
            cleverapps.centerHint.createTextHint("Spawn.nospace", { left: left });
            return;
        }

        if (!this.canAcceptUnit(unit)) {
            delete cleverapps.chatPresents;
            cleverapps.centerHint.createTextHint("RestartWindow.OnError");
            return;
        }

        if (unit.code === "landmark") {
            var data = Landmarks.getLandmarkData(unit.stage);
            if (data) {
                unit.targetExpedition = data.targetExpedition;
            }
        }
    }

    cleverapps.focusManager.display({
        focus: "chatPresentsWindow",
        action: function (f) {
            new RewardWindow(cleverapps.chatPresents.reward, { event: cleverapps.EVENTS.EARN.OTHER });
            cleverapps.focusManager.onceNoWindowsListener = f;

            cleverapps.chatPresents.callback();
            delete cleverapps.chatPresents;
        }
    });
};

Chat.prototype.canAcceptUnit = function (unit) {
    if (cleverapps.travelBook.isExpedition() && !Families[unit.code].expeditions.includes("main")) {
        return true;
    }
    return cleverapps.unitsLibrary.isOpened(unit) || Families[unit.code].units[unit.stage].climbable || ["landmark"].includes(unit.code);
};

Chat.CHECK_RELOAD_INTERVAL = cleverapps.parseInterval("30 seconds");
Chat.CHAT_RELOAD_INTERVAL = cleverapps.parseInterval("1 minute");
Chat.RELOAD_INTERVAL = cleverapps.parseInterval("15 minutes");

Chat.CATEGORIES = [{
    message: "ChatCategory.ProblemWithPayment",
    tag: "payment",
    placeholder: "ChatCategory.ProblemWithPaymentPlaceholder"
}, {
    message: "ChatCategory.QuestionAboutTheRules",
    tag: "FAQ",
    placeholder: "ChatCategory.QuestionAboutTheRulesPlaceholder"
}, {
    message: "ChatCategory.ReportABag",
    tag: "bug",
    placeholder: "ChatCategory.ReportABagPlaceholder"
}, {
    message: "ChatCategory.AdsProblem",
    tag: "no ads",
    placeholder: "ChatCategory.AdsProblemPlaceholder"
}, {
    message: "ChatCategory.Suggestions",
    tag: "suggestion",
    placeholder: "ChatCategory.SuggestionsPlaceholder"
}, {
    message: "ChatCategory.OtherQuestions",
    tag: "other",
    placeholder: "ChatCategory.OtherQuestionsPlaceholder"
}];
