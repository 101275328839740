/**
 * Created by slava on 9/8/17
 */

var FieldBoosters = function () {
    cleverapps.EventEmitter.call(this);
    this.active = undefined;

    this.list = cleverapps.boosters.listBoosters();

    this.list.forEach(function (booster) {
        booster.onExecuteListener = function (targetCell) {
            this.onExecute(targetCell);
        }.bind(this);
    }, this);

    cleverapps.windows.on("openWindow", function () {
        this.setActive(undefined);
    }.bind(this), this);

    this.onSetActiveListener = function () {};
    this.onExecuteListener = function () {};
    this.onGetBoosterView = function () {};
};

FieldBoosters.prototype = Object.create(cleverapps.EventEmitter.prototype);
FieldBoosters.prototype.constructor = FieldBoosters;

FieldBoosters.prototype.stop = function () {
    runCleaners(this);

    this.list.forEach(function (booster) {
        booster.stop();
    });

    this.setActive(undefined);
};

FieldBoosters.prototype.onTouchBegan = function (booster) {
    if (Game.currentGame.counter.isActive()) {
        return;
    }

    if (this.active === booster) {
        this.setActive(undefined);
    } else if (booster.canExecute()) {
        this.setActive(booster);
    } else if (booster.isAdsAvailable()) {
        cleverapps.rewardedAdsManager.playRewarded(booster.limit, function () {
            cleverapps.adsLimits.watch(booster.limit);
            cleverapps.boosters.add(booster.id, 1);
            this.setActive(booster);
        }.bind(this));
    } else if (booster.buy()) {
        this.setActive(booster);
    }

    return true;
};

FieldBoosters.prototype.onTouchMoved = function (touch) {
    var cell = Game.currentGame.field.onGetView().getTouchedCell(touch);
    this.moveThrottled(cell);
};

FieldBoosters.prototype.moveThrottled = cleverapps.timeredThrottle(300, function (cell) {
    if (this.active) {
        this.active.onFieldTouchMoved(cell);
    }
});

FieldBoosters.prototype.onTouchEnded = function (touch) {
    if (this.active) {
        var cell = Game.currentGame.field.onGetView().getTouchedCell(touch);
        if (cell && this.active.isAllowedTarget(cell)) {
            this.active.onFieldTouchEnded(cell);
        } else {
            this.setActive(undefined);
        }
    }
};

FieldBoosters.prototype.onTouchOut = function (booster) {
    if (Game.currentGame.counter.isActive()) {
        return;
    }

    if (this.active === booster) {
        this.setActive(undefined);
    }
};

FieldBoosters.prototype.amountAvailable = function () {
    var amount = 0;
    this.list.forEach(function (booster) {
        if (!booster.isDisabled()) {
            amount++;
        }
    });
    return amount;
};

FieldBoosters.prototype.listAll = function () {
    return this.list;
};

FieldBoosters.prototype.onExecute = function (targetCell) {
    this.setActive(undefined);
    this.onExecuteListener(targetCell);
    Game.currentGame.availableMove.turnOff();
};

FieldBoosters.prototype.setActive = function (booster) {
    if (this.active === booster) {
        return;
    }

    if (this.active) {
        Game.currentGame.availableMove.turnOn();
        this.active.deactivate();
    }

    this.active = booster;

    if (this.active) {
        Game.currentGame.availableMove.turnOff();
        Game.currentGame.field.removeSelection();
        this.active.onActivateListener();
    }

    this.onSetActiveListener();
};
