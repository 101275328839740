/**
 * Created by Aleksandr on 06.04.2023
 */

var InterstitialAction = function (f) {
    if (this.game.outcome === GameBase.OUTCOME_GAVEUP && !cleverapps.config.lives) {
        f();
        return;
    }
    cleverapps.rewardedAdsManager.showInterstitial(AdsLimits.TYPES.INTERSTITIAL_OUTCOME, f);
};

InterstitialAction.checkLastFocus = function () {
    var focus = cleverapps.focusManager.lastFocus;
    if (!focus) {
        return false;
    }

    return focus.startsWith("missionCompleted")
        || (InterstitialAction.ALLOWED_FOCUSES[cleverapps.config.type] || []).indexOf(focus) !== -1;
};

InterstitialAction.showOnLowMonetization = function () {
    if (!cleverapps.flags.lowMonetization || cleverapps.adsLimits.state(AdsLimits.TYPES.INTERSTITIAL_INACTIVE) !== AdsLimits.STATE_READY) {
        return;
    }
    cleverapps.focusManager.display({
        focus: "showInterstitialLowMonet",
        action: function (f) {
            cleverapps.rewardedAdsManager.showInterstitial(AdsLimits.TYPES.INTERSTITIAL_INACTIVE, f);
        }
    });
};

InterstitialAction.ALLOWED_FOCUSES = {
    merge: ["onLevelUp", "FinishQuest", "ExitExpeditionIconClicked",
        "openChestWindow", "updateBuildPassUnit", "GiveRuinReward",
        "ConfirmSpeedUpWindowLock", "StartUnitDialogue", "StartUnitGreeting"]
};