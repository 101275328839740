/**
 * Created by mac on 1/27/21
 */

var NoMetaLocation = function (locationId) {
    BaseLocation.call(this, locationId);
};

NoMetaLocation.prototype = Object.create(BaseLocation.prototype);
NoMetaLocation.prototype.constructor = NoMetaLocation;

NoMetaLocation.prototype.load = function () {

};

NoMetaLocation.prototype.getInfo = function () {

};
