/**
 * Created by slava on 24.03.17.
 */

var CleverappsWindow = BaseWindow.extend({
    onWindowLoaded: function (options) {
        options = options || {};
        this.params = options;

        if (options.closeButtonDelay === true) {
            options.closeButtonDelay = 1.5;
        }
        this.params.closeButtonManual = options.closeButtonManual;
        this.params.closeButtonDelay = options.closeButtonDelay || 0;

        var styles = cleverapps.styles.Window;
        if (options.styles) {
            styles = cleverapps.overrideStyles(styles, options.styles, true);
        }
        this.styles = styles;

        this.name = options.name || options.title || "window";

        this.content = options.content;
        this.withBg = !options.noBackground && !styles.noBackground;

        this.buttons = this.createWindowButtons();

        if (options.shareId && Share.isAvailable()) {
            this.shareCheckBox = new ShareCheckBox(options.shareId, this);
        }

        if (options.closeButton !== false && styles.closeButton !== false) {
            this.closeButton = this.createCloseButton();
        }

        if (options.loginOrInvite) {
            this.loginOrInvite = cleverapps.FBLoginOrInvite();
        }

        if (this.withBg) {
            this.createWindowWithBg();
        } else {
            this.createWindowWithoutBg();
        }

        this.debugId = this.name;

        this.windowSize = this.window.getContentSize();

        if (this.params.title && this.withBg) {
            this.windowTitle = this.createWindowTitle();
            this.window.addChild(this.windowTitle);
        }

        if (options.help && !styles.HelpButton.lovesTitle) {
            this.helpButton = this.createHelpButton(options.help);
            this.window.addChild(this.helpButton);
        }

        if (options.homeButton) {
            this.homeButton = this.createHomeButton();
            this.addChild(this.homeButton);
        }

        if (this.closeButton instanceof BandButton) {
            this.addChild(this.closeButton);
        } else if (this.closeButton instanceof CloseButton) {
            this.window.addChild(this.closeButton);
        }

        var decorStyles = cleverapps.skins.getSlot("windowDecors", {
            decors: styles.decors,
            window: this,
            level: options.level
        });
        if (decorStyles) {
            this.decors = new Decors(this, decorStyles);
        }

        if (options.closeButton !== false || options.closeByBackButton) {
            this.closeByBackButton = this.createListener(function () {
                if (cleverapps.focusManager.checkEventNode(this)) {
                    this.close();
                }
            }.bind(this));
        }

        if (options.fireworks) {
            this.fireworks = new Fireworks(this.window, options.fireworks);
            this.fireworks.setPositionRound(this.width / 2, this.height / 2);
            this.fireworks.setLocalZOrder(options.fireworks.zOrder || 0);
            this.addChild(this.fireworks);
        }

        if (options.offerWindowButton) {
            options.offerWindowButton.positioningStartOffset = this.window.width / 2;
            this.windowOffer = new WindowOffer(this, options.offerWindowButton);
        }

        var person = this.getPerson && this.getPerson();
        if (person) {
            this.persons = new WindowPersons(person, this);
        }

        this.closeAnimationCallbacks = [];

        cleverapps.audio.playSound(options.openSound || bundles.main.urls.window_effect);
    },

    createWindowButtons: function () {
        var items = [];

        if (this.params.button) {
            this.params.button.onClicked = this.params.button.onPressed || this.close.bind(this);
            items.push(new BottomButton(this.params.button));
        }

        if (this.params.buttons) {
            items = items.concat(this.params.buttons);
        }

        if (items.length) {
            return new Buttons(items);
        }
    },

    createWindowTitle: function () {
        return new WindowTitle(this, this.params.title, this.styles.HelpButton.lovesTitle && this.params.help);
    },

    createWindowWithoutBg: function () {
        var tapToContinueItems = [];

        if (this.buttons) {
            tapToContinueItems.push(this.buttons);
        }

        if (this.closeButton instanceof TapToContinue) {
            tapToContinueItems.push(this.closeButton);
        }

        if (tapToContinueItems.length) {
            this.tapToContinue = new cleverapps.Layout(tapToContinueItems, {
                direction: cleverapps.UI.VERTICAL,
                margin: this.styles.Footer.margin,
                padding: this.styles.TapToContinue.padding
            });
            this.tapToContinue.setLocalZOrder(2);
            this.tapToContinue.setCascadeOpacityEnabledRecursively(true);
            this.addChild(this.tapToContinue);
        }

        var windowItems = [this.content];

        if (this.params.title) {
            this.windowTitle = this.createWindowTitle();
            windowItems.unshift(this.windowTitle);
        }

        this.window = new cleverapps.Layout(windowItems, {
            direction: cleverapps.UI.VERTICAL,
            margin: this.styles.titleMargin
        });

        this.window.setLocalZOrder(1);

        this.wrapper = cleverapps.UI.wrapWithPadding(this.window);
        this.wrapper.avoidNode = "Window";
        this.wrapper.setLocalZOrder(1);
        this.addChild(this.wrapper);

        var footerItems = [];

        if (this.shareCheckBox) {
            this.shareCheckBox.setOpacity(180);
            footerItems.push(this.shareCheckBox);
        }

        if (this.loginOrInvite) {
            footerItems.push(this.loginOrInvite);
        }

        if (footerItems.length) {
            this.windowFooter = new cleverapps.Layout(footerItems, {
                direction: cleverapps.UI.VERTICAL,
                margin: this.styles.Footer.margin
            });
            this.windowFooter.setPositionRound(this.styles.Footer);
            this.windowFooter.setLocalZOrder(2);
            this.addChild(this.windowFooter);
        }
    },

    createWindowWithBg: function () {
        var windowItems = [this.content, this.shareCheckBox, this.buttons].filter(Boolean);

        this.window = new cleverapps.Layout(windowItems, {
            margin: this.styles.margin,
            direction: cleverapps.UI.VERTICAL,
            padding: !this.params.noPadding && this.styles.padding
        });
        this.window.setLocalZOrder(1);

        var minWidth = this.params.minWidth !== undefined ? this.params.minWidth : cleverapps.styles.Window.minWidth;
        var minHeight = this.params.minHeight !== undefined ? this.params.minHeight : cleverapps.styles.Window.minHeight;

        var diffX = minWidth - this.window.width;
        var diffY = minHeight - this.window.height;
        if (diffX > 0 || diffY > 0) {
            cleverapps.UI.wrapWithPadding(this.window, {
                x: diffX > 0 && diffX,
                y: diffY > 0 && diffY
            }, true);
        }

        this.bg = this.createBackground();
        this.window.addChild(this.bg);
        this.bg.setLocalZOrder(-1000);
        this.bg.setPositionRound(this.window.width / 2, this.window.height / 2);

        this.wrapper = cleverapps.UI.wrapWithPadding(this.window);
        this.wrapper.avoidNode = "Window";
        this.wrapper.setLocalZOrder(1);
        this.addChild(this.wrapper);

        if (this.params.foreground) {
            this.foreground = this.createForeground(this.params.foreground);
            this.window.addChild(this.foreground);
        }

        if (this.loginOrInvite) {
            this.windowFooter = this.loginOrInvite;
            this.windowFooter.setPositionRound(this.styles.Footer);
            this.windowFooter.setLocalZOrder(2);
            this.addChild(this.windowFooter);
        }
    },

    adjustWrapper: function () {
        var windowBox = this.window.getGlobalBoundingBox();

        var nodes = [
            this.windowTitle && this.withBg ? this.windowTitle : undefined,
            this.tabs && this.tabs.isVisible() ? this.tabs : undefined
        ].filter(Boolean);

        var unionBox = nodes.reduce(function (sum, node) {
            return cc.rectUnion(sum, node.getGlobalBoundingBox());
        }, windowBox);

        this.wrapper.setContentSize2(unionBox.width || 1, unionBox.height || 1);
        this.window.setPosition(
            this.window.width / 2 + windowBox.x - unionBox.x,
            this.window.height / 2 + windowBox.y - unionBox.y
        );
    },

    adjustTapToContinue: function () {
        if (!this.tapToContinue) {
            return;
        }

        var footerBox = this.windowFooter ? this.windowFooter.getBoundingBox() : undefined;
        var footerTop = footerBox ? footerBox.y + footerBox.height : 0;

        this.tapToContinue.setScale(1);
        this.tapToContinue.setPositionRound(this.wrapper.x, footerTop + this.tapToContinue.height / 2);
    },

    inflateWindow: function () {
        this._super();
        this.windowScale = 1;
        this.wrapper.setScale(1);
        this.wrapper.setPositionRound(this.width / 2, this.height / 2);

        if (this.windowTitle) {
            this.windowTitle.adjustPosition();
        }

        this.adjustWrapper();
        this.adjustTapToContinue();

        if (!this.params.noScaleAdjustment) {
            var sceneSize = cleverapps.resolution.getSceneSize();
            var lovesPosition = cc.p(sceneSize.width / 2, sceneSize.height / 2);
            if (this.windowTitle && !this.withBg) {
                lovesPosition.y += this.styles.titleMargin;
            }
            cleverapps.UI.inflateToBoundaries(this.wrapper, this.getOverlappingNodes(), {
                padding: this.getContentPadding(),
                lovesPosition: this.convertToWorldSpace(lovesPosition),
                maxScale: 1
            });
        }

        if (this.windowTitle) {
            this.windowTitle.adjustPosition();
        }

        if (this.persons) {
            this.persons.update();
        }

        this.windowScale = this.wrapper.getScale();
        this.wrapper.basePosition = this.wrapper.getPosition();

        // adjust window scale and position while film effect
        var scene = cleverapps.scenes.getRunningScene();
        var safePadding = cleverapps.resolution.getSafePadding();
        var position = scene.convertToNodeSpace(cc.p(scene.width / 2 + safePadding.left, scene.height / 2 + safePadding.bottom));
        var scale = 1 / scene.getScale();
        this.setScale(scale);
        this.setPosition(position.x, position.y);
    },

    isShowTitle: function () {
        return Boolean(this.windowTitle);
    },

    getOverlappingNodes: function () {
        var scene = cleverapps.scenes.getRunningScene();

        var withMenubar = cleverapps.focusManager.isControlEnabled([
            "MenuBarGoldItem", "MenuBarGameLevelItem", "MenuBarLivesItem",
            "MenuBarCoinsItem", "MenuBarWandsItem", "MenuBarWorkersItem"]);

        return [
            withMenubar ? scene.upMenuContainer : undefined,
            this.goldInfo,
            this.bottomLine,
            this.windowFooter,
            this.tapToContinue,
            this.windowOffer && this.windowOffer.getOverlapNode()
        ].filter(Boolean);
    },

    getContentPadding: function () {
        var sceneSize = cleverapps.resolution.getSceneSize();

        var padding = this.params.contentPadding;

        if (padding === cleverapps.UI.DOCK_RIGHT) {
            padding = { left: sceneSize.width / 2 };
        } else if (padding === cleverapps.UI.DOCK_LEFT) {
            padding = { right: sceneSize.width / 2 };
        } else if (padding === cleverapps.UI.DOCK_BOTTOM) {
            padding = { top: sceneSize.height / 2 };
        } else if (padding === cleverapps.UI.DOCK_TOP) {
            padding = { bottom: sceneSize.height / 2 };
        }

        return cc.paddingAddPadding(cc.padding(padding), this.getBackgroundPadding());
    },

    getBackgroundPadding: function () {
        var styles = this.styles || cleverapps.styles.Window;
        return cc.padding(styles.Background.padding);
    },

    setupChildren: function () {
        this._super();

        if (this.decors) {
            this.decors.updateAll();
        }

        if (this.fireworks) {
            this.fireworks.setPositionRound(this.width / 2, this.height / 2);
        }

        if (this.windowFooter) {
            this.windowFooter.setPositionRound(this.styles.Footer);
        }

        if (this.windowOffer) {
            this.windowOffer.update();
        }
    },

    createCloseButton: function () {
        var ConstructorClass = BandButton;
        var params = {
            onClicked: this.close.bind(this),
            styles: this.styles.CloseButton
        };

        if (!this.params.bandButton) {
            if (this.withBg) {
                ConstructorClass = CloseButton;
            } else if (!this.params.notCloseByTouchInShadow) {
                ConstructorClass = TapToContinue;
                params = {
                    text: this.params.tapToContinueText || "Window.TapToContinue"
                };
            }
        }

        return new ConstructorClass(params);
    },

    createHelpButton: function (callback) {
        var helpButtonStyles = this.styles.HelpButton;
        var helpButton = new cleverapps.UI.HelpButton({ callback: callback });
        helpButton.setPositionRound(helpButtonStyles);
        helpButton.setLocalZOrder(helpButtonStyles.zOrder);
        return helpButton;
    },

    createHomeButton: function () {
        var homeButton = new cleverapps.UI.Button({
            type: {
                button_png: bundles.controlbuttons.frames.back_button_png,
                button_on_png: bundles.controlbuttons.frames.back_button_on_png
            },
            onClicked: function () {
                var boatswain = Game.currentGame && Game.currentGame.boatswain;
                if (boatswain) {
                    boatswain.setIntent(Boatswain.RETURN_INTENT);
                }

                this.close();
            }.bind(this),
            content: new cc.Sprite(bundles.buttons_main.frames.home_icon)
        });
        homeButton.avoidNode = "HomeButton";
        homeButton.setupChildren = function () {
            homeButton.setPositionRound(this.styles.HomeButton.position);
        }.bind(this);
        homeButton.setupChildren();
        return homeButton;
    },

    onShow: function () {
        if (this.windowTitle && !this.params.customTitle) {
            this.windowTitle.show();
        }

        if (this.shareCheckBox) {
            this.shareCheckBox.updateState();
        }

        if (this.fireworks) {
            this.fireworks.start();
        }

        if (this.persons) {
            this.persons.showUp();
        }

        if (this.windowOffer) {
            this.windowOffer.show();
        }

        this.hideBeforeDelayedShowUp();
        if (this.params.closeButtonManual) {
            this.params.closeButtonManual = false; // only first time manual, after reopen - all is shown immediately
        } else {
            this.delayedShowUp();
        }

        if (this.params.showSound) {
            cleverapps.audio.playSound(this.params.showSound);
        }
    },

    hideBeforeDelayedShowUp: function () {
        if (this.homeButton) {
            this.homeButton.stopAllActions();
            this.homeButton.setCascadeOpacityEnabledRecursively(true);
            this.homeButton.setVisible(false);
            this.homeButton.setOpacity(0);
        }

        if (this.closeButton) {
            this.closeButton.hide(true);
        }

        if (this.windowFooter) {
            this.windowFooter.setCascadeOpacityEnabledRecursively(true);
            this.windowFooter.setVisible(false);
            this.windowFooter.setOpacity(0);
        }
    },

    delayedShowUp: function () {
        var delay = this.params.closeButtonDelay;

        if (this.homeButton) {
            this.homeButton.runAction(new cc.Sequence(
                new cc.DelayTime(delay),
                new cc.Show(),
                new cc.FadeIn(0.4).easing(cc.easeOut(1.2))
            ));
        }

        if (this.closeButton) {
            this.closeButton.runAction(new cc.Sequence(
                new cc.DelayTime(delay),
                new cc.CallFunc(function () {
                    this.closeButton.show();
                    this.enableTouchInShadow();
                }.bind(this))
            ));
        }

        if (this.windowFooter) {
            this.windowFooter.runAction(new cc.Sequence(
                new cc.DelayTime(delay + 0.5),
                new cc.Show(),
                new cc.FadeIn(0.4).easing(cc.easeOut(1.2))
            ));
        }
    },

    onHide: function () {
        if (this.fireworks) {
            this.fireworks.stop();
        }

        if (this.touchListener) {
            cc.eventManager.removeListener(this.touchListener);
            this.touchListener = undefined;
        }
    },

    createBackground: function () {
        var styles = this.styles.Background;

        var sprite = styles.image;

        var image = cleverapps.skins.getSlot("windowBg", {
            image: sprite,
            level: this.params.level
        });

        var bg = cleverapps.UI.createScale9Sprite(image, styles.scale9);

        bg.setContentSize2(this.window.getContentSize());
        if (styles.opacity) {
            bg.setOpacity(styles.opacity);
        }

        bg.setLocalZOrder(-1000);
        bg.setPositionRound(this.window.width / 2, this.window.height / 2);

        return bg;
    },

    createForeground: function (image) {
        this.foreground = cleverapps.UI.createScale9Sprite(image, cleverapps.UI.Scale9Rect.TwoPixelXY);

        if (cleverapps.config.debugMode) {
            this.foreground._debugExclude = true;
        }

        this.foreground.setAnchorPoint(0, 0);
        this.foreground.setLocalZOrder(1);

        var rect = cc.rect(0, 0, this.window.width, this.window.height);
        rect = cc.rectSubPadding(rect, cc.padding(this.styles.Foreground && this.styles.Foreground.padding));

        this.foreground.setPositionRound(rect.x, rect.y);
        this.foreground.setContentSize2(rect.width, rect.height);

        return this.foreground;
    },

    onTouchInShadow: function () {
        if (!this.params.notCloseByTouchInShadow) {
            cleverapps.audio.playSound(bundles.main.urls.click_effect);
            this.close();
        }
    },

    setTitle: function (title, toReplace) {
        if (this.windowTitle) {
            this.windowTitle.setTitle(title, toReplace);
        }
    },

    getMinimalDialogueRects: function () {
        var styles = cleverapps.styles.Window;
        var rects = [
            cc.rectSubPadding(this.window.getSceneBoundingBox(), cc.padding(styles.BoundingBoxPadding))
        ];
        if (this.windowTitle) {
            rects.push(cc.rectSubPadding(this.windowTitle.getSceneBoundingBox(), cc.padding(styles.BoundingBoxPadding.Title)));
        }
        return rects;
    },

    enableTouchInShadow: function () {
        cleverapps.UI.onClick(this, function (touch) {
            if (this.window && !(this.closeButton instanceof TapToContinue)) {
                var size = this.window.getContentSize();
                var rect = cc.rect(0, 0, size.width, size.height);

                if (cc.rectContainsPoint(rect, this.window.convertToNodeSpace(touch.getLocation()))
                    || cc.rectContainsPoint(rect, this.window.convertToNodeSpace(touch.getStartLocation()))) {
                    return;
                }
            }

            this.onTouchInShadow();
        }.bind(this), {
            interactiveScale: false
        });
    },

    popUpAnimation: function () {
        var animation = this.chooseAnimation();
        if (animation) {
            if (this.window) {
                this.window.stopAllActions();
            }
            animation.open.call(this, this.onPopUpAnimationFinished.bind(this));
        } else {
            this.onPopUpAnimationFinished();
        }
    },

    stop: function () {
        this._super();

        if (this.window) {
            this.window.stopAllActions();
        }
    },

    closeAnimation: function (callback) {
        cleverapps.audio.playSound(bundles.main.urls.close_window_effect || bundles.main.urls.window_effect);

        var animation = this.chooseAnimation();

        if (!animation || (cleverapps.windows.list.length > 1 && !this.styles.windowShowUpAnimation.force)) {
            callback();
        } else {
            animation.close.call(this, callback);
        }
    },

    chooseAnimation: function () {
        var styles = this.styles || cleverapps.styles.Window;
        if (styles.windowShowUpAnimation && (this.window || this instanceof ForceView)) {
            if (!this.bg && !styles.windowShowUpAnimation.force) {
                return WINDOW_ANIMATIONS.default;
            }
            return WINDOW_ANIMATIONS[styles.windowShowUpAnimation.name];
        }
    },

    closeDuration: function () {
        var styles = this.styles || cleverapps.styles.Window;
        return styles.closeAnimation.duration;
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    CLOSE_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    }
});

cleverapps.styles.Window = {
    shadow: true,
    margin: 30,
    padding: {
        x: 85,
        top: 160,
        bottom: 50
    },
    titleMargin: 30,

    HelpButton: {
        zOrder: 1,
        x: { align: "left", dx: 70 },
        y: { align: "top", dy: -45 }
    },
    BottomButton: {
        width: 200,
        height: 90
    },
    HomeButton: {
        position: {
            x: { align: "right", dx: -40 },
            y: { align: "top", dy: -15 }
        }
    },

    minHeight: 200,
    minWidth: 240,

    windowShowUpAnimation: {
        name: "default",
        force: false
    },

    Background: {
        image: bundles.windows.frames.window_bg_png,
        scale9: cleverapps.UI.Scale9Rect.TwoPixelXY,
        padding: {
            x: 15,
            y: 70
        }
    },

    closeAnimation: {
        duration: 200
    },

    Footer: {
        x: { align: "center" },
        y: { align: "bottom", dy: 60 },

        margin: 20
    },

    BoundingBoxPadding: {
        x: 30,
        y: 30,
        Title: {
            x: 30,
            y: 30
        }
    },

    TapToContinue: {
        padding: {
            bottom: 60
        }
    }
};
