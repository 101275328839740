/**
 * Created by r4zi4l on 13.05.2021
 */

var BuildPassWindow = CleverappsWindow.extend({
    onWindowLoaded: function (mission) {
        this.mission = mission;

        var components = [{
            name: "header",
            class: PassHeader,
            params: {
                bg_json: bundles.pass.jsons.bg_json,
                pack_json: bundles.pass.jsons.pack_json,
                decor_json: bundles.pass.jsons.decor_json,
                close: this.close.bind(this),
                passLogic: mission.logic
            }
        }, {
            name: "progress",
            class: PassProgress
        }, {
            name: "tickets",
            class: PassTickets
        }];

        this._super({
            name: "BuildPassWindow",
            title: "BuildPassWindow.title",
            content: new PassWindowContent({
                components: components,
                width: cleverapps.styles.BuildPassWindow.width,
                styles: cleverapps.styles.BuildPassWindow,
                passLogic: mission.logic
            }),
            help: function () {
                new GuideWindow(mission.rulesOptions);
            },
            foreground: bundles.passforeground.frames.window_foreground_png,
            contentPadding: cleverapps.styles.BuildPassWindow.contentPadding,
            closeButton: true,
            noPadding: true,
            styles: cleverapps.styles.BuildPassWindow.window
        });
    },

    onShow: function () {
        this._super();
        this.mission.logic.handleShowWindow();
    },

    listBundles: function () {
        return ["pass"];
    }
});

cleverapps.styles.BuildPassWindow = {
    width: 1816,
    height: 935,
    paddingX: 50,

    window: {
        Foreground: {
            padding: {
                top: 300
            }
        }
    },

    contentPadding: {
        right: 80
    },

    "progress": {
        x: { align: "right", dx: -26 },
        y: { align: "center", dy: -122 }
    },

    "header": {
        x: { align: "center", dx: -2 },
        y: { align: "top", dy: -30 }
    },

    "tickets": {
        x: { align: "left", dx: 24 },
        y: { align: "center", dy: -118 }
    }
};
