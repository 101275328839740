/**
 * Created by vladislav on 1/9/19
 */

var IceMakerCell = function (x, y) {
    BaseCell.call(this, x, y);
    this.movable = true;
    this.sticky = true;
    this.lives = 10;

    var game = Game.currentGame;
    if (!(game instanceof FakeGame) && game.bindings["beforeStartActions"].indexOf(IceMakerCell.onGameStart) === -1) {
        game.on("beforeStartActions", IceMakerCell.onGameStart);
        game.counter.registerStage(212, IceMakerCell.process);
    }

    this.onChangeLivesListener = function () {};
};

IceMakerCell.prototype = Object.create(BaseCell.prototype);
IceMakerCell.prototype.constructor = IceMakerCell;

IceMakerCell.prototype.bundleId = function () {
    return ["ice_maker", "ice"];
};

IceMakerCell.prototype.getGoalId = function () {
    return IceMakerCell.GOAL_ID;
};

IceMakerCell.prototype.editorComponents = function () {
    return [BaseCellComponent, ColorBombCellComponent];
};

IceMakerCell.prototype.load = function (data) {
    if (data.length > 1) {
        var digits = data.splice(1).join("");
        this.lives = parseInt(digits);
    }
};

IceMakerCell.prototype.save = function () {
    var digits = "" + this.lives;
    return IceMakerCell.CODES.concat(digits.split(""));
};

IceMakerCell.prototype.getViewClass = function () {
    return IceMakerCellView;
};

IceMakerCell.prototype.boom = function () {
    this.hurt();
};

IceMakerCell.prototype.hurt = function () {
    this.skipProcess = true;
    BaseCell.prototype.hurt.call(this);
};

IceMakerCell.prototype.explode = function (params) {
    if (Game.currentGame.goals.hasType(IceMakerCell.GOAL_ID)) {
        this.explodeViaCollect(params);
    } else {
        BaseCell.prototype.explode.call(this, params);
    }
};

IceMakerCell.prototype.process = function () {
    var field = Game.currentGame.field, cx = this.x, cy = this.y;
    var freeNeighbors = [], honeyNeighbors = [];
    BaseCell.neighbors.forEach(function (pos) {
        var x = cx + pos.x, y = cy + pos.y;
        if (field.isCellInField(y, x)
        && !IceMakerCell.USED_CELLS["" + y + "_" + x]
        && field.cells[y][x].movable
        && !field.cells[y][x].controlDisabled
        && !this.isProhibited(field.cells[y][x])) {
            if (Game.currentGame.field.barriers.canMove(cy, cx, y, x) === false) {
                return;
            }
            if ((field.floor[y][x] === undefined) || (field.floor[y][x] instanceof TransporterTile)) {
                freeNeighbors.push(field.cells[y][x]);
            } else if (field.floor[y][x] instanceof IceTile) {
                honeyNeighbors.push(field.cells[y][x]);
            }
        }
    }.bind(this));

    var chosen;
    if (freeNeighbors.length > 0) {
        chosen = cleverapps.Random.choose(freeNeighbors);
    } else if (honeyNeighbors.length > 0) {
        chosen = cleverapps.Random.choose(honeyNeighbors);
    }
    if (chosen) {
        IceMakerCell.USED_CELLS["" + chosen.y + "_" + chosen.x] = true;
        this.moveTo(chosen);
    }
};

IceMakerCell.prototype.moveDuration = function () {
    return 0.2;
};

IceMakerCell.prototype.createIceDuration = function () {
    return 0.8;
};

IceMakerCell.prototype.cellMoveDelay = function () {
    return 0.1;
};

IceMakerCell.prototype.moveTo = function (target) {
    var startX = this.x, startY = this.y, targetX = target.x, targetY = target.y;

    var shouldCreateIce = Game.currentGame.field.floor[startY][startX] === undefined;
    this.x = targetX;
    this.y = targetY;
    this.animate(BaseCell.ANIMATION_ICE_MAKER_MOVE, {
        iceAnimation: shouldCreateIce,
        callback: function () {
            this.onFinishMoveListener({ x: this.x, y: this.y }, {});
            if (shouldCreateIce) {
                BaseCell.prototype.hurt.call(this);
            }
        }.bind(this),
        dir: { x: targetX - startX, y: targetY - startY }
    });

    var totalDuration = this.moveDuration() + (shouldCreateIce ? this.createIceDuration() : 0);
    Game.currentGame.counter.setTimeout(function () {
        target.move({ x: startX, y: startY }, { moveInterval: 0.1 });
    }, (this.cellMoveDelay() + (shouldCreateIce ? this.createIceDuration() : 0)) * 1000);

    Game.currentGame.counter.setTimeout(function () {
        this.move({ x: targetX, y: targetY }, { moveInterval: this.moveDuration() });

        if (shouldCreateIce) {
            this.createIceTile({ x: startX, y: startY });
        }
    }.bind(this), (shouldCreateIce ? this.createIceDuration() : 0) * 1000);

    Game.currentGame.counter.setTimeout(function () {
        Game.currentGame.field.cells[startY][startX] = target;
        Game.currentGame.field.cells[targetY][targetX] = this;
    }.bind(this), totalDuration * 1000);
};

IceMakerCell.prototype.createIceTile = function (pos) {
    var iceTile = new IceTile(pos.x, pos.y);
    Game.currentGame.field.addTile(iceTile);
    iceTile.onCreateNew();

    Game.currentGame.counter.setTimeout(function () {
    }, iceTile.showUpDuration() * 1000);

    if (IceMakerCell.requiredIceAmount < 1) {
        Game.currentGame.goalCounter.setTimeout(function () {
            if (Game.currentGame) {
                Game.currentGame.goals.incTarget(IceTile.GOAL_ID, 1);
            }
        }, iceTile.showUpDuration() * 1000);
    } else {
        IceMakerCell.requiredIceAmount--;
    }
};

IceMakerCell.prototype.isProhibited = function (cell) {
    for (var i = 0; i < IceMakerCell.PROHIBITED_CELLS.length; i++) {
        if (cell instanceof IceMakerCell.PROHIBITED_CELLS[i]) {
            return true;
        }
    }
    return false;
};

IceMakerCell.PROHIBITED_CELLS = [ColorCookieManCell, CookieManCell, IceMakerCell];

IceMakerCell.process = function () {
    if (!Game.currentGame || !Game.currentGame.iceMakerCellCanProcess) {
        return;
    }

    Game.currentGame.iceMakerCellCanProcess = false;
    IceMakerCell.USED_CELLS = {};
    for (var i = 0; i < Field.SIZE; i++) {
        for (var j = 0; j < Field.SIZE; j++) {
            if (Game.currentGame.field.cells[i][j] && Game.currentGame.field.cells[i][j].constructor === IceMakerCell) {
                if (Game.currentGame.field.cells[i][j].skipProcess) {
                    Game.currentGame.field.cells[i][j].skipProcess = false;
                } else {
                    Game.currentGame.field.cells[i][j].process();
                }
            }
        }
    }
};

IceMakerCell.onGameStart = function () {
    var goal = Game.currentGame && Game.currentGame.goals.findTargetFor(IceTile.GOAL_ID);
    if (goal) {
        var amount = 0;
        for (var row = 0; row < Field.SIZE; row++) {
            for (var col = 0; col < Field.SIZE; col++) {
                if (Game.currentGame.field.floor[row][col] && Game.currentGame.field.floor[row][col] instanceof IceTile) {
                    amount++;
                }
            }
        }

        IceMakerCell.requiredIceAmount = goal.target - amount;
    }
};

IceMakerCell.GOAL_ID = "ice_maker";