/**
 * Created by ivan on 11.01.18.
 */

var GingerHouseCell = function (x, y) {
    BaseCell.call(this, x, y);

    var bigComponent = this.bigComponent = this.addComponent(BigComponent, { cellClass: GingerHouseCell });

    if (this.isForGoal) {
        return;
    }

    this.collectedParts = [];
    this.animatingParts = 0;
    this.hurtable = false;
    this.putInPoolAvailable = true;

    var game = Game.currentGame;

    if (bigComponent.isHead) {
        if (!game.HOUSES) {
            game.HOUSES = [];
        }
        game.HOUSES.push(this);

        if (!(game instanceof FakeGame) && game.bindings["beforeStartActions"].indexOf(GingerHouseCell.onGameStart) === -1) {
            game.on("beforeStartActions", GingerHouseCell.onGameStart);
        }
    }

    this.onCollectPartListener = function () {};
};

GingerHouseCell.prototype = Object.create(BaseCell.prototype);
GingerHouseCell.prototype.constructor = GingerHouseCell;

GingerHouseCell.find = function (partType) {
    if (Game.currentGame) {
        if (Game.currentGame.HOUSES) {
            for (var i = 0; i < Game.currentGame.HOUSES.length; i++) {
                if (Game.currentGame.HOUSES[i].collectedParts.indexOf(partType) === -1) {
                    return Game.currentGame.HOUSES[i];
                }
            }
        }
    }

    return undefined;
};

GingerHouseCell.onGameStart = function () {
    if (!Game.currentGame) {
        return;
    }
    GingerHouseCell.createdParts = [0, 0, 0, 0];
    for (var x = 0; x < Field.SIZE; x++) {
        for (var y = 0; y < Field.SIZE; y++) {
            var cell = Game.currentGame.field.cells[y][x];
            if (cell === undefined) {
                continue;
            }
            while (cell.innerCell) {
                cell = cell.innerCell;
            }
            if (cell instanceof GingerHousePartCell) {
                GingerHouseCell.createdParts[cell.type]++;
            }
            if (cell instanceof GingerHouseCell && cell.findComponent(BigComponent).isHead) {
                cell.collectedParts.forEach(function (part) {
                    GingerHouseCell.createdParts[part]++;
                }, cell);
            }
        }
    }
};

GingerHouseCell.prototype.load = function (data) {
    if (!this.bigComponent.isHead) {
        return;
    }
    this.collectedParts = data.slice(1, data.length).map(function (item) {
        return parseInt(item);
    });
};

GingerHouseCell.prototype.bundleId = function () {
    return "gingerbread_house";
};

GingerHouseCell.prototype.save = function () {
    if (this.bigComponent.isHead) {
        return GingerHouseCell.CODES.concat(this.collectedParts);
    }
    return GingerHouseCell.CODES;
};

GingerHouseCell.prototype.getViewClass = function () {
    return GingerHouseCellView;
};

GingerHouseCell.prototype.getGoalId = function () {
    return GingerHouseCell.GOAL_ID;
};

GingerHouseCell.prototype.collectPart = function (partCell) {
    this.collectedParts.push(partCell.type);
    this.animatingParts++;
    var flyDuration = this.getFlyDuration(partCell);
    this.onCollectPartListener(partCell, flyDuration);

    Game.currentGame.counter.setTimeout(function () {
        if (!Game.currentGame) {
            return;
        }
        this.animatingParts--;
        if (this.collectedParts.length === 4 && this.animatingParts === 0) {
            this.explode();
        }

        var notCreatedParts = [];
        for (var type = 0; type < 4; type++) {
            if (GingerHouseCell.createdParts[type] < Game.currentGame.HOUSES.length) {
                notCreatedParts.push(type);
            }
        }

        if (notCreatedParts.length) {
            var typeForCreate = cleverapps.Random.choose(notCreatedParts);
            GingerHouseCell.createdParts[typeForCreate]++;
            Game.currentGame.field.addNextCell(GingerHousePartCell.getCodeByType(typeForCreate));
        }
    }.bind(this), flyDuration * 1000);
};

GingerHouseCell.prototype.getFlyDuration = function (object) {
    var ver = object.y - (this.y + 0.5);
    var hor = object.x - (this.x + 0.5);
    var flyDuration = Math.sqrt(ver * ver + hor * hor + 2) / 6;
    return flyDuration + Goals.UP_DURATION + Goals.WAIT_DURATION;
};

GingerHouseCell.GOAL_ID = "gingerhouse_cell";
