/**
 * Created by vladislav on 19/12/2022
 */

cc.MoveInArc = cc.ActionInterval.extend({
    ctor: function (duration, center, angle) {
        cc.ActionInterval.prototype.ctor.call(this, duration);

        this._center = center;
        this._toAngle = angle;
    },

    clone: function () {
        cc.log("MoveInArc.clone(): clone hasn't been supported.");
        return null;
    },

    reverse: function () {
        cc.log("MoveInArc.reverse(): reverse hasn't been supported.");
        return null;
    },

    startWithTarget: function (target) {
        cc.ActionInterval.prototype.startWithTarget.call(this, target);

        this._startPosition = target.getPosition();
        this._startAngle = Math.atan2(this._startPosition.y - this._center.y, this._startPosition.x - this._center.x);
        this._radius = cc.pDistance(this._startPosition, this._center);
    },

    update: function (time) {
        time = this._computeEaseTime(time);
        var angle = this._startAngle + (this._toAngle - this._startAngle) * time;

        this.target.x = Math.cos(angle) * this._radius + this._center.x;
        this.target.y = Math.sin(angle) * this._radius + this._center.y;
    }
});