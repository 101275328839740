/**
 * Created by Andrey Popov on 9/9/22.
 */

var ClipPreviewBarView = cc.Node.extend({
    ctor: function (clipPreviewBar) {
        this._super();

        this.clipPreviewBar = clipPreviewBar;

        this.clipPreviewBar.onShow = this.show.bind(this);
        this.clipPreviewBar.onHide = this.hide.bind(this);

        this.duration = 0;
        this.clipPreviewBar.onResetTimer = function () {
            this.duration = 0;
        }.bind(this);
        this.clipPreviewBar.onStopTimer = function () {
            this.duration = -1;
        }.bind(this);

        this.createContent();
    },

    createContent: function () {
        var snapshotBar = document.getElementById("snapshotbar");
        if (snapshotBar) {
            snapshotBar.parentNode.removeChild(snapshotBar);
        }

        var content = this.content = this.createWindow(this.hide.bind(this));
        content.id = "ClipPreviewBarView";

        this.timerButton = this.createButton({
            icon: "",
            onClick: function () {
                this.showPopup(
                    [0.25, 0.5, 0.75, 1, 1.25, 1.5, 2],
                    function (speed) {
                        return speed.toString();
                    },
                    cc.director.getScheduler().setTimeScale.bind(cc.director.getScheduler()),
                    function (speed) {
                        return speed === cc.director.getScheduler().getTimeScale();
                    }
                );
            }.bind(this),
            class: "tool-button-timer"
        });
        content.appendChild(this.timerButton);
        this.createTimer();

        content.appendChild(this.createButton({
            icon: bundles.wysiwyg.urls.download_icon,
            onClick: this.showDownloadPopup.bind(this)
        }));

        content.appendChild(this.createButton({
            icon: bundles.wysiwyg.urls.replay_icon,
            onClick: this.clipPreviewBar.replay.bind(this.clipPreviewBar)
        }));

        content.appendChild(this.createButton({
            icon: bundles.wysiwyg.urls.edit_icon,
            onClick: function () {
                this.clipPreviewBar.edit();
                this.hide();
            }.bind(this)
        }));

        var selectors = document.createElement("div");
        selectors.classList.add("tool-selectors");

        selectors.appendChild(this.createButton({
            text: Messages.getLocalized(this.clipPreviewBar.prefabPlayer.options.language),
            onClick: function () {
                this.showPopup(
                    this.clipPreviewBar.clipProps.languages,
                    Messages.getLocalized,
                    this.clipPreviewBar.changeLanguage.bind(this.clipPreviewBar),
                    function (lang) {
                        return lang === this.clipPreviewBar.prefabPlayer.options.language;
                    }.bind(this)
                );
            }.bind(this)
        }));

        selectors.appendChild(this.createButton({
            text: this.clipPreviewBar.prefabPlayer.options.resolutionName.split("_")[1],
            onClick: function () {
                this.showPopup(
                    this.clipPreviewBar.clipProps.resolutions,
                    function (res) {
                        return res.split("_")[1];
                    },
                    this.clipPreviewBar.changeResolution.bind(this.clipPreviewBar),
                    function (res) {
                        return Wysiwyg.VIDEO_SIZES[res] && res === this.clipPreviewBar.prefabPlayer.options.resolutionName;
                    }.bind(this)
                );
            }.bind(this)
        }));

        var oldBarView = document.getElementById(content.id);
        if (oldBarView) {
            oldBarView.parentNode.removeChild(oldBarView);
        }

        content.appendChild(selectors);
        document.body.appendChild(content);
    },

    createWindow: function (onClose) {
        var window = document.createElement("div");
        window.classList.add("tool-window");

        var close = document.createElement("span");
        close.classList.add("tool-window-close");
        close.onclick = onClose || this.hideShownWindow.bind(this);
        close.innerText = "\u2715";
        window.appendChild(close);

        return window;
    },

    hideShownWindow: function () {
        if (this.shownWindow) {
            if (this.shownWindow.parentNode) {
                this.shownWindow.parentNode.removeChild(this.shownWindow);
            }
            delete this.shownWindow;
        }
    },

    createButton: function (options) {
        var button = document.createElement("div");
        button.classList.add("tool-button");

        if (options.icon) {
            button.classList.add("tool-button-icon");
            var img = document.createElement("img");
            img.src = options.icon.url || options.icon;
            button.appendChild(img);
        }
        if (options.text) {
            var textContent = document.createTextNode(options.text);
            if (options.isInactive) {
                button.classList.add("tool-button-disabled");
            } else {
                button.classList.add("tool-button-text");
            }
            button.appendChild(textContent);
        }

        if (!options.isInactive) {
            button.onclick = options.onClick;
        }
        if (options.class) {
            button.classList.add(options.class);
        }

        return button;
    },

    show: function () {
        this.content.style.display = "block";
    },

    hide: function () {
        this.content.style.display = "none";
    },

    createTimer: function () {
        this.runAction(new cc.RepeatForever(new cc.Sequence(
            new cc.DelayTime(0.1),
            new cc.CallFunc(function () {
                if (this.duration === -1) {
                    return;
                }
                this.duration++;
                var timerValue = parseInt(this.duration);
                timerValue += "";
                timerValue = timerValue.substring(0, timerValue.length - 1) + "." + timerValue.substring(timerValue.length - 1);
                while (timerValue.length < 4) {
                    timerValue = "0" + timerValue;
                }
                this.timerButton.innerText = timerValue;
            }.bind(this))
        )));
    },

    showPopup: function (items, textFormatter, onClick, isInactive) {
        if (items.length === 0) {
            return;
        }

        this.hideShownWindow();

        var popup = this.shownWindow = this.createWindow();
        popup.classList.add("tool-window-popup");

        items.forEach(function (item) {
            var button = this.createButton({
                text: textFormatter(item),
                onClick: function () {
                    this.hideShownWindow();
                    onClick(item);
                    this.createContent();
                }.bind(this),
                isInactive: isInactive(item)
            });
            popup.appendChild(button);
        }.bind(this));
        document.body.appendChild(popup);
    },

    showDownloadPopup: function () {
        this.hideShownWindow();

        var languages = this.clipPreviewBar.clipProps.languages;
        var resolutions = this.clipPreviewBar.clipProps.resolutions.filter(function (resolution) {
            return Object.keys(Wysiwyg.VIDEO_SIZES).indexOf(resolution) !== -1;
        });

        var popup = this.shownWindow = this.createWindow();
        popup.classList.add("tool-window-popup");

        var fieldset = document.createElement("fieldset");
        fieldset.classList.add("tool-fieldset");
        var legend = document.createElement("legend");
        legend.innerText = "Selection";
        fieldset.appendChild(legend);
        var tableElement = document.createElement("table");

        var thead = document.createElement("thead");
        tableElement.appendChild(thead);
        var headtr = document.createElement("tr");
        thead.appendChild(headtr);
        var headth = document.createElement("th");
        var toggleInput = document.createElement("input");
        toggleInput.type = "checkbox";
        toggleInput.id = "toggleInput";
        var updateToggleInput = function () {
            var checkedCount = getCheckboxes("", true).length;
            if (checkedCount === 0) {
                toggleInput.checked = false;
                toggleInput.indeterminate = false;
            } else if (checkedCount === getCheckboxes().length) {
                toggleInput.checked = true;
                toggleInput.indeterminate = false;
            } else {
                toggleInput.checked = false;
                toggleInput.indeterminate = true;
            }
        };
        toggleInput.addEventListener("click", function () {
            getCheckboxes().forEach(function (checkbox) {
                checkbox.checked = toggleInput.checked;
            });
        });
        headth.appendChild(toggleInput);
        headtr.appendChild(headth);

        var tbody = document.createElement("tbody");
        tableElement.appendChild(tbody);

        resolutions.forEach(function (resolution) {
            var resolutionTh = document.createElement("th");
            resolutionTh.classList.add("tool-th");
            resolution.split("_")[1].split("x").forEach(function (dim, i) {
                var dimDiv = document.createElement("div");
                dimDiv.innerText = i === 1 ? "x" + dim : dim;
                resolutionTh.appendChild(dimDiv);
            });
            resolutionTh.addEventListener("click", function () {
                var checkboxes = getCheckboxes(resolution);
                var checkedCount = getCheckboxes(resolution, true).length;
                checkboxes.forEach(function (checkbox) {
                    checkbox.checked = checkedCount !== checkboxes.length;
                });

                updateToggleInput();
            });
            headtr.appendChild(resolutionTh);
        });

        languages.forEach(function (language) {
            var langTr = document.createElement("tr");

            var langTd = document.createElement("td");

            var langTdDiv = document.createElement("div");
            langTdDiv.innerText = language;
            langTd.appendChild(langTdDiv);
            langTr.appendChild(langTd);
            langTdDiv.addEventListener("click", function () {
                var checkboxes = getCheckboxes(language);
                var checkedCount = getCheckboxes(language, true).length;
                checkboxes.forEach(function (checkbox) {
                    checkbox.checked = checkedCount !== checkboxes.length;
                });

                updateToggleInput();
            });

            resolutions.forEach(function (resolution) {
                var resTd = document.createElement("td");

                var input = document.createElement("input");
                input.type = "checkbox";
                input.id = language + "%" + resolution;
                if (language === this.clipPreviewBar.prefabPlayer.options.language
                    && resolution === this.clipPreviewBar.prefabPlayer.options.resolutionName) {
                    input.checked = true;
                }
                input.addEventListener("click", updateToggleInput);
                resTd.appendChild(input);

                langTr.appendChild(resTd);
            }.bind(this));

            tbody.appendChild(langTr);
        }.bind(this));

        fieldset.appendChild(tableElement);
        popup.appendChild(fieldset);

        var button = this.createButton({
            text: "Download",
            onClick: function () {
                this.hideShownWindow();
                this.clipPreviewBar.download(getCheckboxes("", true).map(function (checkbox) {
                    return {
                        language: checkbox.id.split("%")[0],
                        resolution: checkbox.id.split("%")[1]
                    };
                }));
                this.createContent();
            }.bind(this)
        });
        button.classList.add("tool-download");
        popup.appendChild(button);

        document.body.appendChild(popup);

        var getCheckboxes = function (filter, onlyChecked) {
            var checkboxes = [].map.call(tableElement.querySelectorAll("input"), function (obj) {
                return obj;
            }).filter(function (checkbox) {
                return checkbox.id !== "toggleInput";
            });

            if (filter && filter.length > 0) {
                if (filter.length === 2) {
                    checkboxes = checkboxes.filter(function (checkbox) {
                        return checkbox.id.split("%")[0] === filter;
                    });
                } else {
                    checkboxes = checkboxes.filter(function (checkbox) {
                        return checkbox.id.split("%")[1] === filter;
                    });
                }
            }

            if (onlyChecked) {
                checkboxes = checkboxes.filter(function (checkbox) {
                    return checkbox.checked;
                });
            }

            return checkboxes;
        };

        updateToggleInput();
    }

});