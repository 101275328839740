/**
 * Created by olga on 23.10.2024
 */

var Meta = function () {
    cleverapps.EventEmitter.call(this);

    this.logic = this.createLogic();
    this.adapter = this.createAdapter();
};

Meta.prototype = Object.create(cleverapps.EventEmitter.prototype);
Meta.prototype.constructor = Meta;

Meta.prototype.createAdapter = function () {
    switch (this.getType()) {
        case Meta.HOSE:
            return new HoseAdapter();
        case Meta.SHORTMETA:
            return new ShortMetaAdapter();
        case Meta.SIMPLE:
            return new SimpleAdapter();
        case Meta.HOMEFIX:
            return new HomefixAdapter();
        case Meta.FARM:
            return new FarmAdapter();
        case Meta.MERGE:
            return new MergeMetaAdapter();
        default:
            return new DefaultAdapter();
    }
};

Meta.prototype.createLogic = function () {
    switch (this.getType()) {
        case Meta.HOSE:
            return new HoseLogic();
        case Meta.SIMPLE:
            return new SimpleLogic();
        case Meta.SHORTMETA:
            return new ShortMetaLogic();
        case Meta.FARM:
            return new FarmLogic();
        case Meta.HOMEFIX:
            return new HomefixLogic();
        case Meta.MERGE:
            return new MergeLogic();
        default:
            return new NoMetaLogic();
    }
};

Meta.prototype.load = function () {
    var needSave = this.adapter.load();

    this.resetAvailableLocations();

    this.switchLocation(this.logic.getMainLocation());

    if (needSave) {
        this.save();
    }
};

Meta.prototype.save = function (fromSever) {
    this.adapter.save(fromSever);
};

Meta.prototype.updateInfo = function (serverData) {
    this.location = undefined;

    this.adapter.updateInfo(serverData);

    this.resetAvailableLocations();

    this.switchLocation(this.logic.getMainLocation());
    this.save(true);

    if (cleverapps.highscore) {
        cleverapps.highscore.updateInfo(serverData);
        cleverapps.highscore.save(true);
    }
};

Meta.prototype.getInfo = function () {
    var data = this.adapter.getInfo();

    if (cleverapps.highscore) {
        Object.assign(data, cleverapps.highscore.getInfo());
    }

    return data;
};

Meta.prototype.loadLocation = function (locationId) {
    return this.adapter.loadLocation(locationId);
};

Meta.prototype.saveLocation = function (locationId, data) {
    this.adapter.saveLocation(locationId, data);
};

Meta.prototype.switchLocation = function (locationId) {
    var activeLocations = this.listActiveLocations();
    if (activeLocations.indexOf(locationId) === -1) {
        if (activeLocations.length === 0) {
            throw "no locations to switch";
        }

        locationId = activeLocations[activeLocations.length - 1];
    }

    if (!this.location || this.location.locationId !== locationId) {
        var LocationClass = this.logic.getLocationClass(locationId);
        this.location = new LocationClass(locationId);

        cleverapps.playSession.set(cleverapps.EVENTS.META_LOCATION, this.location.locationId);
    }
};

Meta.prototype.getType = function () {
    if (connector.info.source === "playable") {
        return Meta.NONE;
    }

    return cleverapps.config.meta;
};

Meta.prototype.listAvailableLocations = function () {
    if (!this.availableLocationsCache) {
        this.resetAvailableLocations();
    }

    return this.availableLocationsCache;
};

Meta.prototype.resetAvailableLocations = function () {
    this.availableLocationsCache = this.logic.listAvailableLocations();
};

Meta.prototype.listActiveLocations = function () {
    return this.logic.listActiveLocations();
};

Meta.prototype.getMainObject = function () {
    return this.location;
};

Meta.prototype.selectedLocationId = function () {
    return this.location.locationId;
};

Meta.prototype.gamePlayed = function (outcome, game) {
    if (outcome === GameBase.OUTCOME_VICTORY) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.METHA_LEVEL);
        cleverapps.playSession.set(cleverapps.EVENTS.METHA_LEVEL, true);
    }

    this.getMainObject().gamePlayed(outcome, game);
};

Meta.prototype.isCompleted = function () {
    return this.logic.isCompleted();
};

Meta.prototype.getLastLocationId = function () {
    var availableLocations = this.listAvailableLocations();
    return availableLocations[availableLocations.length - 1];
};

Meta.prototype.moveNextLocation = function () {
    this.logic.moveNextLocation();

    this.switchLocation(this.logic.getMainLocation());
    this.save();
};

Meta.prototype.wantsToPlay = function (f, level) {
    if (cleverapps.lives && cleverapps.lives.isEmpty()) {
        if (cleverapps.unlimitedLives && cleverapps.unlimitedLives.freeOfferIsAvailable()) {
            cleverapps.unlimitedLives.showFreeOffer(f);
        } else {
            new LivesShopWindow();
            cleverapps.focusManager.onceNoWindowsListener = f;
        }

        return;
    }

    var mainObject = this.getMainObject();

    if (this.getType() === Meta.FARM) {
        var building = level && level.building && level.building.canStillProvideQuestItems()
            ? level.building
            : mainObject.findBuildingForActiveItem() || cleverapps.Random.mathChoose(mainObject.listAvailableProvidesBuildings());

        level = MethaHelper.getCurrentLevel(building);
    } else {
        level = level || MethaHelper.getCurrentLevel();
    }

    if (cleverapps.knockoutGame) {
        cleverapps.knockoutGame.wantsToPlay(f, level);
    } else {
        MethaHelper.start(f, level);
    }
};

Meta.prototype.wantsToReplay = function (f, level) {
    MethaHelper.start(f, level);
};

Meta.prototype.reset = function () {
    this.location = undefined;

    this.logic.reset();
    this.adapter.reset();

    this.resetAvailableLocations();
};

Meta.prototype.getMainScene = function () {
    if (connector.info.source === "playable") {
        return PlayableAdsScene;
    }

    return this.logic.getMainScene(this.location.locationId);
};

Meta.prototype.processChatMessage = function (message) {
    this.logic.processChatMessage(message);
};

Meta.HOSE = "hose";
Meta.FARM = "farm";
Meta.HOMEFIX = "homefix";
Meta.SHORTMETA = "shortmeta";
Meta.SIMPLE = "simple";
Meta.FISHDOM = "fishdom";
Meta.MERGE = "merge";
Meta.NONE = "none";

Meta.SLOT_MAIN = "";
Meta.SLOTS = [Meta.SLOT_MAIN];

if (cleverapps.config.type === "merge") {
    Meta.EXPEDITION_SLOT1 = "1";
    Meta.EXPEDITION_SLOT2 = "2";
    Meta.EXPEDITION_SLOT3 = "3";
    Meta.EXPEDITION_SLOT4 = "4";

    Meta.SLOTS = [Meta.SLOT_MAIN, Meta.EXPEDITION_SLOT1, Meta.EXPEDITION_SLOT2, Meta.EXPEDITION_SLOT3, Meta.EXPEDITION_SLOT4];
}

if (cleverapps.config.meta === "homefix") {
    Meta.LOCATION_SLOT0 = "0";
    Meta.LOCATION_SLOT1 = "1";
    Meta.LOCATION_SLOT2 = "2";

    Meta.SLOTS = [Meta.SLOT_MAIN, Meta.LOCATION_SLOT0, Meta.LOCATION_SLOT1, Meta.LOCATION_SLOT2];
}
