/**
 * Created by andrey on 18.01.2021.
 */

var ShadowLayer = cc.Scale9Sprite.extend({
    ctor: function () {
        this._super(bundles.pixel.frames.pixel_png);
        this.setColor(cleverapps.styles.COLORS.BLACK);
        this.setAnchorPoint(0, 0);
        this.setOpacity(0);
        this.setVisible(false);
        this.show = false;

        this.updateSize();

        var scene = cleverapps.scenes.getRunningScene();
        scene.addChild(this);
    },

    subscribeToWindows: function () {
        cleverapps.windows.on("hideShadow", this.deleteShadow.bind(this), this);
        cleverapps.windows.on("showShadow", this.createShadow.bind(this), this);
        cleverapps.windows.on("moveShadowUp", this.moveUp.bind(this), this);
        cleverapps.windows.on("moveShadowDown", this.moveDown.bind(this), this);
    },

    updateSize: function () {
        var padding = cleverapps.styles.ShadowLayer.padding;
        var rect = cleverapps.resolution.getBgRect();
        this.setContentSize(rect.width + 2 * padding, rect.height + 2 * padding);
        this.setPositionRound(rect.x - padding, rect.y - padding);
    },

    createShadow: function (delayed) {
        if (this.show) {
            return;
        }

        this.show = true;
        this.stopAllActions();
        this.visible = true;

        this.updateSize();
        this.runAction(
            new cc.Sequence(
                new cc.DelayTime(delayed ? 1 : 0),
                new cc.FadeTo(0.2, 150)
            )
        );
    },

    deleteShadow: function () {
        if (!this.show) {
            return;
        }

        this.show = false;
        this.stopAllActions();

        this.runAction(
            new cc.Sequence(
                new cc.FadeTo(0.2, 0),
                new cc.Hide()
            )
        );
    },

    moveUp: function () {
        this.setLocalZOrder(BaseWindow.WINDOWS_ZORDER + 8);
    },

    moveDown: function () {
        this.setLocalZOrder(BaseWindow.WINDOWS_ZORDER - 3);
    }
});

cleverapps.styles.ShadowLayer = {
    padding: 10
};
