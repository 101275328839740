/**
 * Created by slava on 8/8/18
 */

var LeadersRow = RowView.extend({
    ctor: function (row, options) {
        this._super(row, Object.assign(options, {
            prizes: false
        }));
    }
});

cleverapps.styles.LeadersRow = {};