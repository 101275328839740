/**
 * Created by vladislav on 29.10.2021
 */

var SceneDebugger = function () {
    this.isTextsVisible = true;
    this.matchedNodes = [];
    this.hiddenNodes = [];

    cc.eventManager.addListener(cc.EventListener.create({
        event: cc.EventListener.TOUCH_ONE_BY_ONE,
        swallowTouches: true,
        processTouch: this.processTouch.bind(this),
        onTouchBegan: this.onTouchBegan.bind(this)
    }), -100);

    cc.eventManager.addListener(cc.EventListener.create({
        event: cc.EventListener.KEYBOARD,
        onKeyReleased: function (key) {
            if (key === cc.KEY.Delete || key === cc.KEY.backspace) {
                this.removeSelectedNode();
            }
        }.bind(this)
    }), 2);
};

SceneDebugger.prototype.toggleTexts = function () {
    if (this.isTextsVisible) {
        $(cc.LabelTTF).hide();
    } else {
        $(cc.LabelTTF).show();
    }

    this.isTextsVisible = !this.isTextsVisible;
};

SceneDebugger.prototype.removeSelectedNode = function () {
    if (this.selectedNode && this.selectedNode.onDeletePressed) {
        this.selectedNode.onDeletePressed();
    } else if (this.selectedNode && this.selectedNode.removeFromParent) {
        this.selectedNode.removeFromParent();
        this.selectedNode = undefined;
    }
};

SceneDebugger.prototype.toggleMatchedNodes = function () {
    var visible;

    if (this.matchedNodes.length) {
        visible = !this.matchedNodes[0].visible;
        this.matchedNodes.forEach(function (node) {
            if (this.hiddenNodes[node.__instanceId]) {
                delete this.hiddenNodes[node.__instanceId];
            }
            node.setVisible(visible);
            if (!visible) {
                this.hiddenNodes[node.__instanceId] = node;
            }
        }, this);
    } else if (this.selectedNode) {
        visible = !this.selectedNode.visible;
        if (visible) {
            delete this.hiddenNodes[this.selectedNode.__instanceId];
        } else {
            this.hiddenNodes[this.selectedNode.__instanceId] = this.selectedNode;
        }
        this.selectedNode.setVisible(visible);
    } else if (Object.keys(this.hiddenNodes).length) {
        Object.keys(this.hiddenNodes).forEach(function (instanceId) {
            this.hiddenNodes[instanceId].setVisible(true);
        }, this);
        this.hiddenNodes = {};
    }
};

SceneDebugger.prototype.processTouch = function (node, stop) {
    if (!node || !node.visible) {
        return;
    }
    if (typeof EditorScene !== "undefined" && node instanceof EditorScene) {
        return;
    }

    var excluded = this.excluded.some(function (nodeClass) {
        return node instanceof nodeClass;
    }) || node._hideInSceneDebugger;

    if (node.hasTouch(this.touch) && !node._debugExclude && !excluded) {
        this.matchedNode = node;
    }

    if (stop) {
        return;
    }

    var children = node.getChildren();
    var i = 0, child;

    for (; i < children.length; i++) {
        child = children[i];
        if (child && child.getLocalZOrder() < 0) {
            this.processTouch(child);
        } else {
            break;
        }
    }

    if (node.iterateVisibleTiles) {
        node.iterateVisibleTiles(function (tile) {
            if (tile instanceof cc.Node) {
                this.processTouch(tile, true);
            }
        }.bind(this));
    }

    for (; i < children.length; i++) {
        child = children[i];

        if (child) {
            this.processTouch(child);
        }
    }
};

SceneDebugger.prototype.onTouchBegan = function (touch) {
    var nodesToSkip = [];
    if (this.matchedNode && this.matchedNode.debugButtons) {
        nodesToSkip.push(this.matchedNode.debugButtons);
    }
    var scene = cleverapps.scenes.getRunningScene();
    if (scene.toolMenu) {
        nodesToSkip.push(scene.toolMenu, scene.toolMenu.opener);
    }
    var skip = false;
    nodesToSkip.forEach(function (node) {
        var location = node.convertTouchToNodeSpace(touch);
        if (cc.rectContainsPoint(cc.rect(0, 0, node.width, node.height), location)) {
            skip = true;
        }
    });

    if (skip || cleverapps.config.wysiwygMode) {
        return;
    }

    if (this.matchedNode && this.matchedNode.debugButtons) {
        var button = this.matchedNode.debugButtons;
        var location = button.convertTouchToNodeSpace(touch);
        if (cc.rectContainsPoint(cc.rect(0, 0, button.width, button.height), location)) {
            return;
        }
    }

    this.matchedNode = undefined;
    this.touch = touch;
    this.excluded = [SideBarView, cleverapps.UI.ScrollView, cleverapps.UI.ScrollBarContainer];
    if (typeof CenterHint !== "undefined") {
        this.excluded.push(CenterHint);
    }
    if (typeof KeypadView !== "undefined") {
        this.excluded.push(KeypadView);
    }
    if (typeof MergeTutorialView !== "undefined") {
        this.excluded.push(MergeTutorialView);
    }
    if (typeof CenterHintView !== "undefined") {
        this.excluded.push(CenterHintView);
    }
    if (typeof ExclamationViewBase !== "undefined") {
        this.excluded.push(ExclamationViewBase);
    }
    if (typeof AdsGame !== "undefined") {
        this.excluded.push(AdsGame);
    }
    if (typeof Prefab !== "undefined") {
        this.excluded.push(Prefab);
    }

    if (typeof CleverappsWindow !== "undefined") {
        this.excluded.push(CleverappsWindow);
    }

    if (typeof ShadowLayer !== "undefined") {
        this.excluded.push(ShadowLayer);
    }

    this.excluded.push(sp.SkeletonAnimation);

    if (cleverapps.keyboardController.isPressed(cc.KEY.ctrl) && this.selectNode()) {
        return true;
    }

    if (this.matchedNodes.length) {
        this.matchedNodes.forEach(function (node) {
            this.cleanup(node);
        }.bind(this));
        this.matchedNodes = [];
    }
    return false;
};

SceneDebugger.prototype.selectNode = function (node) {
    this.matchedNodes.forEach(function (node) {
        this.cleanup(node);
    }.bind(this));

    this.matchedNodes = [];
    if (node) {
        this.matchedNode = node;
    } else {
        this.processTouch(cleverapps.scenes.getRunningScene());
    }

    if (this.matchedNode) {
        if (cleverapps.config.name === "wordsoup" && this.matchedNode instanceof UnitView) {
            this.matchedNode.unit.highlight();
        }

        this.matchedNode.debugParents();
        this.matchedNode.debugBorder({ showPosition: true, showAttributes: true });
        this.matchedNodes.push(this.matchedNode);
        return true;
    }
};

SceneDebugger.prototype.cleanup = function (node) {
    if (cleverapps.config.name === "wordsoup" && node instanceof UnitView) {
        node.unit.unhighlight();
    }
    node.cleanupBorder();
};

SceneDebugger.prototype.createSpine = function (json) {
    var scene = cleverapps.scenes.getRunningScene();

    var spine = new cleverapps.Spine(json);
    spine.setPositionRound(scene.width / 2, scene.height / 2);
    scene.addChild(spine);

    spine.setVisible(true);
    spine.debugBorder();

    console.log("skins:", Object.keys(spine.data.skins));
    console.log("animations:", Object.keys(spine.data.animations));
};
