/**
 * Created by andrey on 26.02.19.
 */

var IslandView = cc.Node.extend({
    // eslint-disable-next-line no-unused-vars
    ctor: function (page, bundle) {
        this._super();

        this.pageNo = page.pageNo;
        this.bundle = bundle;

        this.page = page;

        page.onAnimateClose = this.createListener(this.animatePass.bind(this));
        page.onAnimateOpen = this.createListener(this.animateOpen.bind(this));
        page.onScroll = this.createListener(this.onScroll.bind(this));

        var animation = this.animation = this.islandAnimation();
        animation.setLocalZOrder(1);
        animation.setCascadeOpacityEnabled(true);
        this.addChild(animation);

        this.updateAnimation();

        this.addCharacters();

        this.setAnchorPoint(0.5, 0.5);

        this.updateSize();
        this.setupChildren();

        if (cleverapps.meta.selectedLocationId() === this.pageNo) {
            this.addHighlight();
            this.addProgress(true);

            this.label = new CurrentEpisodeLabel(this.pageNo);
            this.addChild(this.label);

            this.makeIslandClickable();
        } else {
            this.addStatus(true);
        }

        this.addDecoration();

        if (cleverapps.config.debugMode && cleverapps.flags.islandsInfoEnabled) {
            var islandCenteringArea = new cc.Node();
            islandCenteringArea.setContentSize2(500, 300);
            animation.addChild(islandCenteringArea);
            islandCenteringArea.setAnchorPoint(0.5, 0.5);
            islandCenteringArea.debugBorder();

            var text = cleverapps.UI.generateTTFText("Episode: " + this.pageNo, cleverapps.styles.FONTS.TEXT);
            animation.addChild(text);
        }

        this.scheduleUpdate();
    },

    onEnter: function () {
        this._super();

        if (this.parent.loading) {
            this.visible = false;
            this.runAction(new cc.Sequence(
                new cc.DelayTime(0.2),
                new cc.CallFunc(function () {
                    this.visible = true;
                }.bind(this))
            ));
        }
    },

    updateSize: function () {
        var styles = cleverapps.styles.IslandView;
        this.setContentSize2(styles.width, styles.height);
    },

    setupChildren: function () {
        var styles = cleverapps.styles.IslandView;
        var positions = styles.positions;
        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL && styles.positionsMobile) {
            positions = styles.positionsMobile;
        }
        this.setPositionRound(positions[this.pageNo % Object.keys(positions).length]);

        this.animation.setPositionRound(this.width / 2, this.height / 2);
    },

    islandAnimation: function () {
        return new cleverapps.Spine(this.bundle.jsons["island_" + this.pageNo + "_json"]);
    },

    makeIslandClickable: function () {
        if (!this.clickHandler) {
            this.clickHandler = cleverapps.UI.onClick(this, function () {
                var currentLevel = cleverapps.user.getCurrentLevel();
                var level = new Level(currentLevel.episodeNo, currentLevel.levelNo);
                level.onPressed();
            });
        }
    },

    addCharacters: function () {
        var styles = cleverapps.styles.IslandEnemies;
        if (!styles || !styles[this.pageNo]) {
            return;
        }

        if (cleverapps.meta.selectedLocationId() > this.pageNo) {
            this.addFriends();
        } else {
            this.addEnemies();
        }
    },

    addFriends: function (isBattle) {
        this.friends = this.createCharacters(true, isBattle);
        this.runCharactersAnimation(this.friends, isBattle ? "attack" : "idle");
    },

    addEnemies: function (isBattle) {
        this.enemies = this.createCharacters(false, isBattle);
        this.runCharactersAnimation(this.enemies, isBattle ? "attack" : "idle");
    },

    addHighlight: function () {
        if (this.islandHighlight) {
            return;
        }

        var islandHighlight = this.islandHighlight = new cleverapps.Spine(bundles.episodes_resources.jsons.island_highlight_json);
        islandHighlight.setAnimationAndIdleAfter("open", "animation");
        islandHighlight.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(islandHighlight);
    },

    removeHighlight: function () {
        if (this.islandHighlight) {
            this.islandHighlight.setAnimation(0, "close", false);
            this.islandHighlight.setCompleteListenerRemove();
        }
    },

    addStatus: function (silent) {
        if (this.statusBg) {
            return;
        }

        var styles = cleverapps.styles.IslandView.status;
        var statusBg = this.statusBg = new cc.Sprite(bundles.episodes_resources.frames.island_status_bg);
        statusBg.setLocalZOrder(2);
        statusBg.setPositionRound(styles.positions[this.pageNo % Object.keys(styles.positions).length]);
        this.animation.addChild(statusBg);

        var current = cleverapps.meta.selectedLocationId();
        var json = current >= this.pageNo ? "checkmark_json" : "padlock_json";
        var icon = new cleverapps.Spine(bundles.episodes_resources.jsons[json]);
        icon.setPositionRound(statusBg.width / 2, statusBg.height / 2 + styles.icon.dy);
        statusBg.addChild(icon);
        icon.setAnimation(0, "idle", true);

        statusBg.icon = icon;

        if (!silent) {
            statusBg.setOpacity(0);
            statusBg.runAction(new cc.FadeIn(1));
            icon.setAnimationAndIdleAfter("open", "idle");
        }
    },

    removeStatus: function () {
        if (this.statusBg) {
            this.statusBg.icon.setAnimation(0, "open", false);

            this.statusBg.runAction(new cc.Sequence(
                new cc.FadeOut(0.5),
                new cc.CallFunc(function () {
                    delete this.statusBg;
                }.bind(this))
            ));
        }
    },

    addProgress: function (silent) {
        if (this.progress) {
            return;
        }

        var progress = this.progress = new ProgressView();
        progress.setLocalZOrder(1);
        this.addChild(progress);

        if (!silent) {
            progress.showAnimation();
        }
    },

    removeProgress: function () {
        if (this.progress) {
            this.progress.hideAnimation();
            this.progress.runAction(new cc.Sequence(
                new cc.DelayTime(0.4),
                new cc.RemoveSelf(),
                new cc.CallFunc(function () {
                    delete this.progress;
                }.bind(this))
            ));
        }
    },

    runCharactersAnimation: function (characters, animation) {
        if (!characters) {
            return;
        }
        characters.forEach(function (character) {
            var runAnimationNow = function () {
                if (animation === "idle") {
                    var characterAnimation = function () {
                        character.inAnimation = false;
                        character.setCompleteListener();
                        character.setAnimation(0, character.idleAnimation, true);
                        character.runAction(new cc.Sequence(new cc.DelayTime(Math.random() * 20 + 3), new cc.CallFunc(function () {
                            character.inAnimation = true;
                            character.setAnimation(0, character.attackAnimation, false);
                            character.setCompleteListener(characterAnimation);
                        })));
                    };
                    characterAnimation();
                }
                if (animation === "attack") {
                    character.inAnimation = true;
                    character.setAnimation(0, character.attackAnimation, true);
                }
                if (animation === "die") {
                    character.stopAllActions();
                    character.setAnimation(0, character.dieAnimation, false);
                    character.setCompleteListener(function () {
                        character.setCompleteListener();
                        character.runAction(new cc.Sequence(new cc.DelayTime(0.5), new cc.ScaleTo(0.3, 0), new cc.RemoveSelf()));
                    });
                    cleverapps.audio.playSound(bundles.episodes_resources.urls.enemy_die);
                }
            };
            if (character.inAnimation) {
                character.setCompleteListener(runAnimationNow);
            } else {
                runAnimationNow();
            }
        });
    },

    createCharacters: function (isFriends, isBattle) {
        var styles = cleverapps.styles.IslandEnemies;
        var enemies = [];
        styles[this.pageNo].forEach(function (enemyStyle, id) {
            var delta = { x: 0, y: 0 };
            var spine = enemyStyle.enemy;
            var scale = styles.enemies.scale;
            var idleAnimation = styles.enemies.idleAnimation;
            var attackAnimation = styles.enemies.attackAnimation;
            var dieAnimation = styles.enemies.dieAnimation;
            var x = styles.enemies.x;
            var y = styles.enemies.y;
            if (isBattle) {
                delta = styles.enemies.delta;
            }
            if (isFriends) {
                var seed = this.pageNo * 1000 + id;
                var color = cleverapps.Random.choose(Heroes.AVAILABLE_HEROES, seed);
                color = color.toUpperCase();
                spine = cleverapps.skins.getSlot(color + "_hero_spine_json") || bundles.heroes.jsons[color + "_hero_spine_json"];
                scale = styles.friends.scale;
                idleAnimation = styles.friends.idleAnimation;
                attackAnimation = styles.friends.attackAnimation;
                x = styles.friends.x;
                y = styles.friends.y;
                if (isBattle) {
                    delta = styles.friends.delta;
                }
            }
            var enemy = new cleverapps.Spine(spine);
            enemy.setScale(scale || 1.0);
            enemy.setSkin("regular");

            if (enemyStyle.flipped && !isBattle) {
                enemy.setScaleX(-enemy.scaleX);
            }
            enemy.setPositionRound(enemyStyle.x + x + delta.x, enemyStyle.y + y + delta.y);
            // if (!spine.includes('goblin_5')) {
            enemy.setLocalZOrder(1);
            this.addChild(enemy);
            // }
            enemies.push(enemy);
            enemy.idleAnimation = idleAnimation;
            enemy.attackAnimation = attackAnimation;
            enemy.dieAnimation = dieAnimation;

            // Enemy debug information
            // var text = cleverapps.UI.generateTTFText("Episode: " + (this.pageNo + 1) + " x:" + enemyStyle.x +
            //     " y:" + enemyStyle.y, cleverapps.styles.FONTS.SMALL_WHITE_TEXT);
            // enemy.addChild(text);
        }, this);

        return enemies;
    },

    addDecoration: function () {
        if (!bundles.episodes_spines) {
            return;
        }
        var amountDecorations = 0;
        while (bundles.episodes_spines.jsons["island_decor_" + amountDecorations]) {
            amountDecorations++;
        }
        if (!amountDecorations) {
            return;
        }

        var styles = cleverapps.styles.IslandView;
        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL && styles.decorPositionsMobile) {
            styles = styles.decorPositionsMobile;
        } else {
            styles = styles.decorPositions;
        }
        if (styles.disabled) {
            return;
        }

        styles = styles[this.pageNo % Object.keys(styles).length];

        var decorId = this.pageNo % amountDecorations;
        var decor = new cleverapps.Spine(bundles.episodes_spines.jsons["island_decor_" + decorId]);
        this.addChild(decor);
        decor.setAnimation(0, "animation", true);

        decor.setPositionRound(this.width / 2 + styles.x, this.height / 2 + styles.y);
        if (styles.scaleX) {
            decor.setScaleX(styles.scaleX);
        }
    },

    updateAnimation: function (force) {
        var animation = force;
        if (!force) {
            animation = (cleverapps.meta.selectedLocationId() > this.pageNo
                || (cleverapps.meta.selectedLocationId() === this.pageNo && !cleverapps.meta.getMainObject().isCompleted())) ? "open" : "close";
        }

        this.animation.setAnimation(0, animation, true);
    },

    showBattle: function () {
        var styles = cleverapps.styles.IslandEnemies;

        if (!styles) {
            return;
        }

        var data = [{
            friend: false,
            characters: this.enemies,
            styles: styles.enemies
        }, {
            friend: true,
            characters: this.friends,
            styles: styles.friends
        }];

        data.forEach(function (item) {
            var characters;

            if (!item.characters) {
                characters = this.createCharacters(item.friend, true);
                characters.forEach(function (character) {
                    var scale = character.getScale();
                    character.setScale(0);
                    character.setAnimation(0, "idle", true);
                    character.runAction(new cc.Sequence(
                        new cc.ScaleTo(0.5, scale).easing(cc.easeIn(2)),
                        new cc.CallFunc(function () {
                            character.setAnimation(0, character.attackAnimation, true);
                            cleverapps.audio.playSound(bundles.episodes_resources.urls.friend_attack);
                        })
                    ));
                });

                if (item.friend) {
                    this.friends = characters;
                } else {
                    this.enemies = characters;
                }
            } else {
                item.characters.forEach(function (character) {
                    var pos = character.getPosition();
                    character.setScale(item.styles.scale || 1.0);
                    character.runAction(new cc.Sequence(
                        new cc.MoveTo(0.2, pos.x + item.styles.delta.x, pos.y + item.styles.delta.y).easing(cc.easeIn(2)),
                        new cc.CallFunc(function () {
                            character.setAnimation(0, character.attackAnimation, true);
                            cleverapps.audio.playSound(bundles.episodes_resources.urls.enemy_attack);
                        })
                    ));
                });
            }
        }.bind(this));
    },

    animatePass: function (callback) {
        if (this.clickHandler) {
            this.clickHandler.remove();
            this.clickHandler = undefined;
        }

        this.showBattle();

        this.runAction(new cc.Sequence(new cc.DelayTime(1), new cc.CallFunc(function () {
            this.runCharactersAnimation(this.enemies, "die");
        }.bind(this))));
        this.runAction(new cc.Sequence(new cc.DelayTime(2), new cc.CallFunc(function () {
            this.runCharactersAnimation(this.friends, "idle");

            if (this.label && this.label.visible) {
                this.label.setScale(1);
                this.label.runAction(new cc.ScaleTo(0.5, 0).easing(cc.easeIn(2)));
            }

            this.removeProgress();
            this.removeHighlight();
            this.addStatus();
        }.bind(this))));
        this.runAction(new cc.Sequence(new cc.DelayTime(cleverapps.styles.IslandView.animatePass.delay), new cc.CallFunc(callback)));

        return true;
    },

    animateOpen: function (callback) {
        this.addHighlight();
        this.removeStatus();

        this.makeIslandClickable();

        this.runAction(new cc.Sequence(new cc.DelayTime(1.5), new cc.CallFunc(function () {
            if (!this.label) {
                this.label = new CurrentEpisodeLabel(this.pageNo);
                this.addChild(this.label);
            }
            this.label.setVisible(true);
            this.label.setScale(0);
            this.label.runAction(new cc.ScaleTo(0.5, 1).easing(cc.easeIn(2)));

            this.addProgress();

            callback();
        }.bind(this))));

        return true;
    },

    onScroll: function () {
    },

    update: function (dt) {
        this._super(dt);

        var y = this.convertToWorldSpace(this.getPosition()).y;
        if (this.savedY === y) {
            return;
        }

        var styles;
        var scales;
        var dist;
        var h = cleverapps.resolution.getSceneSize().height;

        if (cleverapps.config.name === "adventure") {
            dist = h - y;
            styles = cleverapps.styles.IslandView.adventure3d;
            scales = styles.scales;
            var scale;
            var maxScaleCoef = 1.8;
            if (dist < h / maxScaleCoef) {
                scale = scales[0] + scales[1] * dist / (h / maxScaleCoef);
            } else {
                scale = scales[0] + scales[1] * (h - dist) / (h - h / maxScaleCoef);
            }

            if ((this.animation.width + styles.padding.x) * scale > cleverapps.resolution.getSceneSize().width * 0.8) {
                scale = cleverapps.resolution.getSceneSize().width * 0.8 / (this.animation.width + styles.padding.x);
            }

            this.setScale(scale);
            this.baseScale = scale;
            this.applyInteractiveScale();
        } else {
            styles = cleverapps.styles.IslandView.runes3d;
            scales = cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? styles.mobileScales : styles.scales;
            dist = h - y + this.parent.height / 2;
            var k = dist / h;
            k = k < 1 ? k : 1;
            var scaleX = k * scales[1] + scales[0];
            var scaleY = scaleX;
            if (styles.horizont) {
                scaleY = k * scales[1] + (k * (1 - styles.horizont.scale) + styles.horizont.scale) * scales[0];
            }

            if ((this.animation.width + styles.padding.x) * scaleX > cleverapps.resolution.getSceneSize().width * 0.8) {
                var oldScaleX = scaleX;
                scaleX = cleverapps.resolution.getSceneSize().width * 0.8 / (this.animation.width + styles.padding.x);
                scaleY = scaleY * scaleX / oldScaleX;
            }

            this.parent.setScale(scaleX, scaleY);
            this.parent.setLocalZOrder(-this.page.pageNo);
        }

        this.savedY = y;
    }
});

cleverapps.styles.IslandView = {
    width: 400,
    height: 300,

    positions: {
        0: {
            x: 150,
            y: 0
        },

        1: {
            x: -150,
            y: 0
        }
    },

    positionsMobile: {
        0: {
            x: -50,
            y: 0
        },

        1: {
            x: 50,
            y: 0
        }
    },

    decorPositions: {
        0: {
            x: 600,
            y: 0,
            scaleX: -1
        },

        1: {
            x: -600,
            y: 0
        }
    },

    decorPositionsMobile: {
        disabled: true
    },

    animatePass: {
        delay: 3.5
    },

    status: {
        positions: {
            0: {
                x: { align: "center", dx: 155 },
                y: { align: "center", dy: 100 }
            },

            1: {
                x: { align: "center", dx: 155 },
                y: { align: "center", dy: 100 }
            }
        },

        icon: {
            dy: 4
        }
    },

    runes3d: {
        scales: [0.8, 0.2],
        mobileScales: [0.8, 0.1],
        horizont: {
            scale: 0.7
        },
        padding: {
            x: 100
        }
    },

    adventure3d: {
        scales: [0.5, 1.0],
        padding: {
            x: 300
        }
    }
};