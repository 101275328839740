/**
 * Created by Vladislav on 25.09.2024.
 */

Placements.ENTRIES["starChest"] = {
    type: Placements.FREE_FOCUS_MAIN,
    filter: function () {
        return cleverapps.starChest && cleverapps.starChest.isEnabled() && !cleverapps.starChest.locked;
    },
    action: function () {
        cleverapps.focusManager.display({
            focus: "StarChestWindow",
            action: function (f) {
                new StarChestWindow();
                cleverapps.focusManager.onceNoWindowsListener = f;
            }
        });
    }
};