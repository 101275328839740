/**
 * Created by vladislav on 19/12/2022
 */

cc.MoveInCircle = cc.MoveInArc.extend({
    ctor: function (duration, center, position) {
        var angle = Math.atan2(
            position.y - center.y,
            position.x - center.x
        );

        if (angle <= 0) {
            angle += 2 * Math.PI;
        }
        angle += 2 * Math.PI;

        cc.MoveInArc.prototype.ctor.call(this, duration, center, angle);
    }
});