/**
 * Created by slava on 02.02.17.
 */

cleverapps.Scenes = function () {
    this.onAvoidNodeVisibilityChanged = function () { };
};

cleverapps.refreshScene = function () {
    var sceneChange = function (f) {
        var scene = cleverapps.scenes.getRunningScene();
        scene.refreshFromTool = true;

        if (scene instanceof MainScene) {
            cleverapps.travelBook.gotoMainScene(f);
        } else if (typeof EditorScene !== "undefined" && scene instanceof EditorScene) {
            EditorScene.open({ level: scene.level, callback: f });
        } else if (scene instanceof GameScene) {
            if (cleverapps.config.editorMode) {
                f();
                LevelManager.getInstance().setLevel({
                    levelNo: cleverapps.environment.levelNo,
                    episodeNo: cleverapps.environment.episodeNo
                });
                LevelManager.getInstance().editLevel();
            } else if (cleverapps.config.type === "merge") {
                cleverapps.travelBook.gotoMainScene(f);
            } else {
                cleverapps.meta.wantsToPlay(f);
            }
        } else if (typeof HeroesScene !== "undefined" && scene instanceof HeroesScene) {
            cleverapps.scenes.replaceScene(new HeroesScene(), f);
        } else if (scene instanceof DailyCupScene) {
            cleverapps.scenes.replaceScene(new DailyCupScene(), f);
        } else if (typeof BonusWorldScene !== "undefined" && scene instanceof BonusWorldScene) {
            cleverapps.travelBook.gotoMainScene(function () {
                cleverapps.scenes.replaceScene(new BonusWorldScene(cleverapps.missionManager.findRunningMission(Mission.TYPE_BONUS_WORLD)), f);
            });
        } else if (typeof OctopusHuntScene !== "undefined" && scene instanceof OctopusHuntScene) {
            cleverapps.scenes.replaceScene(new OctopusHuntScene(cleverapps.missionManager.findRunningMission(Mission.TYPE_OCTOPUS_HUNT)), f);
        } else if (typeof WeeklyTournamentScene !== "undefined" && scene instanceof WeeklyTournamentScene) {
            cleverapps.scenes.replaceScene(new WeeklyTournamentScene(), f);
        }
    };

    if (cleverapps.focusManager.isFocused()) {
        cleverapps.focusManager.distract({
            focus: "sceneRefresh",
            action: sceneChange
        });
    } else {
        cleverapps.focusManager.display({
            focus: "sceneRefresh",
            action: sceneChange
        });
    }
};

cleverapps.Scenes.prototype.getMovingNode = function (target) {
    for (; target; target = target.parent) {
        if (target.movingNode) {
            return target.movingNode;
        }
    }

    return cleverapps.scenes.getRunningScene().movingNode;
};

cleverapps.Scenes.prototype.getRunningScene = function () {
    return cc.director.getRunningScene();
};

cleverapps.Scenes.prototype.replaceScene = function (toScene, f) {
    if (!cleverapps.focusManager.isFocused()) {
        cleverapps.focusManager.debugMessage("Trying to replace scene without user focus! " + toScene.sceneName);
    }

    f = f || function () { };

    if (cleverapps.config.editorMode || cleverapps.config.demoMode || cleverapps.config.wysiwygMode) {
        cleverapps.silentIntro = true;
    }

    cleverapps.notification.clear();

    FingerView.remove(FingerView.currentFinger, true);
    PointerView.remove(PointerView.currentPointer);

    var fromScene = cleverapps.scenes.getRunningScene();
    var isFromLoaderScene = fromScene instanceof LoaderScene;
    var start = Date.now();
    var transition = undefined;

    cleverapps.focusManager.compound(f, [
        function (f) {
            toScene.isAboutToShow = true;
            fromScene.isAboutToRemove = true;

            fromScene.destructor();

            if (toScene.prepareBundles) {
                toScene.prepareBundles();
            }

            f();
        },

        function (f) {
            transition = new Transition(fromScene, toScene);

            if (fromScene.onTransitionAnimationDidStart) {
                fromScene.onTransitionAnimationDidStart();
            }

            transition.show(f, cleverapps.silentIntro);
        },

        function (f) {
            if (cleverapps.bannerAd) {
                cleverapps.bannerAd.check(true);
            }

            if (toScene.updateSize) {
                toScene.updateSize();
            }

            transition.replaceParent(toScene);

            toScene.addTransitionBundles(transition.bundles);

            cc.director.runScene(toScene);
            cc.director.setNextScene();

            f();
        },

        function (f) {
            cleverapps.lazyAssets.unload();
            if (fromScene.unload) {
                fromScene.unload(toScene);
            }

            var q = [fromScene];
            while (q.length > 0) {
                var t = q.pop();
                q = q.concat(t.children);

                if (t.iterateTiles) {
                    t.iterateTiles(function (tile) {
                        if (tile instanceof cc.Node) {
                            q.push(tile);
                        }
                    });
                }

                t.removeAllChildren();
            }

            fromScene = undefined;
            cleverapps.windows.reset();

            if (engine === "creator") {
                cc.SkeletonCache.sharedCache.clear();
            }

            f();
        },

        function (f) {
            var callback = cleverapps.wait(2, f);
            if (isFromLoaderScene) {
                cleverapps.rewardedAdsManager.showInterstitial(AdsLimits.TYPES.PREROLL, callback);
            } else {
                callback();
            }

            if (toScene.load) {
                toScene.load(callback);
            } else {
                callback();
            }
        },

        function (f) {
            toScene.isAllLoaded = true;

            if (toScene.onSceneLoaded) {
                toScene.onSceneLoaded();
            }

            if (toScene.afterResize) {
                toScene.afterResize();
            }

            f();
        },

        function (f) {
            if (cleverapps.config.debugMode && LoaderScene.start) {
                console.log("scene loading time", (Date.now() - LoaderScene.start) / 1000);
                LoaderScene.start = undefined;
            }

            if (cleverapps.toolModel && typeof ToolView !== "undefined" && toScene.movingNode) {
                cleverapps.toolModel.reset();
                toScene.toolMenu = new ToolView();
                toScene.addChild(toScene.toolMenu);
                toScene.toolMenu.addOpener();
            }

            if (cc.game.isPaused()) {
                cc.game.step();
            }

            cleverapps.ConsoleStream.sendLoadingTime("scene " + cleverapps.environment.scene + " " + (Date.now() - start) + "ms");

            f();
        },

        function (f) {
            if (connector.platform.oneOf(connector.SP_MOBAGE)) {
                cleverapps.platformLogic.showLoginScreen(f);
            } else {
                f();
            }
        },

        function (f) {
            transition.hide(f);
        },

        function (f) {
            if (toScene.takeTransitNodes) {
                toScene.takeTransitNodes();
            }

            transition.removeFromParent();

            toScene.isAboutToShow = undefined;

            cleverapps.notification.processQueue();
            connector.platform.notifyGameReady();

            cleverapps.bundleLoader.cacheBundles(toScene.cachedBundles());

            toScene.ensureSoundsLoaded();

            f();
        }
    ]);
};

cleverapps.Scenes.prototype.sceneRefresh = function () {
    cleverapps.flags.reset();

    if (levels.tutorial) {
        levels.tutorial.stop();
    }

    if (connector.social.isLoggedIn()) {
        connector.social.getPlugin().onLogin();
    } else {
        cleverapps.synchronizer.syncWhenReady();
    }

    cleverapps.windows.reset();

    if (cleverapps.isKnockoutGame()) {
        cleverapps.knockoutGame.stopRumble();
    }

    cleverapps.focusManager.distract({
        focus: "sceneRefresh",
        action: function (f) {
            cleverapps.travelBook.gotoMainScene(f);
        }
    });
};

cleverapps.Scenes.prototype.isSceneReady = function () {
    var scene = this.getRunningScene();
    return !scene.isAboutToRemove && !scene.isAboutToShow;
};

cleverapps.Scenes.prototype.addTransitNode = function (node, name) {
    if (!this.transitNodes) {
        this.transitNodes = {};
    }

    this.transitNodes[name] = node;
};

cleverapps.Scenes.prototype.getTransitNode = function (name) {
    return this.transitNodes[name];
};