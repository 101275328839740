/**
 * Created by vlad on 11.12.18.
 */

var DirtyHoneyTile = function (x, y) {
    BaseTile.call(this, x, y);
    this.lives = 1;
    this.putInPoolAvailable = true;

    var game = Game.currentGame;
    if (!(game instanceof FakeGame) && game.bindings["beforeStartActions"].indexOf(DirtyHoneyTile.onGameStart) === -1) {
        game.on("beforeStartActions", DirtyHoneyTile.onGameStart);
        game.counter.registerStage(211, DirtyHoneyTile.process);
    }

    this.onCreateNew = function () {};
};

DirtyHoneyTile.prototype = Object.create(BaseTile.prototype);
DirtyHoneyTile.prototype.constructor = DirtyHoneyTile;

DirtyHoneyTile.prototype.getViewClass = function () {
    return DirtyHoneyTileView;
};

DirtyHoneyTile.prototype.save = function () {
    return DirtyHoneyTile.CODES;
};

DirtyHoneyTile.prototype.getGoalId = function () {
    return DirtyHoneyTile.GOAL_ID;
};

DirtyHoneyTile.prototype.bundleId = function () {
    return "dirty_honey";
};

DirtyHoneyTile.onGameStart = function () {
    DirtyHoneyTile.createdAmount = 0;
};

DirtyHoneyTile.prototype.explode = function () {
    BaseTile.prototype.explode.call(this);

    Game.currentGame.goalCounter.setTimeout(function () {
        if (Game.currentGame && Game.currentGame.goals.hasType(DirtyHoneyTile.GOAL_ID)) {
            if (DirtyHoneyTile.createdAmount > 0) {
                DirtyHoneyTile.createdAmount--;
                Game.currentGame.goals.incGoal(DirtyHoneyTile.GOAL_ID, 1);
            } else {
                Game.currentGame.goals.incGoal(DirtyHoneyTile.GOAL_ID, 1);
            }
        }
    }, 0);

    Game.currentGame.dirtyHoneyTileCanProcess = false;
};

DirtyHoneyTile.prototype.showUpDuration = function () {
    return 0.6;
};

DirtyHoneyTile.prototype.getExplodeInterval = function () {
    return 0.6;
};

DirtyHoneyTile.canProcessTo = function (i, j) {
    if (Game.currentGame.field.isCellInField(i, j)) {
        var cell = Game.currentGame.field.cells[i][j];
        var tile = Game.currentGame.field.floor[i][j];
        return cell.getColor() !== undefined && tile === undefined;
    }
    return false;
};

DirtyHoneyTile.isDirtyHoneyTile = function (i, j) {
    if (Game.currentGame.field.isCellInField(i, j)) {
        return Game.currentGame.field.floor[i][j] && Game.currentGame.field.floor[i][j].constructor === DirtyHoneyTile;
    }
    return false;
};

DirtyHoneyTile.canBlock = function (i, j) {
    if (Game.currentGame.field.isCellInField(i, j)) {
        var cell = Game.currentGame.field.cells[i][j];
        return !(cell.findComponent(BigComponent) || cell.findComponent(DecoratorComponent)) && cell.getColor() !== undefined;
    }
    return false;
};

DirtyHoneyTile.processTo = function (tile) {
    var dirtyHoneyTile = new DirtyHoneyTile(tile.x, tile.y);
    Game.currentGame.field.addTile(dirtyHoneyTile);
    dirtyHoneyTile.onCreateNew();
    Game.currentGame.counter.setTimeout(function () {
    }, dirtyHoneyTile.showUpDuration() * 1000);

    DirtyHoneyTile.createdAmount++;

    Game.currentGame.goalCounter.setTimeout(function () {
        if (Game.currentGame) {
            Game.currentGame.goals.incTarget(DirtyHoneyTile.GOAL_ID, 1);
        }
    }, dirtyHoneyTile.showUpDuration() * 1000);
};

DirtyHoneyTile.block = function (cell) {
    var decorator = new DirtyHoneyDecoratorCell(cell.x, cell.y, { components: [DecoratorComponent] });
    decorator.setInnerCell(cell);
    Game.currentGame.field.addCell(decorator);

    decorator.onCreateNew();
    Game.currentGame.counter.setTimeout(function () {
    }, decorator.showUpDuration() * 1000);
};

DirtyHoneyTile.canSpread = function (iOld, jOld, iNew, jNew) {
    return DirtyHoneyTile.isDirtyHoneyTile(iOld, jOld)
           && (Game.currentGame.field.barriers.canMove(iOld, jOld, iNew, jNew) === true);
};

DirtyHoneyTile.process = function () {
    if (!Game.currentGame || !Game.currentGame.dirtyHoneyTileCanProcess) {
        return;
    }

    var toProcess = [], toBlock = [], i, j;
    for (i = 0; i < Field.SIZE; i++) {
        for (j = 0; j < Field.SIZE; j++) {
            if (DirtyHoneyTile.isDirtyHoneyTile(i, j) && DirtyHoneyTile.canBlock(i, j)) {
                toBlock.push(Game.currentGame.field.cells[i][j]);
            }

            if (DirtyHoneyTile.canProcessTo(i, j) && (
                DirtyHoneyTile.canSpread(i - 1, j, i, j)
                || DirtyHoneyTile.canSpread(i + 1, j, i, j)
                || DirtyHoneyTile.canSpread(i, j - 1, i, j)
                || DirtyHoneyTile.canSpread(i, j + 1, i, j))) {
                toProcess.push(Game.currentGame.field.cells[i][j]);
            }
        }
    }

    DirtyHoneyTile.chooseActions(toProcess, toBlock, 2);
};

DirtyHoneyTile.chooseActions = function (toProcess, toBlock, amount) {
    for (var i = 0; i < amount; i++) {
        var types = [];
        if (toProcess.length > 0) {
            types.push(DirtyHoneyTile.PROCESS_TYPES.TILE);
        }
        if (toBlock.length > 0) {
            types.push(DirtyHoneyTile.PROCESS_TYPES.DECORATOR);
        }
        if (types.length === 0) {
            break;
        }
        var type = cleverapps.Random.choose(types);

        Game.currentGame.dirtyHoneyTileCanProcess = false;

        if (type === DirtyHoneyTile.PROCESS_TYPES.TILE) {
            var index = cleverapps.Random.random(toProcess.length);
            DirtyHoneyTile.processTo(toProcess[index]);
            toProcess.splice(index, 1);
        }

        if (type === DirtyHoneyTile.PROCESS_TYPES.DECORATOR) {
            index = cleverapps.Random.random(toBlock.length);
            DirtyHoneyTile.block(toBlock[index]);
            toBlock.splice(index, 1);
        }
    }
};

DirtyHoneyTile.PROCESS_TYPES = {
    TILE: 0,
    DECORATOR: 1
};

DirtyHoneyTile.CODES = ["z"];
DirtyHoneyTile.GOAL_ID = "dirty_honey";