/**
 * Created by Andrey Popov on 10.11.2021
 */

var ImageSpyView = AnalyzerWindowBase.extend({
    ctor: function (imageSpy) {
        this._super("Spy");

        this.imageSpy = imageSpy;

        this.imageSpy.on("updateTextureData", this.updateTextureData.bind(this), this);
        this.imageSpy.on("updateProperty", this.updateProperty.bind(this), this);

        this.updateSize();
        this.setupChildren();
    },

    updateSize: function () {
        var sceneSize = cleverapps.resolution.getSceneSize();

        var width = cleverapps.styles.ImageSpyView.width;
        var height = sceneSize.height - cleverapps.styles.AtlasAnalyzerScene.offsetTop;

        this.setContentSize(width, height);
    },

    updateProperty: function (params) {
        if (this.propValues[params.propName]) {
            this.propValues[params.propName].setString(params.value);
        }
    },

    updateTextureData: function (textureData) {
        this.propValues = {};
        var styles = cleverapps.styles.ImageSpyView;

        var properties = Object.keys(textureData).map(function (propertyName) {
            var data = textureData[propertyName];

            if (data.hideIfTrue) {
                return;
            }

            var propertyNameText = cleverapps.UI.generateOnlyText(data.errorMessage ? data.errorMessage : propertyName, data.errorMessage ? cleverapps.styles.FONTS.IMAGE_SPY_ERROR_TEXT : cleverapps.styles.FONTS.IMAGE_SPY_CAPTION_TEXT);
            propertyNameText.setDimensions(styles.width * 0.9, 0);
            propertyNameText.setAnchorPoint(0.5, 0.5);

            var propertyParts = [propertyNameText];

            if (data.link) {
                var propertyLink = cleverapps.UI.generateOnlyText(data.value, cleverapps.styles.FONTS.IMAGE_SPY_LINK_TEXT);
                propertyLink.setDimensions(styles.width * 0.9, 0);
                propertyLink.setAnchorPoint(0.5, 0.5);
                propertyParts.push(propertyLink);

                cleverapps.UI.onClick(propertyLink, function () {
                    connector.platform.openUrl(data.link, "_blank");
                });
            } else if (data.value) {
                var propertyValueText = this.propValues[propertyName] = cleverapps.UI.generateOnlyText(data.value, cleverapps.styles.FONTS.IMAGE_SPY_VALUE_TEXT);
                propertyValueText.setDimensions(styles.width * 0.9, 0);
                propertyValueText.setAnchorPoint(0.5, 0.5);
                propertyParts.push(propertyValueText);
                if (data.onClick) {
                    cleverapps.UI.onClick(propertyValueText, function () {
                        data.onClick(data.value);
                    });
                }
            } else if (data.values) {
                var valueTexts = data.values.map(function (value, index) {
                    var propertyValueText = cleverapps.UI.generateOnlyText(
                        index + 1 + ". " + (value.key || value.name),
                        data.error ? cleverapps.styles.FONTS.IMAGE_SPY_ERROR_TEXT : cleverapps.styles.FONTS.IMAGE_SPY_VALUE_TEXT
                    );
                    propertyValueText.setDimensions(styles.width * 0.9, 0);
                    propertyValueText.setAnchorPoint(0.5, 0.5);
                    cleverapps.UI.onClick(propertyValueText, function () {
                        data.onClick(value);
                    });

                    return propertyValueText;
                });

                propertyParts.push(new cleverapps.Layout(valueTexts, {
                    direction: cleverapps.UI.VERTICAL,
                    margin: styles.margin / 2
                }));
            }

            return new cleverapps.Layout(propertyParts, {
                direction: cleverapps.UI.VERTICAL,
                margin: styles.margin
            });
        }.bind(this));

        this.createScroll(new cleverapps.Layout(properties, {
            direction: cleverapps.UI.VERTICAL,
            margin: 3 * styles.margin
        }));

        this.scroll.scrollToPercent(100);
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    IMAGE_SPY_CAPTION_TEXT: {
        size: 20,
        color: cleverapps.styles.COLORS.COLOR_DARK_GRAY,
        font: bundles.wysiwyg.urls.strict_font_ttf
    },

    IMAGE_SPY_ERROR_TEXT: {
        size: 24,
        color: cleverapps.styles.COLORS.COLOR_RED,
        font: bundles.wysiwyg.urls.strict_font_ttf
    },

    IMAGE_SPY_VALUE_TEXT: {
        size: 24,
        color: cleverapps.styles.COLORS.BLACK,
        font: bundles.wysiwyg.urls.strict_font_ttf
    },

    IMAGE_SPY_LINK_TEXT: {
        size: 24,
        color: new cc.Color(50, 50, 238, 255),
        font: bundles.wysiwyg.urls.strict_font_ttf
    }
});

cleverapps.styles.ImageSpyView = {
    width: 500,

    x: { align: "left", dx: -10 },
    y: { align: "top", dy: -60 },

    margin: 10
};