/**
 * Created by iamso on 09.11.18.
 */

var GoalsView = cc.Node.extend({
    ctor: function (game) {
        this._super();
        GoalViewFactory.init();

        this.game = game;

        this.setLocalZOrder(15);
        this.setAnchorPoint(0.5, 0.5);
        this.updateSize();
        this.setGoals();

        if (!this.game.hasDanger()) {
            this.setBackground();
        }

        this.setupChildren();

        game.goals.on("showUpAnimationStart", this.onShowUpAnimationStart.bind(this), this);
        game.goals.on("stopShowUp", this.showUpAnimationFinish.bind(this), this);

        game.goals.onGetView = this.createListener(function () {
            return this;
        }.bind(this));
    },

    animateHighlight: function (callback) {
        var actions = this.views.map(function (view) {
            return function (f) {
                view.highlight();
                setTimeout(f, 500);
            };
        });
        new ActionPlayer(actions).play(callback);
    },

    onShowUpAnimationStart: function (callback, silent) {
        if (silent) {
            this.views.forEach(function (view) {
                view.setVisible(true);
            });
            callback();
            return;
        }

        this.runAction(new cc.DelayTime(cleverapps.styles.GoalsView.startAnimation.delay).setFinalize(function () {
            this.showUpAnimationFinish();
            callback();
        }.bind(this)));

        this.createCarAnimation();
        this.createStartAnimation();
    },

    createStartAnimation: function () {
        var scene = cleverapps.scenes.getRunningScene();
        var styles = cleverapps.styles.GoalsView.startAnimation;
        var baseScale = styles.messagesScale[cleverapps.resolution.mode] / this.scale;
        var availableWidth = styles.width;

        this.views.forEach(function (view) {
            view.upForStartAnimation();
            availableWidth -= view.width;
        });

        var margins = this.views.length + 1;
        var margin = availableWidth / margins;

        var startAnimation = this.startAnimation = new cleverapps.Layout(this.views, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: margin
        });

        startAnimation.setPositionRound(scene.width / 2 + styles.goals.x * baseScale, scene.height / 2 + styles.goals.y * baseScale);
        startAnimation.setScale(styles.action.scale);
        startAnimation.setLocalZOrder(7);

        scene.addChild(startAnimation);

        this.runStartAnimationAction();

        var count = this.views.length;
        var stopAction = cleverapps.wait(count, this.removeStartAnimation.bind(this));

        if (this.game.hasDanger()) {
            this.dangerStartAnimation();
            return;
        }

        this.views.forEach(function (view) {
            view.runAction(new cc.Sequence(
                new cc.DelayTime(1.5),
                new cc.CallFunc(function () {
                    var targetPosition = this.goalsLayout.convertToWorldSpace(view.originalPosition);
                    var offset = styles.goals.flyOffset[cleverapps.resolution.mode];
                    targetPosition.x += offset.x;
                    targetPosition.y += offset.y;
                    var points = cc.CollectAnimation.calc3Points(view, targetPosition);

                    view.runStartAnimation(stopAction, points, { x: this.scaleX * this.parent.scaleX, y: this.scaleY * this.parent.scaleY });
                }.bind(this))
            ));
        }.bind(this));
    },

    dangerStartAnimation: function () {
        this.startAnimation.runAction(new cc.Sequence(
            new cc.DelayTime(1.4),
            new cc.PlaySound(bundles.game.urls.goals_fly_effect),
            new cc.ScaleTo(0.2, 0).easing(cc.easeBackIn()),
            new cc.CallFunc(this.removeStartAnimation.bind(this))
        ));
    },

    runStartAnimationAction: function () {
        var styles = cleverapps.styles.GoalsView.startAnimation;
        var baseScale = styles.messagesScale[cleverapps.resolution.mode] / this.scale;

        this.startAnimation.setVisible(false);
        this.startAnimation.runAction(new cc.Sequence(
            new cc.DelayTime(styles.action.delay),
            new cc.Show(),
            new cc.Spawn(
                new cc.ScaleTo(0.25, 1.0).easing(cc.easeIn(2)),
                new cc.MoveBy(0.25, cc.p(0, styles.goals.y * baseScale))
            ),
            new cc.ScaleTo(0.1, 1.1, 0.9),
            new cc.ScaleTo(0.1, 1.0)
        ));
    },

    removeStartAnimation: function () {
        this.views.forEach(function (view) {
            view.downFromStartAnimation();
        });

        if (this.startAnimation) {
            this.startAnimation.stopAllActions();
            this.startAnimation.removeFromParent();
            delete this.startAnimation;
        }
    },

    createCarAnimation: function () {
        var styles = cleverapps.styles.GoalsView.startAnimation;
        var scene = cleverapps.scenes.getRunningScene();

        var baseScale = styles.scales[cleverapps.resolution.mode];
        var carAnimation = this.carAnimation = new cleverapps.Spine(bundles.game.jsons.start_level_animation_json);
        carAnimation.setScale(baseScale || 1.0);
        carAnimation.setPositionRound(scene.width / 2, scene.height / 2 + styles.car.offsetY);
        carAnimation.setLocalZOrder(7);
        scene.addChild(carAnimation);

        carAnimation.setCompleteListenerRemove();
        carAnimation.runAction(new cc.CallFunc(function () {
            carAnimation.setAnimation(0, "animation", false);
        }));

        cleverapps.audio.playSound(bundles.game.urls.car_episode_effect);
    },

    hide: function () {
        var scene = cleverapps.scenes.getRunningScene();
        var targetPosition = this.getPosition();
        switch (cleverapps.resolution.mode) {
            case cleverapps.WideMode.VERTICAL: case cleverapps.WideMode.SQUARE:
                targetPosition.y = scene.height + this.height + 2;
                break;
            case cleverapps.WideMode.HORIZONTAL:
                targetPosition.x = -this.width - 2;
                break;
        }

        this.runAction(new cc.MoveTo(0.15, targetPosition).easing(cc.easeIn(2)).setFinalize(function () {
            this.setPositionRound(targetPosition);
            this.setVisible(false);
        }.bind(this)));
    },

    updateSize: function () {
        this.setContentSize2(this.getSize());
    },

    completeAnimationOnResize: function () {
        this.stopAllActions();

        if (this.startAnimation) {
            this.removeStartAnimation();
        }

        this.views.forEach(function (view) {
            view.stopAllActions();
        });

        if (this.carAnimation) {
            this.carAnimation.stopAllActions();
            this.carAnimation.removeFromParent();
            delete this.carAnimation;
        }
    },

    showUpAnimationFinish: function () {
        this.views.forEach(function (view) {
            view.setVisible(true);
        });
    },

    setupChildren: function () {
        var positionStyles = this.game.secondaryMission ? cleverapps.styles.GoalsView.positionWithMission : cleverapps.styles.GoalsView.positionNoMission;

        if (this.game.hasDanger()) {
            positionStyles = cleverapps.styles.GoalsView.positionDangerLevel;
        }

        var position = positionStyles[cleverapps.resolution.mode];
        if (Array.isArray(position)) {
            position = position[this.views.length - 1];
        }
        this.setPositionRound(position);
        this.updateGoalsPosition();
    },

    getSize: function () {
        var sizes = (this.game.secondaryMission) ? cleverapps.styles.GoalsView.sizes.withMission : cleverapps.styles.GoalsView.sizes.noMission;

        if (this.game.hasDanger()) {
            return cleverapps.styles.BaseGoalView.danger;
        }

        if (cleverapps.environment.isAdministratorScene()) {
            return sizes[cleverapps.WideMode.VERTICAL];
        }

        return sizes;
    },

    setBgTitle: function () {
        this.bgTitle && this.bgTitle.removeFromParent(true);
        var styles = cleverapps.styles.GoalsView.bgTitle;
        if (styles) {
            var bgTitle = this.bgTitle = new cc.Sprite(bundles.game.frames.goals_title);
            var text = cleverapps.UI.generateOnlyText("Goals.title", cleverapps.styles.FONTS.GOALS_TITLE);
            text.setPositionRound(bgTitle.width / 2, bgTitle.height / 2 + styles.text.offsetY);
            bgTitle.addChild(text);

            this.addChild(bgTitle);

            bgTitle.setupChildren = function () {
                bgTitle.setPositionRound(styles.position[cleverapps.resolution.mode]);
            };
            bgTitle.updateSize = function () {
                bgTitle.setScale(1);
                var sizeStyles = styles.position[cleverapps.resolution.mode];
                if (sizeStyles.scale) {
                    bgTitle.setScale(sizeStyles.scale);
                }
            };
            bgTitle.updateSize();
            bgTitle.setupChildren();
        }
    },

    setBackground: function () {
        var image = bundles.game.frames.goal_common_bg;
        if (!image) {
            return;
        }

        this.background = cleverapps.UI.createScale9Sprite(image);
        this.background.setLocalZOrder(-1);
        this.addChild(this.background);

        if (cleverapps.styles.COLORS.HARD && this.game.level.isHard()) {
            this.background.setColor(cleverapps.styles.COLORS.HARD);
        }

        this.background.updateSize = function () {
            this.background.setContentSize2(this.getSize());
        }.bind(this);

        this.background.setupChildren = function () {
            var position = cleverapps.styles.GoalsView.background;
            this.background.setPositionRound(position);
        }.bind(this);

        this.background.updateSize();
        this.background.setupChildren();

        this.setBgTitle();
    },

    setGoals: function () {
        this.views = [];
        var id = 0;
        Object.values(this.game.goals.elements).forEach(function (element) {
            var view = GoalViewFactory.createView(element);
            view.setLocalZOrder(cleverapps.styles.GoalsView.zOrders[id]);
            this.views.push(view);

            element.checkCompleted();
            view.update();

            element.getView = function () {
                return view;
            };

            id++;
        }.bind(this));
    },

    updateGoalsPosition: function () {
        var styles = cleverapps.styles.GoalsView.goalsLayout;
        var direction = cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL ? cleverapps.UI.VERTICAL : cleverapps.UI.HORIZONTAL;

        var scale;
        if (styles.scales && styles.scales[cleverapps.resolution.mode]) {
            if (Array.isArray(styles.scales[cleverapps.resolution.mode])) {
                scale = styles.scales[cleverapps.resolution.mode][this.views.length - 1] || false;
            } else {
                scale = styles.scales[cleverapps.resolution.mode];
            }
        }

        if (cleverapps.environment.isAdministratorScene()) {
            direction = cleverapps.UI.HORIZONTAL;
        }

        var side = direction === cleverapps.UI.VERTICAL ? "height" : "width";

        var originalSize = 0;
        this.views.forEach(function (view) {
            if (scale) {
                view.setScale(scale);
            }

            originalSize += view[side] * view.scale;
        });

        var layoutOptions = {
            direction: direction,
            margin: (this[side] - originalSize) / (this.views.length)
        };

        if (!this.goalsLayout) {
            this.goalsLayout = new cleverapps.Layout(this.views, layoutOptions);
            this.addChild(this.goalsLayout);
        } else if (this.goalsLayout.options.direction !== direction) {
            layoutOptions.reversed = cleverapps.config.rpg;
            this.goalsLayout.setOptions(layoutOptions);
        }

        this.goalsLayout.setPositionRound(styles);
    }
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    GOALS_TITLE: {
        size: 22,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});

cleverapps.styles.GoalsView = {
    zOrders: [3, 3, 3],

    background: {
        x: { align: "center" },
        y: { align: "center" }
    },

    goalsLayout: {
        x: { align: "center" },
        y: { align: "center" }
    },

    startAnimation: {
        delay: 2.5,
        action: {
            scale: 0.1,
            delay: 0.25
        },
        width: 520,
        goals: {
            scale: [1.0, 1.2, 1.3],
            x: 0,
            y: 0,
            flyOffset: [
                { x: 0, y: 0 },
                { x: 0, y: 0 },
                { x: 0, y: 0 }
            ]
        },

        car: {
            offsetY: -20
        },

        messagesScale: [0.8, 1, 1],
        scales: [0.8, 1, 1]
    }
};
