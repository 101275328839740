/**
 * Created by razial on 20.12.2024.
 */

var SimpleAdapter = function () {
    this.locations = {};
};

SimpleAdapter.prototype.load = function () {
    var data = cleverapps.dataLoader.load(DataLoader.TYPES.META);
    var needSave = false;

    if (Version.compare(cleverapps.user.lastVersion, SimpleAdapter.SIMPLE_MIGRATE_VERSION) < 0) {
        data = cleverapps.dataLoader.load(DataLoader.TYPES.SIMPLE);
        needSave = true;
    }

    this.updateInfo(data);

    return needSave;
};

SimpleAdapter.prototype.save = function (fromSever) {
    var data = this.getInfo();

    this.locations[data.current] = {
        progress: data.stars
    };

    cleverapps.dataLoader.save(DataLoader.TYPES.META, data);
    if (!fromSever) {
        cleverapps.synchronizer.addUpdateTask("metha");
    }
};

SimpleAdapter.prototype.updateInfo = function (data) {
    data = data || {};

    if (cleverapps.config.rpg && !data && levels.user.episode > 0) {
        data = {
            current: levels.user.episode,
            progress: 0
        };
    }

    cleverapps.meta.logic.currentLocationId = data.current || 0;

    this.locations = {};
    this.locations[cleverapps.meta.logic.currentLocationId] = {
        progress: data.stars || 0
    };

    if (cleverapps.knockoutGame) {
        cleverapps.knockoutGame.updateData(data);
    }
};

SimpleAdapter.prototype.getInfo = function () {
    var location = this.locations[cleverapps.meta.logic.currentLocationId] || {};

    var data = {
        current: cleverapps.meta.logic.currentLocationId,
        stars: location.progress
    };

    if (cleverapps.knockoutGame) {
        Object.assign(data, cleverapps.knockoutGame.getData());
    }

    return data;
};

SimpleAdapter.prototype.loadLocation = function (locationId) {
    return this.locations[locationId] || {
        progress: locationId < cleverapps.meta.logic.currentLocationId ? SimpleLocation.GetRequiredProgress(locationId) : 0
    };
};

SimpleAdapter.prototype.saveLocation = function (locationId, data) {
    this.locations[locationId] = data;

    cleverapps.meta.save();
};

SimpleAdapter.prototype.reset = function () {
    this.locations = {};
};

SimpleAdapter.SIMPLE_MIGRATE_VERSION = "1.408.1";
