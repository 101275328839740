/**
 * Created by Vladislav on 25.09.2024.
 */

var LivesMenuBarItem = {
    name: "LivesItem",
    lottery: true,
    targets: "lives",
    plusButton: function () {
        return connector.platform.oneOf(connector.DIGITAL, connector.DIGITALWEB) ? undefined : true;
    },
    attentionUpdater: function (f) {
        if (Game.currentGame && Game.currentGame.energyLottery) {
            Game.currentGame.energyLottery.onLotteryAttentionChanged = f;
        }
    },
    attention: function () {
        if (Game.currentGame && Game.currentGame.energyLottery) {
            return Game.currentGame.energyLottery.isReady();
        }
    },
    availableOnScenes: function () {
        var scenes = [cleverapps.Environment.SCENE_BONUS_WORLD];

        var expedition = cleverapps.travelBook.getCurrentPage();
        if (expedition.withEnergy()) {
            scenes.push(cleverapps.Environment.SCENE_MAIN);
        }

        if (cleverapps.dailyCup.withLives()) {
            scenes.push(cleverapps.Environment.SCENE_DAILY_CUP);
        }

        scenes.push(cleverapps.Environment.SCENE_WEEKLY_TOURNAMENT);

        return scenes;
    },
    force: Forces.BUY_ENERGY_ICON,
    viewClass: MenuBarWithTimeoutView,
    modelClass: LivesMenuBarItemModel,
    icon: function () {
        return cleverapps.skins.getSlot("menubar_life_png") || bundles.menubar.frames.menubar_life_png;
    },
    onClickWindow: LivesShopWindow,
    filter: function () {
        if (cleverapps.unlimitedLives && cleverapps.unlimitedLives.freeOfferIsAvailable()) {
            cleverapps.unlimitedLives.showFreeOffer(function () {});
            return false;
        }

        if (cleverapps.unlimitedLives && cleverapps.unlimitedLives.checkBuyed() || connector.platform.oneOf(connector.DIGITAL, connector.DIGITALWEB)) {
            return false;
        }
        return true;
    },
    sparks: true,
    deltaSound: cleverapps.config.lives ? bundles.menubar.urls.lives_fly_finish_effect : bundles.menubar.urls.energy_fly_finish_effect
};