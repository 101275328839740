/**
 * Created by Mikhail Menshenin on 13.12.2024
 */

var Persist = function () {
    this.persistNodes = {};
};

Persist.prototype.sendNode = function (node, name) {
    var key = name || node.__instanceId;
    this.persistNodes[key] = node;
};

Persist.prototype.acceptNode = function (name) {
    var key = name || Object.keys(this.persistNodes)[0];
    var node = this.persistNodes[key];
    delete this.persistNodes[key];

    return node;
};

Persist.prototype.receiveNodes = function (recepient) {
    for (var key in this.persistNodes) {
        var node = this.persistNodes[key];
        node.replaceParentSamePlace(recepient, { keepScale: true });
    }
};