/**
 * Created by vladislav on 10.02.2022
 */

var DailyCupView = cc.Node.extend({
    ctor: function () {
        this._super();

        var styles = cleverapps.styles.DailyCupView;

        var title = this.createTitle();
        var timerBlock = this.timerBlock = new CupTimer({
            timeLeft: cleverapps.dailyCup.getTimeLeft(),
            text: "Cups.daily.finished"
        });
        var table = this.createTable();

        var topLayout = new cleverapps.Layout([title, timerBlock, table], {
            margin: styles.topLayout.margin,
            direction: cleverapps.UI.VERTICAL
        });

        var helpButton = this.createHelpButton();
        var leadersButton = this.createLeadersButton();
        var playButton = this.playButton = this.createPlayButton();

        var buttonsLayout = this.buttonsLayout = new cleverapps.Layout([helpButton, playButton, leadersButton], {
            margin: styles.buttonsLayout.margin,
            direction: cleverapps.UI.HORIZONTAL
        });

        var layout = new cleverapps.Layout([topLayout, buttonsLayout], {
            margin: styles.margin,
            direction: cleverapps.UI.VERTICAL
        });

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(layout.width, layout.height);
        layout.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(layout);

        var noConnectionInfo = this.noConnectionInfo = this.createNoConnectionInfo();
        noConnectionInfo.setPositionRound(layout.width / 2, layout.height / 2);
        layout.addChild(noConnectionInfo, 1);

        this.updateState();

        cleverapps.dailyCup.reload();

        cleverapps.dailyCup.on("update", this.createListener(this.updateState.bind(this)), this);
        this.updateSize();
        this.setupChildren();
    },

    setupChildren: function () {
        var styles = cleverapps.styles.DailyCupView;
        this.setPositionRound(styles.position);
    },

    updateSize: function () {
        var styles = cleverapps.styles.DailyCupView;
        var width = cleverapps.resolution.getSceneSize().width;
        this.setScale(width < styles.baseWidth ? width / styles.baseWidth : 1);
    },

    updateState: function () {
        if (this.animationRunning) {
            return;
        }

        if (cleverapps.dailyCup.isRunning()) {
            this.timerBlock.showTimer(cleverapps.dailyCup.getTimeLeft());

            this.playButton.setString("Play");
            this.playButton.enable();
        } else {
            this.timerBlock.showText();

            if (cleverapps.dailyCup.getReward()) {
                this.playButton.setString("Claim");
                this.playButton.enable();
            } else if (cleverapps.dailyCup.isActive() && cleverapps.dailyCup.isFinished()) {
                this.playButton.setString("Next");
                this.playButton.enable();
            } else {
                this.playButton.setString("Play");
                this.playButton.disable();
            }
        }

        if (cleverapps.dailyCup.hasConnection()) {
            this.noConnectionInfo.setVisible(false);
        } else {
            this.noConnectionInfo.setVisible(true);
            this.playButton.disable();
        }
    },

    createTitle: function () {
        var styles = cleverapps.styles.DailyCupView.title;

        var titleNode = new cc.Node();
        titleNode.setAnchorPoint(0.5, 0.5);
        titleNode.setContentSize2(styles);

        var titleBg = new cc.Scale9Sprite(bundles.daily_cup.frames.daily_cup_title_bg_png);
        titleBg.setContentSize2(styles);

        titleNode.addChild(titleBg);
        titleBg.setPositionRound(styles.bg);

        var title = cleverapps.UI.generateOnlyText("Cups.daily.title", cleverapps.styles.FONTS.DAILY_CUP_TITLE_TEXT);
        title.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        title.setVerticalAlignment(cc.VERTICAL_TEXT_ALIGNMENT_CENTER);
        title.fitTo(styles.text.width);
        titleNode.addChild(title);
        title.setPositionRound(styles.text);

        return titleNode;
    },

    createTable: function () {
        return new CupTableView(cleverapps.dailyCup, function () {
            return new TableView(cleverapps.dailyCup.table, {
                id: cleverapps.dailyCup.type,
                data: cleverapps.dailyCup.listParticipants(),
                rowConstructor: DailyCupRow,
                rowOptions: {
                    dataIcon: bundles.tablerow_icons.frames.dailycup
                },
                height: cleverapps.styles.DailyCupView.height
            });
        });
    },

    createHelpButton: function () {
        return new cleverapps.UI.HelpButton({
            type: cleverapps.styles.UI.Button.Images.help_button_nobg,
            callback: function () {
                cleverapps.focusManager.display({
                    focus: "DailyCupRulesWindow",
                    action: function (f) {
                        new GuideWindow({
                            name: "DailyCupRulesWindow",
                            bundle: bundles.dailycupruleswindow,
                            title: "DailyCupRulesWindow.title"
                        });
                        cleverapps.focusManager.onceNoWindowsListener = f;
                    }
                });
            } 
        });
    },

    createPlayButton: function () {
        var styles = cleverapps.styles.DailyCupView;

        return new cleverapps.UI.Button({
            text: "Play",
            width: styles.playButton.width,
            height: styles.playButton.height,
            onClicked: function () {
                if (this.animationRunning) {
                    return;
                }

                cleverapps.focusManager.display({
                    focus: "PlayDailyCup",
                    actions: [
                        function (f) {
                            this.animationRunning = true;

                            if (cleverapps.dailyCup.isRunning()) {
                                cleverapps.dailyCup.wantsToPlay(f);
                            } else if (cleverapps.dailyCup.getReward()) {
                                cleverapps.dailyCup.receiveReward(f);
                            } else if (cleverapps.dailyCup.isActive() && cleverapps.dailyCup.isFinished()) {
                                cleverapps.dailyCup.next();
                                f();
                            } else {
                                f();
                            }
                        }.bind(this),

                        function (f) {
                            f();
                            this.animationRunning = false;
                            this.updateState();
                        }.bind(this)
                    ]
                });
            }.bind(this)
        });
    },

    createLeadersButton: function () {
        var styles = cleverapps.styles.DailyCupView;

        var leadersButton = new cc.Sprite(bundles.daily_cup.frames.daily_cup_leaders_icon_png);
        cleverapps.UI.fitToBox(leadersButton, styles.leadersButton);
        leadersButton.baseScale = leadersButton.scale;

        cleverapps.UI.applyHover(leadersButton);
        cleverapps.UI.onClick(leadersButton, function () {
            cleverapps.focusManager.display({
                focus: "DailyCupLeadersWindow",
                action: function (f) {
                    new DailyCupLeadersWindow();
                    cleverapps.focusManager.onceNoWindowsListener = f;
                }
            });
        });

        return leadersButton;
    },

    createNoConnectionInfo: function () {
        var styles = cleverapps.styles.DailyCupView.noConnectionInfo;

        var background = new cc.Scale9Sprite(bundles.timer.frames.timer_bg_png);
        background.setContentSize2(styles.bg);

        var text = cleverapps.UI.generateOnlyText("InternetConnectionError", cleverapps.styles.FONTS.WHITE_TEXT);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.setPositionRound(background.width / 2, background.height / 2);
        text.setDimensions(background.width, 0);
        text.fitTo(undefined, background.height);
        background.addChild(text);

        return background;
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    DAILY_CUP_TITLE_TEXT: {
        name: "default",
        color: cleverapps.styles.COLORS.WHITE,
        size: 60,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});

cleverapps.styles.DailyCupView = {
    height: 640,
    margin: 40,

    baseWidth: 800,

    position: {
        x: { align: "center" },
        y: { align: "center", dy: -30 }
    },

    topLayout: {
        margin: 20
    },

    title: {
        width: 500,
        height: 150,

        bg: {
            x: { align: "center" },
            y: { align: "center", dy: -30 }
        },

        text: {
            width: 250,

            x: { align: "center" },
            y: { align: "center", dy: -10 }
        }
    },

    table: {
        height: 650,

        paddingX: 20
    },

    buttonsLayout: {
        margin: 20
    },

    playButton: {
        width: 300,
        height: 125
    },

    leadersButton: {
        width: 100,
        height: 100
    },

    noConnectionInfo: {
        bg: {
            width: 480,
            height: 120
        }
    }
};
