/**
 * Created by Denis Kuzin on 04 june 2021
 */

var AutomergeHelper = {
    findAffectedWithCrystal: function (crystal, x, y) {
        var map = Map2d.currentMap;
        var originalUnit = map.getUnit(x, y);

        var ways = ISO_NEIGHBORS.map(function (dir) {
            var adjacentUnit = map.getUnit(x + dir.x, y + dir.y);
            if (!adjacentUnit) {
                return [];
            }
            var replacementUnit = new Unit({
                code: adjacentUnit.code,
                stage: adjacentUnit.stage
            });
            map.add(Map2d.LAYER_UNITS, x, y, replacementUnit);

            var affected = crystal.findComponent(MergeComponent).listMergeAtPoint(x, y);

            if (originalUnit) {
                map.add(Map2d.LAYER_UNITS, x, y, originalUnit);
            } else {
                map.remove(Map2d.LAYER_UNITS, x, y);
            }

            return affected || [];
        });

        return ways.sort(function (a, b) {
            return b.length - a.length;
        })[0];
    },

    handleDragMove: function (draggedUnit, x, y) {
        draggedUnit.unhighlightMerge();

        var map = Map2d.currentMap;
        var affected = map.bfs(x, y, map.compareMergeable.bind(map, draggedUnit));
        affected = affected.filter(function (p) {
            return !(p.x === x && p.y === y);
        });
        if (affected && affected.length >= 2) {
            draggedUnit.highlightMerge([affected]);
        } else if (draggedUnit.code === "crystal" && draggedUnit.isLast()) {
            affected = AutomergeHelper.findAffectedWithCrystal(draggedUnit, x, y);
            if (affected && affected.length >= 3) {
                affected = affected.filter(function (pos) {
                    return !(pos.x === x && pos.y === y);
                });
                draggedUnit.highlightMerge([affected]);
            }
        }
    },

    handleDragEnd: function (draggedUnit, x, y) {
        var affected = draggedUnit.findComponent(MergeComponent).listMergeAtPoint(x, y);

        if (!affected && draggedUnit.code === "crystal" && draggedUnit.isLast()) {
            affected = AutomergeHelper.findAffectedWithCrystal(draggedUnit, x, y);

            var newPos = affected.filter(function (pos) {
                return !(pos.x === x && pos.y === y);
            })[0];

            if (newPos) {
                x = newPos.x;
                y = newPos.y;
            }
        }

        if (affected && affected.length >= 3) {
            affected.forEach(function (p) {
                var unit = Map2d.currentMap.getUnit(p.x, p.y);
                unit.highlight();
            });

            affected.forEach(function (p) {
                var unit = Map2d.currentMap.getUnit(p.x, p.y);
                unit.unhighlight();
            });

            var mergeInfoUnit = Map2d.currentMap.getUnit(x, y);
            Game.currentGame.merge(affected, draggedUnit, mergeInfoUnit, true);
        }
    }
};
