/**
 * Created by Andrey Popov on 9.11.20
 */

var MissionRow = RowView.extend({
    ctor: function (row, options) {
        this.mission = options.mission;

        this._super(row, options);
    },

    getRewards: function () {
        return this.mission.getPrizeByPlace(this.place - 1);
    }
});