/**
 * Created by evgenijsenkevic on 25.12.2024
 */

var FriendsListRequestsWindow = CleverappsWindow.extend({
    onWindowLoaded: function () {
        this.styles = cleverapps.styles.FriendRequestsWindow;

        var content = this.createFriendsViews();

        this._super({
            name: "friendslistrequestswindow",
            content: content,
            noBackground: true,
            loginOrInvite: true,
            notCloseByTouchInShadow: true
        });
    },

    createFriendsViews: function () {
        this.friends = cleverapps.friends.listFriends();

        this.friendsViews = [];

        this.friends.forEach(function (friend) {
            var view = new FriendView(friend);
            if (view) {
                this.friendsViews.push(view);
            }
        }.bind(this));

        var items = new cleverapps.Layout(this.friendsViews, {
            direction: cleverapps.UI.VERTICAL
        });

        var scrollContainer = new cleverapps.UI.ScrollView(items);
        scrollContainer.setContentSize2(cleverapps.styles.FriendsListRequestsWindow.scroll.width, this.height);
        scrollContainer.setPositionRound(this.width / 2, this.height / 2);
        return scrollContainer;
    },

    onClose: function () {
        cleverapps.toolbar.updateItems();
    }
});

cleverapps.styles.FriendsListRequestsWindow = {
    scroll: {
        width: 550
    }
};