/**
 * Created by Oleg on 03.03.2020.
 */

var Table = function () {
    cleverapps.EventEmitter.call(this);

    this.rows = {};
    this.size = 0;
};

Table.prototype = Object.create(cleverapps.EventEmitter.prototype);
Table.prototype.constructor = Table;

Table.prototype.updateRows = function (newResults, options) {
    this.size = newResults.length;
    var notInTablePlace = this.getNotInTablePlace();

    Object.values(this.rows).forEach(function (row) {
        row.place = notInTablePlace;
    });

    for (var i = 0; i < newResults.length; i++) {
        var data = newResults[i];
        var row = this.rows[data.id];

        if (row) {
            row.updateData(data);
            continue;
        }

        row = new Row(this, data);
        this.rows[data.id] = row;
        this.trigger("addRow", row, options);
    }

    this.trigger("updateRows", options);
};

Table.prototype.removeRow = function (row) {
    row.removeView();
    delete this.rows[row.id];
};

Table.prototype.getNotInTablePlace = function () {
    return this.size + 1;
};

Table.prototype.resetRows = function () {
    this.rows = {};
    this.size = 0;
};

Table.prototype.getRows = function () {
    return this.rows;
};

Table.prototype.removeView = function () {
    if (this.view) {
        this.view = undefined;
    }
};

Table.prototype.getView = function () {
    return this.view;
};