/**
 * Created by Andrey Popov on 11/17/21.
 */

var MissionsTool = {
    getLastRemoved: function () {
        var actions = {};
        var fn = function (type) {
            if (Missions[type].oncePerEvent) {
                for (var id in cleverapps.missionManager.lastRemoved) {
                    if (Missions[type].semaphore === Missions[id].semaphore) {
                        delete cleverapps.missionManager.lastRemoved[id];
                    }
                }
            } else {
                delete cleverapps.missionManager.lastRemoved[type];
            }
            cleverapps.missionManager.save();
            cleverapps.toolModel.refresh();
        };

        for (var type in cleverapps.missionManager.lastRemoved) {
            var name = Missions[type].name;
            actions[name] = fn.bind(undefined, type);
        }

        return actions;
    },

    getMissionMenu: function (mission) {
        var missionActions = {};

        var finish = function () {
            var timeRemain = 2 * 1000;

            if (mission.feature) {
                cleverapps.eventManager.setCustomFeature(mission.feature, 0);
            }

            if (mission.eventFinishTime) {
                mission.eventFinishTime = Date.now() + cleverapps.parseInterval("1 seconds");
            } else {
                mission.started = Date.now() - cleverapps.parseInterval(mission.duration) + timeRemain;
            }

            mission.onUpdateState();
            cleverapps.missionManager.save();
        };

        missionActions.finish = finish;

        if (mission.type === Mission.TYPE_LANTERN) {
            missionActions.setLevel = function () {
                var level = parseInt(window.prompt("Lantern level (0..." + Lantern.getMaxStreak() + "):"));
                var levelCorrect = false;
                if (!isNaN(level)) {
                    if (level >= 0 && level <= Lantern.getMaxStreak()) {
                        levelCorrect = true;
                        mission.logic.lantern.streak = mission.logic.lantern.savedStreak = level;
                        mission.logic.lantern.save();
                    }
                }
                if (!levelCorrect) {
                    alert("INCORRECT LEVEL");
                }
            };
        } else if (mission.type === Mission.TYPE_TREASURE_SEARCH) {
            missionActions.viewField = function () {
                var window = cleverapps.windows.currentWindow();
                if (window && window.treasureSearch) {
                    var game = window.treasureSearch;
                    var s = "";
                    var fieldSize = TreasureSearch.FIELD_SIZE_BY_STAGES[game.getStage()];

                    for (var row = 0; row < fieldSize.height; row++) {
                        for (var col = 0; col < fieldSize.width; col++) {
                            var code = "-";

                            Object.keys(game.goals).forEach(function (type) {
                                game.goals[type].forEach(function (goal) {
                                    if (col === goal.col && fieldSize.height - row - 1 === goal.row) {
                                        code = type;
                                    }
                                });
                            });

                            s += code;
                        }
                        s += "\n";
                    }
                    alert("TreasureSearch unopened keys/flowers:\n" + s);
                }
            };
            missionActions.add20Shovels = function () {
                mission.updateCurrency(20);
            };
            missionActions.shovelsReset = function () {
                mission.updateCurrency(-mission.currency);
            };
            missionActions.passStage = function () {
                var window = cleverapps.windows.currentWindow();
                if (window && window.treasureSearch) {
                    var treasureSearch = window.treasureSearch;

                    Object.keys(treasureSearch.goals).forEach(function (type) {
                        treasureSearch.goals[type].forEach(function (goal) {
                            if (!treasureSearch.isOpened(goal.col, goal.row)) {
                                mission.updateCurrency(1);
                                treasureSearch.open(goal.col, goal.row);
                            }
                        });
                    });
                }
            };
        } else if (mission.type === Mission.TYPE_PRIZE_CALENDAR) {
            missionActions.reset = function () {
                mission.update(-mission.result, {});
                var win = cleverapps.windows.currentWindow();
                if (win && win instanceof PrizeCalendarWindow) {
                    win.tiles.forEach(function (tile) {
                        tile.type = win.prizeCalendar.getTileType(tile.index);
                        tile.createContent();
                    });
                }
            };

            missionActions.open = function () {
                var win = cleverapps.windows.currentWindow();
                if (win && win instanceof PrizeCalendarWindow) {
                    win.prizeCalendar.onClaimTile();
                }
            };

            missionActions.setTileToLast = function () {
                mission.update(8 - mission.result);
                var win = cleverapps.windows.currentWindow();
                if (win && win instanceof PrizeCalendarWindow) {
                    win.tiles.forEach(function (tile) {
                        tile.type = win.prizeCalendar.getTileType(tile.index);
                        tile.createContent();
                    });
                }
            };
        } else if (mission.logic instanceof PassMissionLogic) {
            missionActions.addStars = function () {
                var amount = parseInt(prompt("enter stars amount"));
                if (amount) {
                    cleverapps.missionManager.dispatchEvent(mission.type, { amount: amount });
                }
            };

            missionActions.setLevel = function () {
                var menu = {};
                var setLevel = function (level) {
                    while (mission.logic.progress.level < level) {
                        mission.logic.addProgress(mission.logic.levels[mission.logic.progress.level].task.goal - mission.logic.progress.progress);
                    }
                };

                for (var i = 0, l = mission.levels.length; i < l; ++i) {
                    menu["level " + (i + 1)] = setLevel.bind(null, i);
                }
                menu.finish = setLevel.bind(null, mission.levels.length);
                return menu;
            };

            missionActions.dropAllRewards = function () {
                var toLastBit = function (rewards) {
                    return rewards === 0 ? 0 : (1 << (Number(rewards).toString(2).length - 1));
                };

                mission.logic.rewards = toLastBit(mission.logic.rewards);
                mission.logic.premiumRewards = toLastBit(mission.logic.premiumRewards);
                mission.logic.saveProgress();
            };

            missionActions.reset = function () {
                mission.logic.load({});
                mission.logic.saveProgress();
                cleverapps.sideBar.findMissionIcon(mission.type).resetState();
            };
        } else if (mission.type === Mission.TYPE_PIGGY_BANK) {
            missionActions.addBalance = function () {
                var amount = parseInt(prompt("Enter amount"));
                if (amount) {
                    cleverapps.missionManager.dispatchEvent(mission.type, { amount: amount });
                }
            };

            missionActions.reset = function () {
                mission.logic.load({});
                mission.logic.saveProgress();
                cleverapps.sideBar.findMissionIcon(mission.type).resetState();
            };
        } else if (mission.type === Mission.TYPE_BUTTERFLY) {
            missionActions.addBalance = function () {
                var amount = parseInt(prompt("Enter amount"));
                if (amount) {
                    cleverapps.missionManager.dispatchEvent(mission.type, { amount: amount });
                }
            };

            missionActions.add5inGame = function () {
                var game = Game.currentGame;
                if (game) {
                    game.addClover(Mission.TYPE_BUTTERFLY, 5);
                }
            };
        } else if (mission.competition) {
            missionActions.set_place = function () {
                displayToolPrompt(function (value) {
                    var place = Math.max(0, parseInt(value));
                    if (!place) {
                        return;
                    }

                    var competition = mission.runningCompetition;
                    if (competition) {
                        var player = competition.results.find(function (res) {
                            return res.player;
                        });

                        var results = competition.results.sort(function (a, b) {
                            return b.amount - a.amount;
                        });

                        var playerAmount = Math.max(player.amount, results[0].amount) + 10;

                        mission.update(Math.max(0, playerAmount - player.amount));

                        for (var j = 0; j < Math.min(place, competition.results.length); j++) {
                            competition.updatePlayerResult(results[j].id, playerAmount + j * 20);
                        }
                    }
                }, "Enter the place:");
            };

            missionActions.win_place = function () {
                displayToolPrompt(function (value) {
                    var place = Math.max(0, parseInt(value));
                    if (!place) {
                        return;
                    }

                    var competition = mission.runningCompetition;
                    if (competition) {
                        var player = competition.results.find(function (res) {
                            return res.player;
                        });

                        var results = competition.results.sort(function (a, b) {
                            return b.amount - a.amount;
                        });

                        var playerAmount = Math.max(player.amount, results[0].amount) + 10;

                        mission.update(Math.max(0, playerAmount - player.amount));

                        for (var j = 0; j < Math.min(place, competition.results.length); j++) {
                            competition.updatePlayerResult(results[j].id, playerAmount + j * 20);
                        }
                        competition.stop();
                    }

                    finish(mission);
                }, "Enter the place:");
            };

            missionActions.win_1_native = function () {
                if (mission.getResults().length) {
                    mission.update(1000);
                }
                finish(mission);
            };

            missionActions.lose = function () {
                var updated = false;
                var results = mission.getResults();
                if (results.length) {
                    var ourPlace = 0;
                    for (var j = 0; j < results.length; j++) {
                        if (results[j].player) {
                            ourPlace = j;
                        }
                    }
                    if (ourPlace >= 5) {
                        mission.update(Math.max(1, mission.result) - mission.result);
                        updated = true;
                    } else {
                        mission.update(-1000);
                        updated = true;
                    }
                }
                if (updated) {
                    finish(mission);
                }
            };
        } else if (mission.logic instanceof ExpeditionMissionLogic) {
            missionActions.resetCompensation = function () {
                cleverapps.serverFlags.clearFlag(cleverapps.ServerFlags.EXPEDITION_COMPENSATION);
            };
        } else if (mission.type === Mission.TYPE_PINBALL) {
            missionActions.addChips = function () {
                var amount = parseInt(prompt("Enter amount", 10));
                mission.logic.updateChips(amount);
            };

            missionActions.addChips10 = function () {
                mission.logic.updateChips(10);
            };

            missionActions.addTickets = function () {
                var amount = parseInt(prompt("Enter amount", 10));
                mission.logic.updateTickets(amount);
                mission.logic.pinball.win();
            };

            missionActions.addTickets250 = function () {
                mission.logic.updateTickets(250);
                mission.logic.pinball.win();
            };

            missionActions.setExtraBarrierStages = function () {
                var amount = parseInt(prompt("Enter the number of stages", 1));
                ExtraBarrier.STAGES = amount;
            };

            missionActions.toggleProgressBarText = function () {
                mission.logic.pinball.trigger("toggleProgressBarText");
            };
        } else if (mission.type === Mission.TYPE_OCTOPUS_HUNT) {
            missionActions.goToStage = function () {
                var stage = parseInt(prompt("Enter stage", 10));
                mission.update(stage - mission.result - 1);

                cleverapps.refreshScene();
            };

            missionActions.winRound = function () {
                var game = cleverapps.scenes.getRunningScene().octopusHuntGame;

                var reward = game.stageRewards.find(function (reward) {
                    return !reward.octopus;
                });

                cleverapps.Random.choose(game.barrels).collectReward(reward);
            };

            missionActions.loseRound = function () {
                var scene = cleverapps.scenes.getRunningScene();
                var game = scene.octopusHuntGame;

                var reward = game.stageRewards.find(function (reward) {
                    return reward.octopus;
                });

                if (!reward) {
                    alert("Can't lose bonus round!");
                    return;
                }

                scene.octopusHunt.handleOctopus();
                cleverapps.Random.choose(game.barrels).collectReward(reward);
            };
        }

        missionActions.makeUnknown = function () {
            cleverapps.setUrlHash({ forgetMission: mission.type });
            window.location.reload();
        };

        return missionActions;
    },

    getToolItems: function () {
        var missionActions = {
            "clear finished": this.getLastRemoved()
        };

        var start = function (missionData) {
            var siblingMission = cleverapps.missionManager.findBySemaphore(missionData.semaphore);
            if (siblingMission) {
                cleverapps.missionManager.remove(siblingMission);
            }

            var feature = missionData.feature;
            if (feature && !cleverapps.eventManager.isActive(feature)) {
                cleverapps.eventManager.setCustomFeature(feature, cleverapps.parseInterval("1 day"));
            }

            cleverapps.missionManager.planMission(missionData.type);

            cleverapps.focusManager.whenFreeFocus(function () {
                cleverapps.placements.run(Placements.FREE_FOCUS, {
                    skipDelayFromStart: true
                });
            });
        };

        var missions = {};

        cleverapps.missionManager.getExistingTypes().forEach(function (missionType) {
            var parentType = Mission.GetParentType(missionType);

            if (parentType === undefined) {
                missions[missionType] = missions[missionType] || {};
            } else {
                missions[parentType] = missions[parentType] || {};
                missions[parentType][missionType] = missions[parentType][missionType] || {};
            }
        });

        missions = Object.keys(missions).reduce(function (result, missionType) {
            result.push(missionType);

            Object.keys(missions[missionType]).forEach(function (childType) {
                result.push(childType);
            });

            return result;
        }, []);

        missions.forEach(function (missionType) {
            var missionData = Missions[missionType];
            var parentMissionData = Missions[Mission.GetParentType(missionType)];

            var semaphoreNames = {
                0: "ALWAYS",
                1: "PRIMARY",
                2: "SECONDARY",
                4: "LANTERN",
                8: "PIGGY_BANK",
                16: "SALE",
                32: "PRIZE_CALENDAR",
                64: "EXPEDITION_SLOT1",
                128: "EXPEDITION_SLOT2",
                256: "EXPEDITION_SLOT4"
            };
            var title = (parentMissionData && (parentMissionData.name || parentMissionData.id) + " " || "") + (missionData.name || missionData.id);
            if (missionData.semaphore) {
                title += " (" + semaphoreNames[missionData.semaphore] + " | " + Missions.SEMAPHORE_COOLDOWNS[missionData.semaphore] + ")";
            }

            var mission = cleverapps.missionManager.findByType(missionType);

            if (mission) {
                missionActions[ToolMenuItem.ACTIVE_ITEM + title] = this.getMissionMenu(mission);
            } else {
                missionActions[title] = start.bind(null, missionData);
            }
        }.bind(this));

        return missionActions;
    }
};

(function () {
    var baseInit = Missions.initialize;
    Missions.initialize = function () {
        baseInit.apply(undefined, arguments);

        var params = cleverapps.getRequestParameters(location.hash);
        if (params.forgetMission !== undefined) {
            cleverapps.setUrlHash({ forgetMission: undefined });
            if (Missions[params.forgetMission]) {
                var missionType = Mission.GetChildType(params.forgetMission);
                console.warn("Mission " + Missions[missionType].name + " was made unknown, reload to restore");
                delete Missions[missionType];
            }
        }
    };
}());